<template>
  <div class="el_1_5dd3733c121df6f22176b5fdb7d8227c flex items-center p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100">
    <div class="el_2_5dd3733c121df6f22176b5fdb7d8227c w-6/12 pl-2">
      <div class="el_3_5dd3733c121df6f22176b5fdb7d8227c inline-flex items-center ">
        <MenuIcon class="w-4 h-4 mr-2 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        <tg-badge class="mr-2" :color="data.pivot_alt ? 'success' : 'gray'" @click="toggle('pivot_alt')">
          Alt
        </tg-badge>
        {{ data.name }}
      </div>
    </div>
    <div class="el_4_5dd3733c121df6f22176b5fdb7d8227c w-6/12 text-right">
      <tg-badge color="danger" @click="remove()">
        X
      </tg-badge>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { MenuIcon } from "@heroicons/vue/solid";

export default {
  name: "PivoItem",
  components: {
    TgBadge,
    MenuIcon,
  },
  props: {
    item: Object,
  },
  emits: ["remove-item"],
  data() {
    return {
      data: this.item,
    };
  },
  methods: {
    toggle(fld) {
      this.data[fld] = !this.data[fld];
      // this.$emit('toggle-item-feature',{fld: fld,item: this.data});
    },
    remove() {
      this.$emit("remove-item", { fld: "pivot_arr", item: this.data });
    },
  },
};
</script>

<style>
</style>