<template>
  <div class="el_1_583829268557c491036fdfb47007b472">
    <div class="el_2_583829268557c491036fdfb47007b472 absolute top-5 right-6">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>
    </div>

    <textarea v-model="data" rows="15" class="block w-full border border-gray-300 rounded-md shadow-sm" />
  </div>
  <Code2FA
    v-if="get_otp"
    :sending="sending"
    :error="error"
    @submit="save"
    @cancel="get_otp = false"
  />
</template>

<script>
import { STORE } from "@/store";
import { API } from "@/api";
import TgBtn from "@/components/Common/TgBtn";
import Code2FA from "@/components/Common/Code2FA";

export default {
  name: "ConfigSettings",
  components: {
    TgBtn,
    Code2FA,
  },
  props: {
    slug: String,
  },
  emits: ["on:save"],
  data() {
    return {
      sending: false,
      data: null,
      error: null,
      get_otp: false,
    };
  },

  async mounted() {
    let resp = await API.page({ slug: this.slug });

    this.data = JSON.stringify(resp.data.data);
  },
  methods: {
    save(otp) {
      this.sending = true;
      STORE.set(this.slug,  JSON.parse(this.data), otp)
        .then(() => {
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Page Saved!",
          });
          this.sending = false;
          this.get_otp = false;
          this.$emit("on:save");
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>