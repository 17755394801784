<template>
  <label :for="item.field" class="relative block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_a62e50c147903d6bfb033292cd05f6ce inline mr-1 text-sm text-red-400">*</span> {{ item.name }}
    <div class="el_2_a62e50c147903d6bfb033292cd05f6ce absolute top-0 right-0">
      <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />
      <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer" @click="show_help = !show_help" />
    </div>
  </label>
  <div class="el_3_a62e50c147903d6bfb033292cd05f6ce flex px-2 py-2 pl-3 mt-1 bg-gray-200 border border-gray-200 rounded-md">
    <span v-if="item.prefix" class="el_4_a62e50c147903d6bfb033292cd05f6ce inline-flex items-center px-3 font-bold text-gray-500 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
      {{ item.prefix }}
    </span>
    {{ modelValue }}
    <span v-if="item.suffix" class="el_5_a62e50c147903d6bfb033292cd05f6ce inline-flex items-center px-3 font-bold text-gray-500 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
      {{ item.suffix }}
    </span>
  </div>
  <div v-if="show_help && item.popover" class="el_6_a62e50c147903d6bfb033292cd05f6ce absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300" @click="show_help = false">
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import { InformationCircleIcon, KeyIcon } from "@heroicons/vue/outline";
import { API } from "@/api";
export default {
  components: {
    InformationCircleIcon,
    KeyIcon,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
    };
  },
  methods: {
    onBlur() {
      if (this.item.unique) {
        let payload = {
          _top: 1,
        };
        payload[this.item.field] = this.modelValue;

        API.get(this.slug, payload)
          .then((resp) => {
            if (resp.data.data.length > 0) {
              this.$emit("update:error", {
                error: "This value already exists",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>