<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog :static="true" :initial-focus="closeButtonRef" class="fixed inset-0 overflow-hidden"
      :class="[z ? z : 'z-30']" :open="true" @close="close">
      <TransitionChild as="template" enter="transition-opacity duration-300" enter-from="opacity-0"
        enter-to="opacity-100" leave="transition-opacity duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="absolute inset-0 bg-black bg-opacity-40" />
      </TransitionChild>

      <TransitionChild as="template" enter="transform ease-in-out transition-transform duration-300"
        :enter-from="enter_from" :enter-to="enter_to" leave="transform ease-in-out transition-transform duration-300"
        :leave-from="leave_from" :leave-to="leave_to">
        <div class="el_1_7ca2c91cbdf962e00fbbd14d191cb5cf fixed inset-y-0 flex flex-col w-full bg-white"
          :class="[(side == 'left' ? 'left-0' : 'right-0'), panel_width, `el_panel_${slug}`]">
          <div
            class="el_2_7ca2c91cbdf962e00fbbd14d191cb5cf flex items-center justify-between p-4 shadow bg-primary-700">
            <div class="el_3_7ca2c91cbdf962e00fbbd14d191cb5cf">
              <DialogTitle class="text-lg font-medium text-white">
                <slot name="title" />
              </DialogTitle>
              <p class="mt-1 text-sm text-white">
                <slot name="subtitle" />
              </p>
            </div>
            <button ref="closeButtonRef" class="p-1 text-white rounded-full hover:text-primary-150 focus:outline-none"
              @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" title="Close Panel" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="el_4_7ca2c91cbdf962e00fbbd14d191cb5cf flex flex-col flex-1 ">
            <div class="el_5_7ca2c91cbdf962e00fbbd14d191cb5cf relative flex-1" :class="`el_panel_body_${slug}`">
              <div class="el_6_7ca2c91cbdf962e00fbbd14d191cb5cf absolute inset-0 overflow-y-scroll bg-gray-100">
                <slot name="content" />
              </div>
            </div>
            <div :class="`el_panel_footer_${slug}`"
              class="el_7_7ca2c91cbdf962e00fbbd14d191cb5cf flex items-center justify-between px-3 py-4 text-right bg-gray-200 border-t border-gray-300">
              <button type="button"
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                @click="close">
                Cancel
              </button>
              <slot name="footer" />
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import _get from "lodash/get";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  },

  props: {
    open: Boolean,
    onClose: Function,
    side: String,
    width: String,
    z: String,
    overlay: Boolean,
    slug: String,
  },
  setup(props) {
    let panel_width = _get(props, "width", "max-w-5xl");
    let side = _get(props, "side", "right");
    let closeButtonRef = ref(null);

    let enter_from = "translate-x-full";
    let enter_to = "translate-x-0";
    let leave_from = "translate-x-full";
    let leave_to = "translate-x-0";

    if (side == "left") {
      enter_from = "-translate-x-full";
      enter_to = "-translate-x-0";
      leave_from = "-translate-x-full";
      leave_to = "-translate-x-0";
    }

    return {
      closeButtonRef,
      enter_from,
      enter_to,
      leave_from,
      leave_to,
      panel_width,
    };
  },
  methods: {
    close() {
      this.onClose();
    },
  },
};
</script>