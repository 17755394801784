<template>
  <div class="el_1_8f985b87c2ed1cad6af6076423cee48b flex items-center p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100">
    <div class="el_2_8f985b87c2ed1cad6af6076423cee48b w-6/12 pl-2">
      <div class="el_3_8f985b87c2ed1cad6af6076423cee48b inline-flex items-center ">
        <MenuIcon class="w-4 h-4 mr-2 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        <tg-badge class="mr-2" :color="data.is_count ? 'success' : 'gray'" @click="toggle('is_count')">
          C
        </tg-badge>
        <tg-badge class="mr-2" :color="data.is_sum ? 'success' : 'gray'" @click="toggle('is_sum')">
          S
        </tg-badge>
        <tg-badge class="mr-2" :color="data.is_max ? 'success' : 'gray'" @click="toggle('is_max')">
          M
        </tg-badge>
        <tg-badge class="mr-2" :color="data.is_total ? 'success' : 'gray'" @click="toggle('is_total')">
          T
        </tg-badge>
        {{ data.name }}
      </div>
    </div>
    <div class="el_4_8f985b87c2ed1cad6af6076423cee48b w-6/12 text-right">
      <tg-badge color="danger" @click="remove()">
        X
      </tg-badge>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { MenuIcon } from "@heroicons/vue/solid";

export default {
  name: "ValueItem",
  components: {
    TgBadge,
    MenuIcon,
  },
  props: {
    item: Object,
  },
  emits: ["remove-item"],
  data() {
    return {
      data: this.item,
    };
  },
  methods: {
    toggle(fld) {
      this.data[fld] = !this.data[fld];
      // this.$emit('toggle-item-feature',{fld: fld,item: this.data});
    },
    remove() {
      this.$emit("remove-item", { fld: "values", item: this.data });
    },
  },
};
</script>

<style>
</style>