<template>
  <div class="el_1_064d93b2d8de85a1feb7b951d2425eea relative">
    <label :for="item.field" class="relative block text-sm font-medium uppercase text-primary-800">
      <span v-if="item.required" class="el_2_064d93b2d8de85a1feb7b951d2425eea inline mr-1 text-sm text-red-400">*</span>
      {{ item.name }}
      <div class="el_3_064d93b2d8de85a1feb7b951d2425eea absolute top-0 right-0">
        <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
          @click="show_help = !show_help" />
      </div>
    </label>
  </div>
  <div class="flex flex-col space-y-2 mt-3">
    <div class="flex space-x-2">
      <div class="flex-1">
        <div class="text-sm">
          Passenger Class
        </div>
        <select v-model="cascade.pax_class"
          :disabled="item.loading || item.disabled || item.lock_edits || combo_data.pax_class.length == 0"
          class="block w-full py-2 pl-3 pr-10 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200">
          <option />
          <option v-for="i in combo_data.pax_class" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </div>
      <div class="flex-1">
        <div class="text-sm">
          Operation
        </div>
        <select v-model="cascade.operation"
          :disabled="item.loading || item.disabled || item.lock_edits || !cascade.pax_class"
          class="block w-full py-2 pl-3 pr-10 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200">
          <option />
          <option v-for="i in operations" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </div>
      <div class="flex-1">
        <div class="text-sm">
          Meal Window
        </div>
        <select v-model="cascade.meal_window"
          :disabled="item.loading || item.disabled || item.lock_edits || !cascade.operation"
          class="block w-full py-2 pl-3 pr-10 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200">
          <option />
          <option v-for="i in meal_windows" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex-1">
      <div class="flex-1">
        <div class="text-sm">
          Product
        </div>
        <select :disabled="item.loading || item.disabled || item.lock_edits || !cascade.meal_window" :value="modelValue"
          class="block w-full py-2 pl-3 pr-10 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
          @input="$emit('update:modelValue', $event.target.value)">
          <option />
          <option v-for="i in products" :key="i.masterlistkey" :value="i.masterlistkey">
            {{ i.masterlistkey }} - {{ i.masterdescription }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import _map from "lodash/map";
import _each from "lodash/each";
import _uniq from "lodash/uniq";
import _has from "lodash/has";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { API } from "@/api";


export default {
  components: {
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    row: Object,
    tab: Object,
    modelValue: [String, Number, Boolean],
  },
  emits: { "update:modelValue": null },
  data() {
    return {
      search_query: "",
      options: [],
      selected: {},
      cascade: {
        pax_class: "",
        operation: "",
        meal_window: "",
      },
      combo_data: {
        pax_class: [],
        operation: [],
        meal_window: [],
      },
      loading: true,
      open: false,
      form_row: null,
      show_add_combo_item: false,
      show_help: false,
      is_sql: !_isEmpty(this.item.sql),
    };
  },
  computed: {
    operations() {
      if (this.cascade.pax_class) {
        // filter the data based on the pax_class
        let filtered = this.data.filter((item) => {
          return item.pax_class == this.cascade.pax_class;
        });

        // just return an array of unique meal_window values
        return _uniq(_map(filtered, "operation"));
      } else {
        return [];
      }
    },
    meal_windows() {
      // filter the data based on the pax_class and operation
      if (this.cascade.pax_class && this.cascade.operation) {
        let filtered = this.data.filter((item) => {
          return item.pax_class == this.cascade.pax_class && item.operation == this.cascade.operation;
        });
        // just return an array of unique meal_window values
        return _uniq(_map(filtered, "meal_window"));
      } else {
        return [];
      }
    },
    products() {
      if (this.cascade.pax_class) {
        // filter data based on cascade
        let filtered = this.data.filter((item) => {

          // if this.cascade.pax_class is not empty then add to filterBy object
          let filterBy = {};

          if (this.cascade.pax_class) filterBy.pax_class = this.cascade.pax_class;

          if (this.cascade.operation) filterBy.operation = this.cascade.operation;

          if (this.cascade.meal_window) filterBy.meal_window = this.cascade.meal_window;

          return Object.keys(filterBy).every((v) => {
            return item[v] == filterBy[v];
          });

        });
        return filtered;
      } else {
        return this.data;
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: false,
      handler() {
        this.refresh(true);
      },
    },
    row: {
      immediate: true,
      deep: true,
      handler(row) {
        if (this.item.cascade_field && row) {
          this.form_row = row;
          this.refresh();
        }
      },
    },
  },
  mounted() {
    this.get_combos();
    window.addEventListener("keydown", this.onKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    async onSearchBlur() { },
    onKeyDown(e) {
      if (e.keyCode == 9) {
        this.open = false;
      }
    },

    async get_combos() {
      this.loading = true;
      try {
        const res = await API.get('CA72E6F3A7F847A899201F7236D34537', {
          _fields: ['pax_class', 'operation', 'meal_window', 'masterlistkey', 'masterdescription'],
          _distinct: 1,
          orderBy: 'masterlistkey'
        });
        let data = res.data.data;

        // using _each loop over data and create a unique list of pax_class, operation, meal_window
        let pax_class = [];

        _each(data, (i) => {
          if (i.pax_class && !_has(pax_class, i.pax_class)) pax_class.push(i.pax_class);
          if (i.masterlistkey == this.modelValue) {
            this.cascade.pax_class = i.pax_class;
            this.cascade.operation = i.operation;
            this.cascade.meal_window = i.meal_window;
          }
        });

        this.combo_data = {
          pax_class: _uniq(pax_class)
        };

        this.data = data;

      } catch (e) {
        console.error(e);

      }
    },

    async refresh() {

    },
  },
};
</script>

<style scoped>
.combo {
  min-height: 32px;
}
</style>
