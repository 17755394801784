import md5 from "blueimp-md5";
import _has from "lodash/has";
import _get from "lodash/get";
import _each from "lodash/each";
import _chunk from "lodash/chunk";
import _findIndex from "lodash/findIndex";
import axios from "axios";
// import { del, get as GetCache, set } from "idb-keyval";
import { getState } from "@/auth";
import mitt from "mitt";
import router from "@/router";
import { globalVariable, updateGlobalVariable } from '@/globalVariable';

// current
const MIGRATION_SLUG = 'cfc3950470e7490ab5810947b639fd03';
const emitter = mitt();


const api_external_endpoint = "https://api-au-gw.tplive-aws.com/dev/";
let api_endpoint = "https://api-prod.touchpointglobal.com/api/";

if (window.location.hostname == "production.tplive.io" || window.location.hostname == "beta.tplive.io") {
  api_endpoint = "https://api-dev.touchpointglobal.com/api/";
}

if (window.location.hostname == "uat.tplive.io") {
  // api_endpoint = "https://api-uat.tplive.io/api/";
  api_endpoint = "https://api-uat.touchpointglobal.com/api/";
}

if (window.location.hostname == "localhost") {
  api_endpoint = "http://127.0.0.1:3000/api/";
  api_endpoint = "https://api-uat.touchpointglobal.com/api/";
  // api_endpoint = "https://api-dev.touchpointglobal.com/api/";
  // api_endpoint = "https://api-prod.touchpointglobal.com/api/";
}

const api = axios.create({
  baseURL: api_endpoint,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to set Authorization header based on the current token
function setupAxiosInterceptors() {
  api.interceptors.request.use(
    (config) => {
      const token = globalVariable.value;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

}


export const API = {
  HTTP: null,
  ws: null,
  ws_msg: null,
  ws_data: [],
  tabs: {},
  data: {},
  apikey: null,
  csrf_token: null,
  accessToken: null,


  getAuthHeaders() {
    const token = globalVariable.value;
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    }
    return {};
  },

  getAccessToken() {
    return this.accessToken
  },

  updateBearerToken(newAccessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
  },

  setupAxiosInterceptors() {
    this.HTTP.interceptors.request.use(
      (config) => {
        const token = globalVariable.value;
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

  },

  updateInterceptor() {
    const token = globalVariable.value;
    this.HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // this.HTTP.interceptors.request.eject(this.HTTP.interceptors.request.handlers[0]);
    // this.setupAxiosInterceptors();
  },

  init(csrf_token, apikey, accessToken, waf_key) {
    this.apikey = apikey;
    this.csrf_token = csrf_token;
    this.accessToken = accessToken;

    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    api.defaults.headers.common['x-api-key'] = apikey;
    api.defaults.headers.common['x-csrf-token'] = csrf_token;
    api.defaults.headers.common['x-waf-key'] = waf_key;

    this.HTTP = api;

    // this.setupAxiosInterceptors();


    // this.HTTP.interceptors.request.use(
    //   (config) => {

    //     if (globalVariable.value) {
    //       config.headers['Authorization'] = `Bearer ${globalVariable.value}`;
    //       console.log('API inter globalVariable.value', globalVariable.value)
    //     }
    //     return config;
    //   },
    //   (error) => {
    //     Promise.reject(error);
    //   }
    // );


    this.HTTP.interceptors.response.use(config => {
      config.headers["x-url"] = window.location;

      return config;
    }, error => {
      console.log("error : ", error);

      if (error.response && error.response.status == 511) {

        // get the message
        let msg = error.response.data.message;

        if (msg == 'Unauthorized - token has expired') {
          router.push({
            name: "logout",
            query: { msg: "1" },
          });
        }


      } else {
        return Promise.reject(error);
      }

    });

    // this.HTTP.interceptors.request.use(
    //   function (config) {
    //     config.headers["x-url"] = window.location;
    //     return config;
    //   },
    //   function (error) {
    //     console.log('error', error)
    //     console.log('e.response.status', e.response.status)
    //     // Do something with request error
    //     return Promise.reject(error);
    //   }
    // );
  },

  async ec2Status() {
    return this.HTTP.get(`ec2/status`);
  },

  async ec2Types() {
    return this.HTTP.get(`ec2/types`);
  },

  async ec2Action(instance_id, action, payload) {
    return this.HTTP.post(`ec2/action/${instance_id}/${action}`, payload);
  },

  async openai(chat) {
    return this.HTTP.post(`openai`, chat);
  },

  async test_get_api(url, params) {
    let headers = {
      "Content-type": "application/json",
    };
    let resp = await axios.get(url, params, headers);
    return resp.data;
  },
  async test_post_api(url, body) {
    let headers = {
      "Content-type": "application/json",
    };
    let resp = await axios.post(url, body, headers);
    return resp.data;
  },

  async sleep(ms) {
    return await new Promise((r) => setTimeout(r, ms));
  },

  async checkOnline() {
    return navigator.onLine;
    // return false;
    let resp = await axios.get("https://api-au-gw.tplive-aws.com/prod/ping").catch(() => {
      return false;
    });
    return resp.status == 200;
  },

  bump_worker(worker) { },

  get_emitter() {
    return emitter;
  },

  set_tab(level, tab) {
    this.tabs[level] = tab;
  },

  get_tabs() {
    return this.tabs;
  },

  async init_ws(user_id) { },

  ws_send(payload) {
    this.ws.send(JSON.stringify(payload));
  },

  fire_worker(worker) {
    let client = getState.client;
    let payload = {
      worker: worker,
      client: client.key,
    };
    // this.ws.send(JSON.stringify(payload));
  },


  /**
   *raw request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  raw(url, params) {
    return this.HTTP.post(url, params);
  },

  get_api_endpoint(slug) {
    return `${api_endpoint}gw/${slug}`;
  },
  external_get(slug, user_apikey, params) {
    params.appid = this.apikey;
    params.apikey = user_apikey;
    return this.HTTP.get(`gw/${slug}`, { params: params });
  },
  health(apikey) {
    return this.HTTP.get("health", { headers: { "x-api-key": apikey } });
  },
  async health_checkin(apikey) {
    return await axios.get(`${api_endpoint}health/checkin`, { headers: { "x-api-key": apikey } });
  },
  health_query(apikey, query_id, options) {
    if (options) {
      options = JSON.stringify(options);
    } else {
      options = "{}";
    }
    let cb = Math.floor(new Date().getTime() / 1000);
    return this.HTTP.get(`health/query/${query_id}/${options}?cb=${cb}`, {
      headers: { "x-api-key": apikey },
    });
  },
  health_get_worker(apikey, live, worker, status) {
    return this.HTTP.get(`health/workers/${live}/${worker}/${status}`, {
      headers: { "x-api-key": apikey },
    });
  },
  health_worker_update(slug, apikey, user, id, payload) {
    return this.HTTP.put(`/health/${slug}/${id}/27B6C778E98A44E59A06C4B7A6615F57`, payload, {
      headers: { "x-api-key": apikey },
    });
  },
  health_proc(apikey, payload) {
    return this.HTTP.post(`/health/proc/6283A7EEA6664FF3A0B9814BCDA4AAA6`, payload, {
      headers: { "x-api-key": apikey },
    });
  },
  health_proc_resolved(apikey, comment) {
    return this.HTTP.post(
      `/health/resolve/proc/83B8C0C887874BFF93D9B301E430DD79`,
      { comment: comment },
      {
        headers: { "x-api-key": apikey },
      }
    );
  },

  async tabs(slug) {
    return this.HTTP.post(`pageconfig/${slug}`, {});
  },

  /**
   *Get request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  get_data(slug, params) {
    let headers = {};

    let dnata = _get(params, "dnata", false);

    if (dnata) {
      let client = getState.client;
      if (client.key == "dnata" && router.currentRoute._value.name == "mobilepage")
        headers["x-api-key"] = client.old_apikey;

      delete params.dnata;
    }

    let queryparams = "";

    if (_has(this.crud_db_keys, slug)) {
      queryparams += `&_db_key=${this.crud_db_keys[slug]}`;
    }

    return this.HTTP.post(`page/${slug}`, params);

    // return this.HTTP.get(`page/${slug}?${queryparams}`, {
    //   params: { _p: btoa(unescape(encodeURIComponent(JSON.stringify(params)))) },
    //   headers: headers,
    // });
  },


  get_csrf(slug) {
    return this.HTTP.get(`csrf/${slug}`);
  },

  get_cache_key(prefix, slug, force_cache_key) {
    if (force_cache_key) return `${prefix}_${slug}_${force_cache_key}`;
    const url = new URL(window.location);
    let cache_key = url.searchParams.get("cache_key");
    if (!!cache_key) return `${prefix}_${slug}_${cache_key}`;
    return `${prefix}_${slug}`;
  },

  async get_fields(slug, params) {
    let headers = {};

    let dnata = _get(params, "dnata", false);

    if (dnata) {
      let client = getState.client;
      if (client.key == "dnata" && router.currentRoute._value.name == "mobilepage")
        headers["x-api-key"] = client.old_apikey;

      delete params.dnata;
    }

    let queryparams = "";

    if (_has(this.crud_db_keys, slug)) {
      queryparams += `&_db_key=${this.crud_db_keys[slug]}`;
    }

    return this.HTTP.post(`page/715A95B4E1369B5CB1052B15CF2921C8`, params);
  },

  async get(slug, params, force_cache_key) {
    let cache_key = this.get_cache_key("get", slug, force_cache_key);

    // check if mobile site?
    let online = await this.checkOnline();

    let db_resp = await this.get_data(slug, params);

    // if (db_resp) set(cache_key, db_resp.data);

    return new Promise((resolve) => {
      return resolve(db_resp);
    });
  },

  crud_db_keys: {},

  async page(params, force_cache_key, queryparams) {
    const online = await this.checkOnline();
    let cache_key;

    let slug = params.slug

    if (_has(params, "parentslug"))
      slug = params.parentslug


    // check if queryparams is set 
    if (typeof queryparams === 'undefined') {
      queryparams = '';
    }



    return new Promise((resolve, reject) => {
      return this.HTTP.post(`pageconfig/${slug}${queryparams}`, params, {
        headers: {
          ...this.getAuthHeaders()
        }
      })
        .then((resp) => {
          console.log("page", resp.data);

          if (_has(resp.data, "data.slug")) {
            if (_has(this.crud_db_keys, resp.data.data.slug))
              delete this.crud_db_keys[resp.data.data.slug];

            if (_has(resp.data.data, "crud_db_key") && resp.data.data.crud_db_key) {
              this.crud_db_keys[resp.data.data.slug] = resp.data.data.crud_db_key;
            }
          }

          // set(cache_key, resp.data);
          return resolve(resp);
        })
        .catch((e) => {
          return reject(e);
        });
    });
  },

  insert_worker_job(data) {
    return this.insert("01DF7E1A27893E8BF2D68E9734C8EE0A", data);
  },

  remove_slug(slug) {
    return this.HTTP.get(`page/remove/${slug}`);
  },

  get_worker_job(id) {
    return this.HTTP.post(`page/01DF7E1A27893E8BF2D68E9734C8EE0A`, { id: id });
  },

  regenerate_token(slug) {
    return this.HTTP.get(`/regenerate/token/${slug}`);
  },

  sleep(delay) {
    new Promise((resolve) => setTimeout(resolve, delay));
  },

  /**
   *Get request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  get_secure_upload(slug, filename, s3_folder) {
    var url =
      "get-signed-url/" +
      slug +
      "/" +
      filename +
      "?action=get&folder=" +
      s3_folder +
      "&type=undefined&pub=1";

    return this.HTTP.get(url);
  },

  /**
   *Get request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  get_secure_upload_url(slug, filename, params) {
    return this.HTTP.get(`put-signed-url/${slug}/${filename}`, { params: params });
  },

  /**
   *Proc request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  proc(params) {
    return this.HTTP.post(`proc`, params);
  },

  send_reset_email(auth0_id) {
    auth0_id = encodeURIComponent(auth0_id);
    return this.HTTP.get(`/users/send-reset-email/${auth0_id}`);
  },

  generate_otp(auth0_id) {
    auth0_id = encodeURIComponent(auth0_id);
    return this.HTTP.get(`/users/generate_otp/${auth0_id}`);
  },
  bulk_delete(slug, ids) {
    return this.HTTP.post(`bulk/delete/${slug}`, { ids: ids });
  },

  /**
   *Widget request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  widget(slug, data) {
    return this.HTTP.post(`dashboard/process/${slug}`, data);
  },

  /**
   *Get request
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  filter(slug, field, params) {
    let client = getState.client;
    let headers = {};

    if (client.key == "dnata" && router.currentRoute._value.name == "mobilepage")
      headers["x-api-key"] = client.old_apikey;

    let queryparams = "";

    if (_has(this.crud_db_keys, slug)) {
      queryparams += `&_db_key=${this.crud_db_keys[slug]}`;
    }

    return this.HTTP.post("combo/" + slug + "/" + field);
  },

  create_user(data) {
    return this.HTTP.post(`users`, data);
  },

  // alias for post
  insert(slug, data) {
    return this.post(slug, data);
  },

  get_user(auth0_id) {
    auth0_id = encodeURIComponent(auth0_id);
    return this.HTTP.get(`users/${auth0_id}`);
  },

  update_user(auth0_id, data) {
    auth0_id = encodeURIComponent(auth0_id);
    return this.HTTP.post(`users/${auth0_id}`, data);
  },

  delete_user(auth0_id) {
    auth0_id = encodeURIComponent(auth0_id);
    return this.HTTP.delete(`users/${auth0_id}`);
  },

  revoke_object_perms(object_id, tab_slug) {
    return this.HTTP.delete(`perms/${object_id}/${tab_slug}`);
  },

  /**
   *Post request
   *
   *@ param URL interface routing
   *@ param data interface parameters
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  query(slug, data, tab_slug, queryparams) {

    // check if tab_slug is undefined and set to null
    if (typeof tab_slug === 'undefined') {
      tab_slug = MIGRATION_SLUG;
    }

    if (typeof queryparams === 'undefined') {
      queryparams = '';
    }

    return this.HTTP.post(`query/${slug}/${tab_slug}${queryparams}`, data);
  },

  verify_identity(otp) {
    return this.HTTP.get(`verify/identity?otp=${otp}`, {});
  },
  refreshToken() {
    return this.HTTP.get(`user/refreshToken`, {});
  },

  put(url, data) {
    return this.HTTP.put(url, data);
  },

  put_page(data, otp) {
    return this.HTTP.put(`pageconfig?otp=${otp}`, data);
  },

  cleanData(data) {
    return JSON.parse(JSON.stringify(data));
  },

  get_queue_key(slug) {
    return "queue_" + md5(slug + window.location.href);
  },

  form_id(slug, hashid, tab_slug) {

    // check if tab_slug is undefined and set to null
    if (typeof tab_slug === 'undefined') {
      tab_slug = MIGRATION_SLUG;
    }

    return this.HTTP.get(`form/${slug}/${hashid}/${tab_slug}`);
  },


  str_rand(L) {
    var s = "";
    var randomchar = function () {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    };
    while (s.length < L) s += randomchar();
    return s;
  },

  async post(slug, data, tab_slug) {

    // check if tab_slug is undefined and set to null
    if (typeof tab_slug === 'undefined') {
      tab_slug = MIGRATION_SLUG;
    }
    return this.HTTP.post(`form/${slug}/${tab_slug}`, data);
  },

  async update(slug, id, data, queryparams, tab_slug) {

    // check if tab_slug is undefined and set to null
    if (typeof tab_slug === 'undefined') {
      tab_slug = MIGRATION_SLUG;
    }

    if (queryparams) {
      return this.HTTP.put(`form/${slug}/${id}/${tab_slug}?${queryparams}`, data);
    } else {
      return this.HTTP.put(`form/${slug}/${id}/${tab_slug}`, data);
    }
  },


  /**
   *Delete
   *@ param URL interface routing
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise}
   */
  del(slug, id, fld, queryparams, tab_slug) {

    // check if tab_slug is undefined and set to null
    if (typeof tab_slug === 'undefined') {
      tab_slug = MIGRATION_SLUG;
    }
    if (!fld) fld = "id";

    if (_has(this.crud_db_keys, slug)) {
      queryparams += `&_db_key=${this.crud_db_keys[slug]}`;
    }

    return this.HTTP.delete(`form/${slug}/${id}/${tab_slug}?id_fld=${fld}&${queryparams}`);
  },

  /**
   *bulk_insert request
   *
   *@ param URL interface routing
   *@ param data interface parameters
   *Does @ param auth need to bring login information
   * @returns {AxiosPromise<any>}
   */
  async bulk_insert(slug, rows) {
    let parts = _chunk(rows, 20);
    let jobs = [];

    _each(parts, (i) => {
      jobs.push(this.HTTP.post(`bulk/insert/${slug}`, { data: i }));
    });

    return Promise.all(jobs);
  },
};

export const API_EXTERNAL = {
  HTTP: null,
  ws: null,
  ws_msg: null,
  ws_data: [],
  tabs: {},
  data: {},
  headers: {},

  init(apikey, accessToken) {
    this.headers = {
      "x-api-key": apikey,
    };

    this.HTTP = axios.create({
      baseURL: api_external_endpoint,
      headers: this.headers,
    });

    this.HTTP.interceptors.request.use(
      function (config) {
        config.headers["x-url"] = window.location;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  },

  async get_token(token) {
    return this.HTTP.get(`oauth/token?client_secret=${token}`);
  },

  async post(slug, token, payload) {
    let headers = this.headers;
    let apikey = headers["x-api-key"];
    return this.HTTP.post(`push/placeholder/${slug}?apikey=${apikey}&client_key=${token}`, payload);
  },

  async get(slug, bearer, params) {
    let headers = this.headers;
    headers.Authorization = `Bearer ${bearer}`;
    return this.HTTP.get(`get/${slug}`, { params: params, headers: headers });
  },
  async post_out(slug, params) {
    let headers = this.headers;
    let apikey = headers["x-api-key"];
    return this.HTTP.get(`postout/${slug}?apikey=${apikey}`, { params: params });
  },

  async flow(payload) {
    let headers = this.headers;
    let apikey = headers["x-api-key"];
    return this.HTTP.post(`flow?apikey=${apikey}`, payload);
  },
};


export const MOBILE = {
  HTTP: null,
  apikey: null,
  csrf_token: null,
  data: {},
  getKey(key) {
    return _get(this.data, key, null);
  },
  setKey(key, val) {
    return (this.data[key] = val);
  },

  accessToken: null,

  updateBearerToken(newAccessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
  },

  init(csrf_token, apikey, accessToken, waf_key) {
    this.apikey = apikey;
    this.csrf_token = csrf_token;
    this.accessToken = accessToken;

    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    api.defaults.headers.common['x-api-key'] = apikey;
    api.defaults.headers.common['x-csrf-token'] = csrf_token;
    api.defaults.headers.common['x-waf-key'] = waf_key;


    this.HTTP = api;

    this.HTTP.interceptors.request.use(
      function (config) {
        config.headers["x-url"] = window.location;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  },

  get_queue_key(slug) {
    return "queue_" + md5(slug + window.location.href);
  },
  async checkOnline() {
    return navigator.onLine;
    let resp = await axios.get("https://api-au-gw.tplive-aws.com/prod/ping").catch(() => {
      return false;
    });
    return resp.status == 200;
  },

  async del_from_queue(slug, data) {
    alert('todo')
  },

  async form_submit(slug, data) {
    // let queue_key = this.get_queue_key(slug);

    // let queue = await GetCache(queue_key);

    // if (!queue) queue = [];

    // data = JSON.parse(JSON.stringify(data));

    // let index = _findIndex(queue, { _id: data._id });

    // if (index >= 0) {
    //   queue[index] = data;
    // } else {
    //   queue.push(data);
    // }

    // return await set(queue_key, queue);
  },

  async page(params, force_cache_key, queryparams) {
    const online = await this.checkOnline();

    let cache_key;

    let slug = params.slug

    if (_has(params, "parentslug"))
      slug = params.parentslug

    console.log("get page", cache_key);

    // check if queryparams is set 
    if (typeof queryparams === 'undefined') {
      queryparams = '';
    }

    return new Promise((resolve, reject) => {
      return this.HTTP.post(`pageconfig/${slug}${queryparams}`, params)
        .then((resp) => {
          return resolve(resp);
        })
        .catch((e) => {
          return reject(e);
        });
    });
  },

  async bulk_insert(slug, rows) {
    let parts = _chunk(rows, 20);
    let jobs = [];

    _each(parts, (i) => {
      jobs.push(this.HTTP.post(`bulk/insert/${slug}`, { data: i }));
    });

    return Promise.all(jobs);
  },

  async local_page(params, force_cache_key) {
    let cache_key;


    let slug = params.slug

    if (_has(params, "parentslug"))
      slug = params.parentslug

    return new Promise((resolve, reject) => {
      return this.HTTP.post(`pageconfig/${slug
        }`, params)
        .then((resp) => {
          // set(cache_key, resp.data);
          return resolve(resp);
        })
        .catch((e) => {
          return reject(e);
        });
    });
  },

  async get(slug, params, force_cache_key) {
    let queryparams = "";

    if (_has(this.crud_db_keys, slug)) {
      queryparams += `&_db_key=${this.crud_db_keys[slug]}`;
    }
    return this.HTTP.post(`page/${slug}?${queryparams}`, params);
  },

  get_cache_key(prefix, slug, force_cache_key) {
    if (force_cache_key) return `${prefix}_${slug}_${force_cache_key}`;
    const url = new URL(window.location);
    let cache_key = url.searchParams.get("cache_key");
    if (!!cache_key) return `${prefix}_${slug}_${cache_key}`;
    return `${prefix}_${slug}`;
  },

  async query(slug, data, tab_slug) {
    return new Promise((resolve, reject) => {
      return this.HTTP.post(`query/${slug}/${tab_slug}`, data)
        .then((resp) => {
          return resolve(resp);
        })
        .catch((e) => {
          return reject(e);
        });
    });
  },

  async query_local(slug, data, force_cache_key) {
    // const online = await this.checkOnline();

    // let cache_key = this.get_cache_key("data", slug, force_cache_key);

    // let cache_resp = await GetCache(cache_key);
    // if (cache_resp) {
    //   return new Promise((resolve) => {
    //     return resolve({ data: cache_resp });
    //   });
    // }
  },
};

