<template>
  <span :class="css" class="el_1_f3aefc371776cb3d33dd0904c1346b32 inline-flex items-center px-2 font-medium rounded-lg py-0.25">
    <slot />
  </span>
</template>

<script>
import _get from "lodash/get";
export default {
  name: "TgBadge",
  props: {
    color: String,
    click: Function,
  },
  data() {
    return {
      colors: {
        primary:
          "bg-primary-100 text-primary-800 hover:bg-primary-400 hover:text-white",
        "primary-dark": "bg-primary-700 text-white hover:bg-primary-600",
        success:
          "bg-green-100 text-green-800 hover:bg-green-400 hover:text-white",
        "success-dark": "bg-green-500 text-white hover:bg-green-400",
        danger: "bg-red-100 text-red-700 hover:bg-red-200",
        warning:
          "bg-yellow-100 text-yellow-800 hover:bg-yellow-400 hover:text-white",
        gray: "bg-gray-100 text-gray-800 hover:bg-gray-400 hover:text-white",
        dark: "bg-gray-600 text-gray-100 hover:bg-gray-700 hover:text-white",
        info: "bg-primary-100 text-primary-800 hover:bg-primary-400 hover:text-white",
        default: "bg-gray-100 text-gray-800 hover:bg-gray-400 hover:text-white",
      },
    };
  },
  computed: {
    css() {
      return _get(this.colors, this.color, this.color);
    },
  },
};
</script>

<style>
</style>