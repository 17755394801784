<template>
  <TgSlidePanel :open="showFormPanel" :on-close="closeFormPanel" side="left" width="max-w-5xl" :overlay="true" z="z-40">
    <template #title>
      FR-{{ item.id }}
    </template>
    <template #subtitle>
      Flight report form
    </template>
    <template #content>
      <form-panel ref="FormPanel" :slug="slug" :tab="tab" :selected="item" @formerror="formHasError"
        @formsuccess="receiveFormData" />
    </template>
    <template #footer>
      <TgSubmit :disabled="submitting" @click="submitForm">
        Update
      </TgSubmit>
    </template>
  </TgSlidePanel>

  <div v-if="item" class="el_1_f08d9a633a0e7d7808a0f51038aa8648 bg-gray-100 rounded-md">
    <div class="el_2_f08d9a633a0e7d7808a0f51038aa8648 p-3 uppercase rounded-t-md" :class="stage_colors[item.stage_color]">
      <div class="el_3_f08d9a633a0e7d7808a0f51038aa8648 flex items-center text-white">
        <div class="el_4_f08d9a633a0e7d7808a0f51038aa8648 flex-1 text-lg">
          Flight report #{{ item.id }} -
          <span class="el_5_f08d9a633a0e7d7808a0f51038aa8648 text-base">({{ item.hoursremaining }} Remain)</span>
        </div>
        <div class="el_6_f08d9a633a0e7d7808a0f51038aa8648 flex-shrink-0 text-right">
          <TgBadge v-if="tab.access == 'Full'" color="dark" class="cursor-pointer" @click="openForm()">
            Edit
          </TgBadge>
        </div>
      </div>
    </div>
    <div class="el_7_f08d9a633a0e7d7808a0f51038aa8648 flex flex-col px-4 py-4 space-y-2">
      <div class="el_8_f08d9a633a0e7d7808a0f51038aa8648 flex-1 space-y-2">
        <div class="el_9_f08d9a633a0e7d7808a0f51038aa8648 flex items-center">
          <!-- <div class="el_10_f08d9a633a0e7d7808a0f51038aa8648 w-1/4">
            <div class="el_11_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Section
            </div>
            <div class="el_12_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 whitespace-normal">
              {{ item.section }}
            </div>
          </div> -->
          <div class="el_13_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_14_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              category
            </div>
            <div class="el_15_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.category }}
            </div>
          </div>
          <div class="el_16_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_17_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              subcategory
            </div>
            <div class="el_18_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.subcategory }}
            </div>
          </div>
          <div class="el_19_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_20_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              cabin class
            </div>
            <div class="el_21_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.cabinclass }}
            </div>
          </div>
        </div>
        <div class="el_22_f08d9a633a0e7d7808a0f51038aa8648 flex items-center">
          <div class="el_23_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_24_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Derived Supplier
            </div>
            <div class="el_25_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.supplier }}
            </div>
          </div>
          <div class="el_26_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_27_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Derived Port
            </div>
            <div class="el_28_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.caterer }}
            </div>
          </div>
          <div class="el_29_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_30_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Service Port
            </div>
            <div class="el_31_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.serviceport }}
            </div>
          </div>
        </div>
        <div class="el_35_f08d9a633a0e7d7808a0f51038aa8648 flex items-center">
          <div class="el_32_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_33_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Hours Remaining
            </div>
            <div class="el_34_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.hoursremaining }}
            </div>
          </div>
          <div class="el_36_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_37_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              OTP Minutes
            </div>
            <div class="el_38_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.minutes || "N/A" }}
            </div>
          </div>

          <!-- <div class="el_39_f08d9a633a0e7d7808a0f51038aa8648 w-2/4">
            <div class="el_40_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              MSC Flight
            </div>
            <div class="el_41_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.mscflag || "N/A" }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="el_42_f08d9a633a0e7d7808a0f51038aa8648 flex-col space-y-3">
        <div class="el_43_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_44_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
            Crew Comment
          </div>
          <div class="el_45_f08d9a633a0e7d7808a0f51038aa8648 text-base text-gray-600 whitespace-normal">
            {{ item.comments }}
          </div>
        </div>
        <div class="el_46_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_47_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
            Caterer Comment
          </div>
          <div class="el_48_f08d9a633a0e7d7808a0f51038aa8648 text-base text-gray-600 whitespace-normal">
            <TgBadge :color="item.supplier_css">
              {{ item.status }}
            </TgBadge>
            {{ item.catererresponse }}
          </div>
        </div>
        <div class="el_49_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_50_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
            Airline Reply
          </div>
          <div class="el_51_f08d9a633a0e7d7808a0f51038aa8648 text-base text-gray-600 whitespace-normal">
            <TgBadge :color="item.airline_css">
              {{ item.status_airline }}
            </TgBadge>
            {{ item.airlineresponse }}
          </div>
        </div>
        <div v-if="item.imagename" class="el_52_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_53_f08d9a633a0e7d7808a0f51038aa8648 mb-2 text-gray-400 uppercase">
            Photo
          </div>
          <tg-image :path="item.imagename" :thumb="true" class="shadow-md" />
        </div>
        <div class="el_54_f08d9a633a0e7d7808a0f51038aa8648 flex-1 mt-3 text-center">
          <div class="el_55_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
            Overall Status
          </div>
          <div class="el_56_f08d9a633a0e7d7808a0f51038aa8648 text-2xl text-gray-600 whitespace-normal">
            <TgBadge :color="item.stage_css">
              {{ item.stage }}
            </TgBadge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TgSlidePanel from "@/components/Common/TgSlidePanel.vue";
import { API } from "@/api";
import { STATUS_COLORS } from "@/default";
import TgBadge from "@/components/Common/TgBadge";
import TgSubmit from "@/components/Common/TgSubmit";
import TgImage from "@/components/Common/TgImage";

import _has from "lodash/has";
import FormPanel from "@/components/Grids/FormPanel.vue";

export default {
  components: {
    TgSlidePanel,
    TgSubmit,
    FormPanel,
    TgBadge,
    TgImage,
  },
  props: {
    item: Object,
    config: Object,
    tab: Object,
    refreshlist: Function,
  },
  emits: ["on:update"],
  data() {
    return {
      emitter: API.get_emitter(),
      // item: null,
      colors: STATUS_COLORS,
      categories: null,
      open: false,
      submitting: false,
      showFormPanel: false,
      slug: null,
      stage_colors: {
        red: "bg-red-400 text-white",
        yellow: "bg-yellow-400 text-white",
        blue: "bg-primary-400 text-white",
        green: "bg-green-400 text-white",
      },
    };
  },
  methods: {
    openForm() {
      let slug = "6464A3BE24474C98B28DB96AF4D9E9BD"; // HO form

      if (_has(this.config, "supplier")) {
        if (this.item.stage == "Closed IFS") {
          this.$notify({
            group: "toast",
            type: "danger",
            title: "Edit Denied",
            text: "This report has been closed. No changes can be made.",
          });
          return;
        }

        slug = "059C953AD93D456C9E1823FCFDB65995"; // supplier form - VA
        if (this.$auth.client.value.key == "ib") {
          if (this.item._canedit == 0) {
            this.$notify({
              group: "toast",
              type: "danger",
              title: "Edit Denied",
              text: "This report has been closed. No changes can be made.",
            });
            return;
          }

          slug = "CB33B3605C05442087833B872D73186A"; // supplier form - IB
        }
      } else {
        if (this.$auth.client.value.key == "ib") {
          slug = "7C2F568ADF5344B0885FCA0C16FD4221"; // HO form

          if (this.item._canedit_ho == 0) {
            this.$notify({
              group: "toast",
              type: "danger",
              title: "Edit Denied",
              text: "The caterer must reply first before you can edit this.",
            });
            return;
          }
        }
      }
      this.slug = slug;
      this.openFormPanel();
    },
    openFormPanel() {
      this.showFormPanel = true;
    },
    formHasError() {
      this.submitting = false;
    },
    async receiveFormData(data) {
      this.submitting = false;
      if (this.$auth.client.value.key == "va") {
        await API.proc({
          proc: "SLAp_01_RefreshSLARunner_byID",
          params: [data.row.id],
        });
      }
      this.emitter.emit("crew-report-updated");
      this.refreshlist();
      this.closeFormPanel();
    },
    submitForm() {
      this.submitting = true;
      this.$refs.FormPanel.submit();
    },
    closeFormPanel() {
      this.showFormPanel = false;
    },
  },
};
</script>
<style></style>
;
