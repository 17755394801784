<template>
  <div class="el_1_da7623cb6389c9e7a8af90c6741a2472 bg-white shadow sm:rounded-lg">
    <div class="el_2_da7623cb6389c9e7a8af90c6741a2472 px-4 py-2">
      <div class="el_3_da7623cb6389c9e7a8af90c6741a2472 flex items-center">
        <div class="el_4_da7623cb6389c9e7a8af90c6741a2472 flex-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            <slot name="header" />
          </h3>
        </div>
        <div class="el_5_da7623cb6389c9e7a8af90c6741a2472 flex-1 text-right">
          <slot name="header-buttons" />
        </div>
      </div>
      <div class="el_6_da7623cb6389c9e7a8af90c6741a2472 mt-2 text-sm text-gray-500">
        <slot name="content" />
      </div>
      <div class="el_7_da7623cb6389c9e7a8af90c6741a2472 mt-5">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TgPanel",
};
</script>

<style></style>
