<template>
  <label :for="item.field" @click="$emit('sticky')" class="relative block cursor-pointer text-sm font-medium uppercase text-primary-800">
    <span
      v-if="item.required"
      class="el_1_512a49d9bc2dc86de1a609593e69c1e8 inline mr-1 text-sm text-red-400"
    >*</span>
    {{ item.name }}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" v-if="item.sticky" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline text-red-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

    <div class="el_2_512a49d9bc2dc86de1a609593e69c1e8 absolute top-0 right-0">
      <InformationCircleIcon
        v-if="item.popover"
        class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
        @click="show_help = !show_help"
      />
    </div>
  </label>
  <div class="el_3_512a49d9bc2dc86de1a609593e69c1e8 flex mt-1 rounded-md shadow-sm">
    <textarea
      :id="item.field"
      autocomplete="off"
      :value="modelValue"
      tabindex="0"
      :readonly="item.sticky"
      :name="item.field"
      :disabled="item.disabled || item.lock_edits"
      rows="3"
      class="block w-full max-w-full pr-6 border read-only:bg-yellow-50 border-gray-200 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
      :class="[error ? 'border-red-500' : '', item.input_css]"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
  <div
    v-if="show_help && item.popover"
    class="el_4_512a49d9bc2dc86de1a609593e69c1e8 absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
    @click="show_help = false"
  >
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
export default {
  components: {
    InformationCircleIcon,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
    };
  },
};
</script>
