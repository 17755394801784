<template>
  <button :disabled="disabled" :class="colors[color]"
    class="relative inline-flex items-center text-center mr-1 text-base font-medium leading-4 text-white border border-transparent rounded shadow-sm lg:px-3 lg:py-1 md:px-5 md:py-3 disabled:opacity-50 focus:outline-none"
    @click="click">
    <slot />
  </button>
</template>

<script>
export default {
  name: "TgBtn",
  props: {
    color: String,
    disabled: Boolean,
    click: Function,
    icon: String,
  },
  setup() {
    const colors = {
      primary: "bg-primary-300 hover:bg-primary-500",
      "primary-200": "bg-primary-200 hover:bg-primary-500",
      "primary-300": "bg-primary-300 hover:bg-primary-500",
      green: "bg-green-500 text-white hover:bg-green-600",
      danger: "bg-red-300 hover:bg-red-500",
      sky: "bg-sky-300 hover:bg-sky-500",
      success: "bg-green-500 hover:bg-green-600",
      orange: "bg-orange-500 hover:bg-orange-600",
      gray: "bg-gray-600 hover:bg-gray-400",
      "gray-100": "bg-gray-100 hover:bg-gray-400",
      "gray-200": "bg-gray-200 text-gray-500 hover:bg-gray-400 hover:text-white",
      invert: "bg-gray-700",
      transparent: "bg-transparent",
    };
    return {
      colors,
    };
  },

  data() {
    return {};
  },
};
</script>
