<template>
  <div v-if="open" class="el_1_0b9209c19425f89d517536035710bf0d">
    <div class="el_2_0b9209c19425f89d517536035710bf0d w-full p-5 mb-2 font-bold text-center bg-gray-200 rounded-lg text-7xl">
      {{ val }}
    </div>
    <div v-for="item in buttons" :key="item" class="el_3_0b9209c19425f89d517536035710bf0d grid items-center grid-cols-2 gap-1 mb-1 text-center">
      <button class="col-span-1 p-5 text-2xl bg-primary-200" @click="inc(item)">
        +{{ item }}
      </button>
      <button class="col-span-1 p-5 text-2xl text-white bg-primary-600 disabled:bg-gray-400 disabled:opacity-50" :disabled="val < item" @click="dec(item)">
        -{{ item }}
      </button>
    </div>

    <button class="w-full p-10 mb-20 text-3xl font-bold text-center text-white uppercase bg-green-400 rounded-lg" @click="submit">
      Apply
    </button>
  </div>
</template>

<script>
import inc from "@/assets/inc.mp3";
import dec from "@/assets/dec.mp3";

export default {
  props: {
    qty: Number,
  },
  emits: ["update-qty"],
  data() {
    return {
      open: true,
      val: this.qty,
      audio_inc: new Audio(inc),
      audio_dec: new Audio(dec),
      buttons: [1, 2, 5, 10, 20, 50],
    };
  },

  methods: {
    inc(num) {
      this.audio_inc.playbackRate = 5;

      this.audio_inc.play();
      this.val = this.val + num;
    },
    dec(num) {
      this.audio_dec.playbackRate = 3;

      this.audio_dec.play();
      let v = this.val + -num;
      if (v < 0) v = 0;
      this.val = v;
    },
    submit() {
      this.open = false;
      this.$emit("update-qty", this.val);
    },
  },
};
</script>

<style scoped>
button {
  touch-action: manipulation;
}
</style>


