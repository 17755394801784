<template>
  <div v-if="data" class="el_1_58578d79a11301b0de2abf335e9474ab">
    <div class="el_2_58578d79a11301b0de2abf335e9474ab absolute top-5 right-6">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>
    </div>
    <form v-if="data" autocomplete="off" class="pt-5" @submit.prevent>
      <div class="el_3_58578d79a11301b0de2abf335e9474ab grid grid-cols-6 mt-6 gap-y-6 gap-x-4">
        <div class="el_4_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Before Toolbar Slot
          </label>
          <div class="el_5_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.before_toolbar"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="el_6_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Before Toolbar Slot Params
          </label>
          <div class="el_7_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.before_toolbar_params"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>

        <div class="el_8_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            End of Toolbar Slot
          </label>
          <div class="el_9_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.end_toolbar"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="el_10_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            End of Toolbar Slot Params
          </label>
          <div class="el_11_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.end_toolbar_params"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>

        <div class="el_12_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            End of Toolbar Slot 2
          </label>
          <div class="el_13_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.end_toolbar2"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="el_14_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            End of Toolbar Slot 2 Params
          </label>
          <div class="el_15_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.end_toolbar_params2"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="el_12_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            End of Toolbar Slot 3
          </label>
          <div class="el_13_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.end_toolbar3"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="el_14_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            End of Toolbar Slot 3 Params
          </label>
          <div class="el_15_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.end_toolbar_params3"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>

        <div class="el_16_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            After Toolbar Slot
          </label>
          <div class="el_17_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.after_toolbar"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="el_18_58578d79a11301b0de2abf335e9474ab col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            After Toolbar Slot Params
          </label>
          <div class="el_19_58578d79a11301b0de2abf335e9474ab mt-1">
            <input
              id="size"
              v-model="data.pageconfig.slots.after_toolbar_params"
              type="text"
              name="size"
              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
          </div>
        </div>
      </div>
    </form>
  </div>

  <Code2FA
    v-if="get_otp"
    :sending="sending"
    :error="error"
    @submit="save"
    @cancel="get_otp = false"
  />
</template>

<script>
import { STORE } from "@/store";
import { API } from "@/api";
import _has from "lodash/has";
import _isArray from "lodash/isArray";
import Code2FA from "@/components/Common/Code2FA";
import TgBtn from "@/components/Common/TgBtn";

export default {
  name: "ConfigSettings",
  components: {
    TgBtn,
    Code2FA,
  },
  props: {
    slug: String,
  },
  emits: ["on:save"],
  data() {
    return {
      sending: false,
      data: null,
      error: null,
      get_otp: false,
    };
  },

  async mounted() {
    let resp = await API.page({ slug: this.slug });

    if (!_has(resp.data.data.pageconfig, "slots")) {
      resp.data.data.pageconfig.slots = {};
    }
    this.data = resp.data.data;
    if (_isArray(this.data.pageconfig.slots)) {
      this.data.pageconfig.slots = {};
    }
  },
  methods: {
    save(otp) {
      this.sending = true;
      STORE.set(this.slug, this.data, otp)
        .then(() => {
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Page Saved!",
          });
          this.sending = false;
          this.get_otp = false;
          this.$emit("on:save");
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
