<template>
  <img :src="img" class="cursor-pointer shadow-md rounded-md" @click="openModal">
  <tg-modal :open="open" size="w-[90%]" class="mx-3" @modal-close="onClose">
    <template #header>
      Full Size
    </template>

    <template #content>
      <div class="el_1_33f2b2970637a10e5c786ea58ef7438d p-32 pt-10 mx-auto">
        <img :src="full_img" class="mx-auto">
      </div>
    </template>
  </tg-modal>
</template>

<script>
import TgModal from "@/components/Common/TgModal.vue";
export default {
  name: "TgImage",
  components: {
    TgModal,
  },
  props: {
    path: String,
    class: String,
    thumb: Boolean,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    img() {
      let url = ["//res.cloudinary.com/tplive/image/upload/c_scale,q_auto:low,h_50"];

      if (this.thumb) url.push("t_media_lib_thumb");

      url.push(this.path);

      return url.join("/");
    },
    full_img() {
      let url = ["//res.cloudinary.com/tplive/image/upload/c_scale,q_auto:low"];

      url.push(this.path);

      return url.join("/");
    },
  },
  methods: {
    openModal() {
      this.open = true;
    },
    onClose() {
      this.open = false;
    },
  },
};
</script>

<style></style>
