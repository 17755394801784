<template>
  <div class="el_1_68e14cfceb89954e6818ea99000b36a2 flex items-center p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100">
    <div class="el_2_68e14cfceb89954e6818ea99000b36a2 w-6/12 pl-2">
      <div class="el_3_68e14cfceb89954e6818ea99000b36a2 inline-flex items-center ">
        <MenuIcon class="w-4 h-4 mr-3 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        <SortAscendingIcon :class="data.direction ? 'text-green-400' : 'text-gray-300'" class="w-4 h-4 mr-2 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="setSortDirection(true)" />
        <SortDescendingIcon :class="!data.direction ? 'text-green-400' : 'text-gray-300'" class="w-4 h-4 mr-2 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="setSortDirection(false)" />
        <EyeIcon :class="!data.hide ? 'text-green-400' : 'text-gray-300'" class="w-4 h-4 mr-2 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="toggle('hide')" />

        {{ data.name }}
      </div>
    </div>
    <div class="el_4_68e14cfceb89954e6818ea99000b36a2 w-6/12 text-right">
      <div class="el_5_68e14cfceb89954e6818ea99000b36a2 inline-flex items-center ">
        <tg-badge color="danger" @click="remove()">
          X
        </tg-badge>
      </div>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { MenuIcon } from "@heroicons/vue/solid";
import {
  SortAscendingIcon,
  SortDescendingIcon,
  EyeIcon,
} from "@heroicons/vue/solid";

import _has from "lodash/has";

export default {
  name: "GroupItem",
  components: {
    TgBadge,
    MenuIcon,
    SortDescendingIcon,
    SortAscendingIcon,
    EyeIcon,
  },
  props: {
    item: Object,
  },
  emits: { "toggle-item-feature": null, "remove-item": null },
  data() {
    let item = this.item;

    if (!_has(this.item, "direction")) item.direction = false;
    return {
      data: item,
    };
  },
  methods: {
    setSortDirection(state) {
      this.data.direction = state;
    },
    toggle(fld) {
      this.data[fld] = !this.data[fld];
      this.$emit("toggle-item-feature", { fld: fld, item: this.data });
    },
    remove() {
      this.$emit("remove-item", { fld: "groupby", item: this.data });
    },
  },
};
</script>

<style>
</style>