<template>
  <label :for="item.field" class="relative block text-sm font-medium uppercase text-primary-800">
    <span
      v-if="item.required"
      class="el_1_9a8789be07f8cab319e83d31279a87d2 inline mr-1 text-sm text-red-400"
    >*</span>
    {{ item.name }}
  </label>
  <CameraIcon v-if="!base64Photo" class="w-20 h-20 mx-auto text-gray-300" @click="openCamera" />
  <div v-else class="el_2_9a8789be07f8cab319e83d31279a87d2 mx-auto" @click="openCamera">
    <img :src="base64Photo" class="h-32 p-2 mx-auto border border-gray-200 rounded-md shadow-lg">
  </div>

  <input
    ref="file"
    type="file"
    class="hidden"
    accept="image/x-png,image/jpeg,image/gif"
    @change="onChange"
  >
</template>

<script>
import { CameraIcon } from "@heroicons/vue/outline";
import _has from "lodash/has";
import _head from "lodash/head";

export default {
  components: {
    CameraIcon,
  },
  props: {
    item: Object,
    filename: String,
    folder: String,
    slug: String,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
      no_image: false,
      files: [],
      base64Photo: null,
    };
  },
  methods: {
    openCamera() {
      this.$refs.file.click();
    },
    async onChange(evt) {
      console.log(evt);
      const photo = _head(evt.target.files);
      if (photo) {
        this.base64Photo = await this.convert(photo);
        this.$emit("update:modelValue", this.base64Photo);
      }
    },
    resizeBase64Img(base64, newWidth, newHeight) {
      return new Promise((resolve, reject) => {
        var canvas = document.createElement("canvas");
        // canvas.style.width = newWidth.toString() + "px";
        // canvas.style.height = newHeight.toString() + "px";
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {
          // context.scale(newWidth / img.width, newHeight / img.height);
          context.drawImage(img, 0, 0);
          resolve(canvas.toDataURL());
        };
      });
    },
    convert(myFile) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        if (fileReader && myFile) {
          fileReader.readAsDataURL(myFile);
          fileReader.onload = () => {
            resolve(fileReader.result);
            // resolve(this.resizeBase64Img(fileReader.result, 600, 800));
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        } else {
          reject("No file provided");
        }
      });
    },
  },
};
</script>
