import {
  CogIcon,
  MapIcon,
  AdjustmentsIcon,
  ChartPieIcon,
  CloudIcon,
  ShieldCheckIcon,
  ClockIcon,
  ShareIcon,
  HomeIcon,
  UserGroupIcon,
  DeviceMobileIcon,
  SparklesIcon,
  SwitchHorizontalIcon,
  ArrowsExpandIcon,
  FlagIcon,
  MailOpenIcon,
  HeartIcon,
} from "@heroicons/vue/outline";

import _each from "lodash/each";
import _has from "lodash/has";

export const NAV = {
  mobile: [
    {
      name: "Lastmile",
      icon: ShareIcon,
      group: 50,
      slug: "903CBA74F6D34672A221D44D7E787096",
    },
    {
      name: "MIV",
      icon: ClockIcon,
      group: 51,
      slug: "1D64D903CB672A227EA74F6D34787096",
    },
    {
      name: "Crew",
      icon: UserGroupIcon,
      group: 52,
      slug: "A28B47F3D45B43AAAF79F4C530A5A485",
      locked: false,
    },
    {
      name: "Cleaning",
      icon: SparklesIcon,
      group: 53,
      slug: "2E8AE23F88434EE8ADBAB0CAFBD21CD0",
      locked: true,
    },
    {
      name: "Ground Handling",
      icon: FlagIcon,
      group: 74,
      slug: "AEFB82BC0E9242F885AB01651333FFBE",
      locked: false,
    },
    {
      name: "Amco",
      icon: SwitchHorizontalIcon,
      group: 54,
      slug: "43386B967D204DA885F40171173B03F2",
      locked: false,
    },
    {
      name: "Supply Chain",
      icon: ArrowsExpandIcon,
      group: 55,
      slug: "76723D76E8F34621AE3997788A5FE1EE",
      locked: true,
    },
    // {
    //   name: "Inbox",
    //   icon: MailOpenIcon,
    //   group: 56,
    //   slug: "7D51A4A932AC4E419F5A1010AC2D294C",
    //   locked: true,
    // },
    {
      name: "Health",
      icon: HeartIcon,
      group: 57,
      slug: "health",
      // slug: "84EE45E31B444318A77B74A72764DBBA",
      locked: false,
    },
  ],
  mobile_roles: [
    {
      name: "Lastmile",
      icon: ShareIcon,
      group: 50,
      slug: "s_903CBA74F6D34672A221D44D7E787096",
    },
    {
      name: "MIV",
      icon: ClockIcon,
      group: 51,
      slug: "s_1D64D903CB672A227EA74F6D34787096",
    },
    {
      name: "Crew",
      icon: UserGroupIcon,
      group: 52,
      slug: "s_A28B47F3D45B43AAAF79F4C530A5A485",
      locked: false,
    },
    {
      name: "Cleaning",
      icon: SparklesIcon,
      group: 53,
      slug: "s_2E8AE23F88434EE8ADBAB0CAFBD21CD0",
      locked: true,
    },
    {
      name: "Ground Handling",
      icon: FlagIcon,
      group: 74,
      slug: "s_AEFB82BC0E9242F885AB01651333FFBE",
      locked: false,
    },
    {
      name: "Amco",
      icon: SwitchHorizontalIcon,
      group: 54,
      slug: "s_43386B967D204DA885F40171173B03F2",
      locked: false,
    },
    {
      name: "Supply Chain",
      icon: ArrowsExpandIcon,
      group: 55,
      slug: "s_76723D76E8F34621AE3997788A5FE1EE",
      locked: true,
    },
    // {
    //   name: "Inbox",
    //   icon: MailOpenIcon,
    //   group: 56,
    //   slug: "s_7D51A4A932AC4E419F5A1010AC2D294C",
    //   locked: true,
    // },
    {
      name: "Health",
      icon: HeartIcon,
      group: 57,
      slug: "health",
      // slug: "84EE45E31B444318A77B74A72764DBBA",
      locked: false,
    },
  ],
  data: [
    {
      name: "Planning",
      icon: MapIcon,
      children: [
        {
          name: "Inventory",
          group: 10,
          slug: "EF36CAA551AB4AAD0034DF17F4B1B191",
        },
        {
          name: "Menu Designer",
          group: 203,
          slug: "3C9820B616A746E891281B303838FA57",
        },
        {
          name: "Menu Planning",
          group: 11,
          slug: "0FD84C4E7BBEB42676E3C2B7B705FC15",
        },
        {
          name: "Galley Planning",
          group: 12,
          slug: "8FC3A86DA5BEB6DF81AFBC90CD53C366",
        },
        {
          name: "Cleaning Planning",
          group: 8,
          slug: "073BF6351F0B495CBB7BC3198E692718",
        },
        {
          name: "Ground Handling",
          group: 8,
          slug: "5FCE30F30E6D4F129857BCB3B5709415",
        },
        {
          name: "Supply Chain",
          group: 20,
          slug: "8CCC684F38DA428DA2B26669BCDE62E1",
        },
      ],
    },
    {
      name: "Operations",
      icon: AdjustmentsIcon,
      children: [
        {
          name: "Scheduling",
          group: 13,
          slug: "8714DA9108B8A1D772CC095512490BDB",
        },
        {
          name: "Catering Operations",
          group: 14,
          slug: "65A4F163A5CBE41BA910FB6F622DC403",
        },
        {
          name: "Cleaning Operations",
          group: 15,
          slug: "2F07F7BCC91902DBEBA321185C8074CA",
        },
        {
          name: "Ground Operations",
          group: 15,
          slug: "B60E68DA7E984BC78BD1AC22893860B3",
        },
        {
          name: "Supplier Performance",
          group: 17,
          slug: "2792DA61C6690A77AF47557138718648",
        },
      ],
    },
    {
      name: "Analysis",
      icon: ChartPieIcon,
      children: [
        {
          name: "Finance & Billing",
          group: 16,
          slug: "36D04518A0D77E138B4568F2551408BE",
        },
        {
          name: "Retail Sales",
          group: 19,
          slug: "FB1605E3C16D4194B61AB29F00F3139A",
        },
        {
          name: "Procurement",
          group: 87,
          slug: "3C9820B616A746E891281B303838FA57",
        },
      ],
    },
    {
      name: "Portals",
      icon: CloudIcon,
      children: [],
    },
    {
      name: "Mobile",
      icon: DeviceMobileIcon,
      children: [],
    },
    {
      name: "Admin",
      icon: ShieldCheckIcon,
      children: [
        {
          name: "Setup",
          group: 7,
          slug: "211B582D94AF4EF896E959B6B91DD783",
        },
        {
          name: "User Management",
          group: 23,
          slug: "0EF4D58449FB1F8317AD77CFA08C1E6C",
        },
        {
          name: "Monitor",
          group: 22,
          slug: "E095D0456F104EC6BCDD37FFB9F14FD0",
        },
        {
          name: "Touchpoint Admin",
          group: 18,
          slug: "FD753B64E3F43F87CABC62F24F03BDFB",
        },
        {
          name: "Admin Operations",
          group: 987,
          slug: "91BBCBB8C5814CC6BAE2724C4C3F3D53",
        },
      ],
    },
  ],
  suppliers: [
    {
      name: "Home",
      icon: HomeIcon,
      slug: "home",
    },
    {
      name: "Portals",
      icon: CloudIcon,
      children: [],
    },
    {
      name: "Mobile",
      icon: DeviceMobileIcon,
      children: [],
    }
  ],
  suppliers_no_portals: [
    {
      name: "Home",
      icon: HomeIcon,
      slug: "home",
    },
    {
      name: "Mobile",
      icon: DeviceMobileIcon,
      children: [],
    }
  ],
  getCurrent(slug) {
    let current = null;
    _each(this.data, (i) => {
      if (_has(i, "children")) {
        _each(i.children, (j) => {
          if (j.slug == slug) current = j;
        });
      } else {
        if (i.slug == slug) current = i;
      }
    });
    return current;
  },
  getPortals(client) {
    if (client == "demo") {
      return [
        {
          name: "Sample Caterer A",
          slug: "1D1044D763FD449BA5BDFA8DA19C1C36",
          domain: "demoA.com",
        },
      ];
    }

    if (client == "ib") {
      return [
        {
          name: "DO&CO",
          slug: "C4AA23A6EEF5E1F3B0FC7AADB73F27EB",
          domain: "doco.com",
        },
        {
          name: "GATE GOURMET",
          slug: "F8DE6EA869DC47AAB9C9DFA74128577F",
          domain: "gategourmet.com,gategroup.com,gategroupmaasa.com",
        },
        {
          name: "GCG GROUP",
          slug: "81114DB006FC42DE98AF06452D86D0D3",
          domain: "gcggroup.com,gcguy.com",
        },
        {
          name: "CUBA CATERING",
          slug: "B5EA9B2DE0F54C8790604353079D7B3A",
          domain: "cubacatering.avianet.cu",
        },
        {
          name: "JJ ROCA",
          slug: "8608E383126241EBA4026360B50E28D2",
          domain: "jjroca.com",
        },
        {
          name: "MGI CARIBE",
          slug: "3EE08C1144E14D92894722382A1DB67C",
          domain: "mgicaribe.com",
        },
        {
          name: "NEWREST",
          slug: "8F94EFA138034911B992968F7CCA68BA",
          domain: "newrest.eu",
        },
        {
          name: "QATAR",
          slug: "126240E2818608E383402EBA6360B5D2",
          domain: "qatarairways.com.qa",
        },
        {
          name: "SERVAIR SENEGAL",
          slug: "11B9289F94EFA138034CCA69968F78BA",
          domain: "servair.sn",
        },

        {
          name: "TFK",
          slug: "85B8C74015BF478C94DC81892F1D1FF0",
          domain: "satstfk.co.jp",
        },
      ];
    }
    if (client == "va") {
      return [
        {
          name: "APHC-DPS",
          slug: "DC7D53D38B7F47CE94498E556BF0D8FA",
          domain: "aphotels.co.id,aphic.id",
        },
        {
          name: "AUS Flight Handling",
          slug: "C4D353F6035FBBBE541923400B692F37",
          domain: "ausflighthandling.com.au",
        },
        {
          name: "ATS",
          slug: "5C17061F1C5D477CAAD053253F2F6E5B",
          domain: "atsgroup.net,ats.com.fj",
        },
        {
          name: "CSA",
          slug: "95DC2450A11F137E7E86F1B188D93C85",
          domain: "cabin-services.com",
        },

        {
          name: "Dnata",
          slug: "388E5AECFB17485CB8328A7484D52267",
          domain: "dnata.com",
        },
        {
          name: "FFA",
          slug: "D4E02E81C8FABF04D3E1E73724651259",
          domain: "inflightcatering.com.au,foodfolk.com.au",
        },
        {
          name: "Gate Gourmet",
          slug: "6C5A88B9F46477B3C6AAE6666C22E48E",
          domain: "gategroup.com,gategourmet.com",
        },

        {
          name: "Link Airways",
          slug: "06C84BAC05A44E9DA4679D1BCBAE58BC",
          domain: "linkairways.com,corporate-air.com.au",
        },

        {
          name: "LSG",
          slug: "446DFDD9343D4D74A7AF095DDFC61B03",
          domain: "lsgskychefs.com",
        },

        {
          name: "Menzies",
          slug: "66E5B362776247489259FF57DF016AF9",
          domain: "menziesaviation.com",
        },
        {
          name: "Oceania",
          slug: "B47824A98CA3CAD3F46EA1618241E96B",
          domain: "oceaniaaviation.com",
        },
        {
          name: "Spotless",
          slug: "928F27CE98494143B80166B50A132E22",
          domain: "spotless.com.au",
        },
        {
          name: "Swissport",
          slug: "712C081B0AF1D044A59FFB40DF7221F5",
          domain: "swissport.com.au",
        },
        {
          name: "Swissport NZ",
          slug: "1D0BB22CD55E1587DD6D75F061318ECC",
          domain: "swissport.com",
        },
        {
          name: "TFK",
          slug: "D997B396AD474CA78A389C76C6FFED80",
          domain: "satstfk.co.jp",
        },
      ];
    }
  },
};
