<template>
  <div class="el_1_7cd239ab2ef5764a9ae8d3334ed06e87 flex p-3 space-x-3 bg-gray-200">
    <div class="el_2_7cd239ab2ef5764a9ae8d3334ed06e87 flex-1">
      <flight-details v-if="item" :flrkey="item.flrkey" />
    </div>
    <div v-if="config.crew" class="el_3_7cd239ab2ef5764a9ae8d3334ed06e87 flex-1">
      <flight-crew-reports v-if="item" :flrkey="item.flrkey" :item="item" :config="config" :tab="tab" />
    </div>
  </div>
</template>

<script>
import FlightDetails from "@/components/Touchpoint/Schedules/Flight/FlightDetails.vue";
import FlightCrewReports from "@/components/Touchpoint/Schedules/Flight/FlightCrewReports.vue";

import _get from "lodash/get";
import _has from "lodash/has";
export default {
  components: {
    FlightDetails,
    FlightCrewReports,
  },
  props: {
    slug: String,
    tab: Object,
    level: Number,
    params: String,
    flrkey: String,
  },
  data() {
    return {
      item: null,
      config: {},
    };
  },
  created() {
    if (this.flrkey) {
      this.item = { flrkey: this.flrkey };
      return;
    }

    if (this.tab.comp_props) {
      this.config = JSON.parse(this.tab.comp_props);
    }

    let modal_row = {};
    let tab_drill_config_filters = {};

    if (_has(this.tab, "tab_drill_config")) {
      modal_row = this.tab.tab_drill_config.modal_row;
      tab_drill_config_filters = this.tab.tab_drill_config.filters;
    }

    let flrkey = _get(this.$route.query, "flrkey", null);

    if (_has(this.tab.tab_drill_config, "flrkey")) {
      flrkey = _get(modal_row, this.tab.tab_drill_config.flrkey);
    }

    if (!flrkey) flrkey = _get(this.$route.query, "flightkeyfinal", null);

    if (!flrkey) flrkey = _get(this.$route.query, "flightkeyload", null);

    if (!flrkey) flrkey = _get(this.$route.query, "flightkeymeal", null);

    if (!flrkey) flrkey = modal_row.flrkey;

    if (!flrkey) flrkey = modal_row.loadflight;

    if (!flrkey) flrkey = modal_row.flightkeyfinal;

    if (!flrkey) flrkey = modal_row.flightkeyload;

    if (!flrkey) flrkey = modal_row.flightkeymeal;

    this.item = { flrkey: flrkey, row: modal_row };
  },
};
</script>

<style></style>
