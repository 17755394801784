<template>
  <div class="el_1_e1332613b2b1baad5a685dfa376bc76e inline-flex items-start">
    <slot />
  </div>
</template>

<script>
export default {
  name: "TgToolbarItem",
};
</script>

<style>
</style>