<template>
  <TgPanel class="flex-1 text-left">
    <template #header>
      Flight Submits
    </template>
    <template #header-buttons>
      <tg-btn color="gray" class="mr-1" :disabled="loading" @click="refresh">
        Refresh
      </tg-btn>
    </template>
    <template #content>
      <TgLoader v-if="loading" class="mx-auto my-20" />
      <div v-if="!loading" class="el_1_634526ab05a93950b8d1f96ff84bc35c mt-4">
        <div v-if="data.length == 0" class="el_2_634526ab05a93950b8d1f96ff84bc35c py-20 text-lg text-center">
          No submits found.
        </div>
        <div v-for="(item,index) in data" :key="index" class="el_3_634526ab05a93950b8d1f96ff84bc35c flex pt-3 my-3 space-x-2 space-y-2 border-t border-gray-200 border-dotted">
          <div class="el_4_634526ab05a93950b8d1f96ff84bc35c flex-1">
            <div class="el_5_634526ab05a93950b8d1f96ff84bc35c text-sm text-gray-400 uppercase">
              stage
            </div>
            <div class="el_6_634526ab05a93950b8d1f96ff84bc35c uppercase text-primary-400">
              {{ item.stage }}
            </div>
          </div>
          <div class="el_7_634526ab05a93950b8d1f96ff84bc35c flex-1">
            <div class="el_8_634526ab05a93950b8d1f96ff84bc35c text-sm text-gray-400 uppercase">
              service
            </div>
            <div class="el_9_634526ab05a93950b8d1f96ff84bc35c">
              {{ item.service }}
            </div>
          </div>
          <!-- <div class="el_10_634526ab05a93950b8d1f96ff84bc35c flex-1">
            <div class="el_11_634526ab05a93950b8d1f96ff84bc35c text-sm text-gray-400 uppercase">
              supplier
            </div>
            <div class="el_12_634526ab05a93950b8d1f96ff84bc35c">{{ item.supplier }}</div>
          </div> -->
          <div class="el_13_634526ab05a93950b8d1f96ff84bc35c flex-1">
            <div class="el_14_634526ab05a93950b8d1f96ff84bc35c text-sm text-gray-400 uppercase">
              submitted by
            </div>
            <div class="el_15_634526ab05a93950b8d1f96ff84bc35c">
              {{ item.fullname }}
            </div>
          </div>
          <div class="el_16_634526ab05a93950b8d1f96ff84bc35c flex-1">
            <div class="el_17_634526ab05a93950b8d1f96ff84bc35c text-sm text-gray-400 uppercase">
              submitted at
            </div>
            <div class="el_18_634526ab05a93950b8d1f96ff84bc35c">
              {{ item.created }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </TgPanel>
</template>

<script>
import { API } from "@/api";
import _map from "lodash/map";
import dayjs from "dayjs";

import TgBtn from "@/components/Common/TgBtn";
import TgLoader from "@/components/Common/TgLoader";
import TgPanel from "@/components/Common/TgPanel";
export default {
  components: {
    TgLoader,
    TgBtn,
    TgPanel,
  },
  props: {
    flrkey: String,
  },
  data() {
    return {
      loading: true,
      data: [],
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      let resp = await API.get("AE873B482A304242AD30A1B361BB2B03", {
        mealflight: this.flrkey,
        _distinct: 1,
        _current: 1,
        orderBy: "created",
        _fields: ["supplier", "service", "stage", "created", "fullname"],
      });

      if (resp) {
        this.data = _map(resp.data.data, (i) => {
          i.created = dayjs(i.created).format("MMM DD HH:mm");
          return i;
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>