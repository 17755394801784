<template>
  <div v-if="data.pageconfig" class="el_1_24dce7b0c7fe9c6e59a08f152326791e relative">
    <div class="el_2_24dce7b0c7fe9c6e59a08f152326791e absolute right-6 -top-10">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>
      <tg-btn color="primary" :click="copy">
        Copy
      </tg-btn>
      <tg-btn color="gray" :click="paste">
        Paste
      </tg-btn>
    </div>

    <fields-config-modal
      v-if="openFieldsConfigModal"
      :table="data.data_view"
      :widthtype="data.pageconfig.grid_width_type"
      :slug="slug"
      :select="data.pageconfig.select"
      @update:select="data.pageconfig.select = $event"
      @modal-close="openFieldsConfigModal = false"
    />

    <div class="el_3_24dce7b0c7fe9c6e59a08f152326791e flex w-full bg-gray-100">
      <div class="el_4_24dce7b0c7fe9c6e59a08f152326791e flex-grow m-3 w-3/12">
        <tg-panel>
          <template #header>
            All Fields
          </template>
          <template #header-buttons>
            <div class="el_5_24dce7b0c7fe9c6e59a08f152326791e flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                @click="get_available_fields"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </div>
          </template>
          <template #content>
            <input
              v-model="search_query"
              type="text"
              class="block my-2 w-full rounded-lg border-b border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-400"
              placeholder="Search..."
            >
            <div
              v-for="(item, index) in available_fields_results"
              :key="item.field"
              class="el_6_24dce7b0c7fe9c6e59a08f152326791e p-1 mb-1 w-full rounded-lg border border-gray-200 hover:bg-primary-100"
              @click="add(item, index)"
            >
              {{ item.name }}
            </div>
          </template>
        </tg-panel>
      </div>
      <div class="el_7_24dce7b0c7fe9c6e59a08f152326791e flex-grow m-3 w-5/12">
        <tg-panel>
          <template #header>
            Selected Fields
          </template>
          <template #header-buttons>
            <tg-btn color="gray" class="mr-1" :click="toggleFieldsConfigModal">
              Config
            </tg-btn>
            <tg-btn color="gray" :click="addCustomField">
              Cust
            </tg-btn>
          </template>
          <template #content>
            <draggable v-model="data.pageconfig.select" item-key="field" handle=".handle">
              <template #item="{ element }">
                <data-item
                  :item="element"
                  :class="
                    dirty && !dirty[element.field]
                      ? 'text-red-500 bg-red-100 blink-100 opacity-100 font-bold'
                      : ''
                  "
                  @toggle-item-feature="toggleItemFeature"
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>
      </div>
      <div class="el_8_24dce7b0c7fe9c6e59a08f152326791e flex-grow m-3 w-4/12">
        <tg-panel class="mb-5">
          <template #header>
            Group By
          </template>
          <template #content>
            <draggable v-model="data.pageconfig.groupby" item-key="field" handle=".handle">
              <template #item="{ element }">
                <group-item
                  :item="element"
                  :class="
                    dirty && !dirty[element.field]
                      ? 'text-red-500 bg-red-100 blink-100 opacity-100 font-bold'
                      : ''
                  "
                  @toggle-item-feature="toggleItemFeature"
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>

        <tg-panel class="mb-5">
          <template #header>
            Sorting
          </template>
          <template #content>
            <draggable v-model="data.pageconfig.orderby" item-key="field" handle=".handle">
              <template #item="{ element }">
                <sort-item
                  :item="element"
                  :class="
                    dirty && !dirty[element.field]
                      ? 'text-red-500 bg-red-100 blink-100 opacity-100 font-bold'
                      : ''
                  "
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>

        <tg-panel class="mb-5">
          <template #header>
            Filters
          </template>
          <template #header-buttons>
            <tg-btn
              :color="data.pageconfig.no_counts ? 'success' : 'gray'"
              @click="data.pageconfig.no_counts = !data.pageconfig.no_counts"
            >
              No Counts
            </tg-btn>
          </template>
          <template #content>
            <draggable v-model="data.pageconfig.filters" item-key="field" handle=".handle">
              <template #item="{ element }">
                <filter-item
                  :item="element"
                  :class="
                    dirty && !dirty[element.field]
                      ? 'text-red-500 bg-red-100 blink-100 opacity-100 font-bold'
                      : ''
                  "
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>

        <tg-panel class="mb-5">
          <template #header>
            Pivot
          </template>
          <template #content>
            <draggable v-model="data.pageconfig.pivot_arr" item-key="field" handle=".handle">
              <template #item="{ element }">
                <pivot-item
                  :item="element"
                  :class="
                    dirty && !dirty[element.field]
                      ? 'text-red-500 bg-red-100 blink-100 opacity-100 font-bold'
                      : ''
                  "
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>
        <tg-panel class="mb-5">
          <template #header>
            Values
          </template>
          <template #header-buttons>
            <tg-btn
              :color="data.pageconfig.values_totals ? 'success' : 'gray'"
              @click="data.pageconfig.values_totals = !data.pageconfig.values_totals"
            >
              Totals
            </tg-btn>
          </template>
          <template #content>
            <draggable v-model="data.pageconfig.values" item-key="field" handle=".handle">
              <template #item="{ element }">
                <value-item
                  :item="element"
                  :class="
                    dirty && !dirty[element.field]
                      ? 'text-red-500 bg-red-100 blink-100 opacity-100 font-bold'
                      : ''
                  "
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>
      </div>
    </div>
  </div>
  <Code2FA
    v-if="get_otp"
    :sending="sending"
    :error="error"
    @submit="save"
    @cancel="get_otp = false"
  />
</template>

<script>
import { STORE } from "@/store";
import { GRID_DEFAULT, EXCLUDED_FIELDS } from "@/default";
import Code2FA from "@/components/Common/Code2FA";

import FieldsConfigModal from "./FieldsConfigModal.vue";

import TgPanel from "@/components/Common/TgPanel.vue";
import TgBtn from "@/components/Common/TgBtn";

import DataItem from "./DataItem.vue";
import GroupItem from "./GroupItem.vue";
import SortItem from "./SortItem.vue";
import FilterItem from "./FilterItem.vue";
import PivotItem from "./PivotItem.vue";
import ValueItem from "./ValueItem.vue";
import _map from "lodash/map";
import _has from "lodash/has";
import _each from "lodash/each";
import _findIndex from "lodash/findIndex";
import { API } from "@/api";

import draggable from "vuedraggable";

export default {
  name: "ConfigData",
  components: {
    FieldsConfigModal,
    TgPanel,
    draggable,
    Code2FA,
    TgBtn,
    DataItem,
    GroupItem,
    SortItem,
    FilterItem,
    PivotItem,
    ValueItem,
  },
  props: {
    slug: String,
  },
  emits: ["on:save"],
  data() {
    return {
      sending: false,
      search_query: "",
      error: null,
      get_otp: false,
      available_fields: [],
      openFieldsModal: false,
      openFieldsConfigModal: false,
      data: {},
      dirty: null,
    };
  },
  computed: {
    available_fields_results() {
      if (this.search_query) {
        let filtered = this.available_fields.filter((item) => {
          return this.search_query
            .toLowerCase()
            .split(" ")
            .every((v) => item.field.toLowerCase().includes(v));
        });
        return filtered;
      } else {
        return this.available_fields;
      }
    },
  },
  async mounted() {
    let resp = await API.page({ slug: this.slug });
    _each(resp.data.data.select, (i) => {
      if (i.field.contains("custom_")) this.dirty[i.field] = true;
    });
    this.data = resp.data.data;
    this.checkDirty();
    this.get_available_fields();
  },
  methods: {
    copy() {
      localStorage.setItem("clipboard", JSON.stringify(this.data.pageconfig));
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Copied!",
      });
    },
    paste() {
      let pageconfig = JSON.parse(localStorage.getItem("clipboard"));
      if (!_has(pageconfig, "select")) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: "Pasted data is not in pageconfig format",
        });
      } else {
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Pasted!",
        });
        this.data.pageconfig = pageconfig;
        this.save();
        localStorage.removeItem("clipboard");
      }
    },
    save(otp) {
      this.sending = true;
      STORE.set(this.slug, this.data, otp)
        .then(() => {
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Page Saved!",
          });
          this.sending = false;
          this.get_otp = false;
          this.$emit("on:save");
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
        });
    },
    add(item, index) {
      this.data.pageconfig.select.push({ ...GRID_DEFAULT, ...item });
      this.available_fields.splice(index, 1);
    },
    addCustomField() {
      let item = {
        field: "custom_" + Math.random().toString(36).substr(2, 7),
        name: "Custom",
      };
      this.data.pageconfig.select.push({ ...GRID_DEFAULT, ...item });
    },
    async checkDirty() {
      this.dirty = {};
      this.loading = true;
      let params = {
        tablename: this.data.data_view,
      };

      if (this.$route.params.client == "dnata") params.dnata = true;

      let resp = await API.get_fields(this.slug, params).catch((e) => {
        console.log(e.message);
        this.loading = false;
      });

      if (resp) {
        this.loading = false;
        _each(resp.data.data, (i) => {
          this.dirty[i.field] = true;
        });
      }
    },
    async get_available_fields() {
      this.available_fields = [];

      this.loading = true;
      let exclude = JSON.parse(JSON.stringify(EXCLUDED_FIELDS));
      _each(this.data.pageconfig.select, (i) => {
        exclude.push(i.field);
      });

      let resp;

      let params = {
        tablename: this.data.data_view,
        orderBy: "field_order_ASC",
        "!col": exclude,
      };

      if (this.$route.params.client == "dnata") params.dnata = true;

      resp = await API.get_fields(this.slug, params).catch((e) => {
        console.log(e.message);
        this.loading = false;
      });

      this.loading = false;

      if (resp) {
        this.available_fields = _map(resp.data.data, (i) => {
          i.field = i.field.toLowerCase();
          return i;
        });
      }
    },
    toggleFieldsConfigModal() {
      this.openFieldsConfigModal = !this.openFieldsConfigModal;
    },
    toggleItemFeature(params) {
      let arr = params.fld;
      let item = params.item;

      if (!_has(this.data.pageconfig, arr)) return;

      if (arr === "pivot_arr") {
        this.data.pageconfig.select = _map(this.data.pageconfig.select, (i) => {
          if (i.field != item.field) i.pivot_arr = false;
          return i;
        });
        this.data.pageconfig[arr] = [params.item];
      } else {
        let index = _findIndex(this.data.pageconfig[arr], {
          field: params.item.field,
        });

        if (index === -1) {
          this.data.pageconfig[arr].push(JSON.parse(JSON.stringify(params.item)));
        } else {
          this.data.pageconfig[arr].splice(index, 1);
        }
      }
    },
    removeItem(params) {
      let arr = params.fld;
      let item = params.item;

      let index = _findIndex(this.data.pageconfig[arr], { field: item.field });

      if (index >= 0) {
        this.data.pageconfig[arr].splice(index, 1);
        if (arr != "select") {
          this.data.pageconfig.select = _map(this.data.pageconfig.select, (i) => {
            if (i.field === item.field) i[arr] = false;
            return i;
          });
        } else {
          this.get_available_fields();
        }
      }
    },
  },
};
</script>

<style></style>
