<template>
  <label :for="item.field" @click="$emit('sticky')" class="relative block text-sm font-medium uppercase text-primary-800">
    <span
      v-if="item.required"
      class="el_1_a737cb807a13f4b9fe0e8e1dc224b8c4 inline mr-1 text-sm text-red-400"
    >*</span>
    {{ item.name }}
    <div class="el_2_a737cb807a13f4b9fe0e8e1dc224b8c4 absolute top-0 right-0">
      <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />
      <RefreshIcon class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer" @click="refresh()" />
    </div>
  </label>
  <div class="el_3_a737cb807a13f4b9fe0e8e1dc224b8c4 flex mt-1 rounded-md shadow-sm">
    <select
      :id="item.field"
      :value="modelValue"
      tabindex="0"
      :readonly="item.sticky"
      :name="item.field"
      :disabled="loading || item.disabled || item.lock_edits"
      class="block w-full py-2 pl-3 pr-10 text-base read-only:bg-yellow-50 border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
      :class="error ? 'border-red-500' : ''"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option v-if="loading">
        Loading...
      </option>
      <option v-if="!loading" />
      <option v-for="i in options" :key="i.value" :value="i.value">
        {{ i.label }}
      </option>
    </select>
  </div>
</template>

<script>
import _map from "lodash/map";
import _get from "lodash/get";
import _each from "lodash/each";
import _has from "lodash/has";
import _isEmpty from "lodash/isEmpty";
import { API } from "@/api";
import { get, set } from "idb-keyval";
import md5 from "blueimp-md5";

import { RefreshIcon, KeyIcon } from "@heroicons/vue/outline";

export default {
  components: {
    RefreshIcon,

    KeyIcon,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    row: Object,
    id: Number,
    modelValue: [String, Number, Boolean],
  },
  emits: { "update:modelValue": null },
  data() {
    return {
      options_all: [],
      options: [],
      loading: true,
      form_row: null,
      show_add_combo_item: false,
      show_help: false,
    };
  },
  watch: {
    row: {
      immediate: true,
      deep: true,
      handler(row) {
        if (this.item.cascade_field && row) {
          this.form_row = row;
          if (this.options_all.length > 0) this.apply_cascade();
        }
      },
    },
  },
  created() {
    if (this.options.length == 0) this.refresh();
  },
  methods: {
    apply_cascade() {
      if (this.item.cascade_field && this.form_row) {
        let fields = this.item.cascade_field.split(",");

        this.options = this.options_all.filter((item) => {
          let found = 0;

          _each(fields, (f) => {
            let val = _get(this.form_row, f, "");

            if (_has(item, f)) {
              if (item[f] == val) found++;
            }
          });

          return found == fields.length;
        });
      }
    },
    async refresh() {
      this.loading = true;

      let params = {};

      if (!_isEmpty(this.item.sql)) {
        params.combo = 1;
      }

      if (this.id > 0) {
        params.edit = 1;
      }

      let cache_key = "combo_" + md5(this.slug + this.item.field);

      // get page config from cache
      let cache_resp = await get(cache_key);

      if (cache_resp) this.process_filter({ data: cache_resp });

      let db_resp = await API.filter(this.slug, this.item.field, params).catch((e) => {
        console.log(e.message);
      });

      if (db_resp) set(cache_key, db_resp.data);

      if (!cache_resp) this.process_filter(db_resp);
    },
    process_filter(resp) {
      if (resp) {
        let options = _map(resp.data.data, (i) => {
          if (_has(i, "value")) return i;
          return { value: i[this.item.field], label: i[this.item.field] };
        });
        this.options_all = options;
        this.options = options;
      }
      this.apply_cascade();

      this.loading = false;
    },
  },
};
</script>
