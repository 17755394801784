<template>
  {{ status }}
</template>

<script>
import { API } from "@/api";
export default {
  data() {
    return {
      status: "OK",
    };
  },
  created() {
    this.check();
  },
  methods: {
    async check() {
      await API.health_checkin(this.$route.params.apikey);
    },
  },
};
</script>

<style></style>
