<template>
  <div v-if="Object.keys(errors).length > 0" class="fixed top-5 left-5">
    <div class="bg-red-600/80 rounded-lg text-white px-5 pb-5 pt-2">
      <div>
        <div class="text-lg py-2 border-b mb-4">
          Form Validation Issues
        </div>
      </div>
      <div v-for="(e_items, fld) in errors" :key="fld">
        <div class="uppercase font-bold">
          {{ fld }}
        </div>
        <ul class="list-decimal pl-5">
          <li v-for="descr in e_items" :key="descr" class="ml-4">
            {{ descr }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <form autocomplete="off" class="relative p-3" @submit.prevent :class="`el_999_${slug}`">
    <input autocomplete="false" name="hidden" type="text" style="display: none">
    <div v-if="sending"
      class="el_1_a29faed9c6953ece95d383bb47210481 absolute inset-0 z-40 text-white bg-white bg-opacity-75 transition-opacity">
      <TgLoader class="mx-auto my-20" />
      <div class="el_2_a29faed9c6953ece95d383bb47210481 p-5 text-lg text-center text-gray-600 bg-white">
        {{ sending_msg }}
      </div>
    </div>

    <div class="el_3_a29faed9c6953ece95d383bb47210481 absolute top-2 right-5 left-5">
      <div class="el_4_a29faed9c6953ece95d383bb47210481 flex items-center justify-end">
        <div v-if="record_partial_locked"
          class="el_5_a29faed9c6953ece95d383bb47210481 flex-1 p-2 text-center text-yellow-500">
          <InformationCircleIcon class="inline-flex mr-1 w-6 h-6" /> This record is live, changes
          restricted.
        </div>
        <div class="flex space-x-2">
          <a v-if="show_validate_rules" href="https://github.com/mikeerickson/validatorjs" class="text-sky-400"
            target="_blank">Rules</a>
          <span v-if="show_validate_rules"
            class="el_7_a29faed9c6953ece95d383bb47210481 bg-green-400 p-1 text-xs text-white rounded-md"
            @click="saveRules">Save Rules</span>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="inline w-6 h-6 text-gray-300 cursor-pointer hover:text-primary-400"
            @click="toggleRules">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
          </svg>
        </div>
        <div class="el_6_a29faed9c6953ece95d383bb47210481 flex-shrink-0 flex space-x-4">

          <!-- <svg xmlns="http://www.w3.org/2000/svg" @click="show_help_descriptions = !show_help_descriptions"
            v-if="$auth.isTpAdmin()" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
          </svg> -->

          <CogIcon v-if="$auth.isTpAdmin()" class="w-6 h-6 text-gray-300 cursor-pointer hover:text-primary-400"
            @click="openConfig" />
          <LockOpenIcon v-if="$auth.isTpAdmin()" class="w-6 h-6 text-gray-300 cursor-pointer hover:text-primary-400"
            @click="unlockForm" />
          <DuplicateIcon v-if="selected_row.id" :class="`el_copy_icon_${slug}`"
            class="w-6 h-6 text-gray-300 cursor-pointer hover:text-primary-400" @click="copy" />
        </div>
      </div>
    </div>

    <div v-if="!ready" class="el_7_a29faed9c6953ece95d383bb47210481 py-20 mt-10 text-center bg-white">
      <TgLoader class="mx-auto" />
      <div class="el_8_a29faed9c6953ece95d383bb47210481 text-2xl">
        Getting record data...
      </div>
    </div>

    <div v-else class="el_9_a29faed9c6953ece95d383bb47210481 grid grid-cols-12 gap-x-2 gap-y-2 p-3 pt-9">

      <div v-for="item in form" v-show="!item.hide" :key="item.field"
        class="el_10_a29faed9c6953ece95d383bb47210481 mb-2" :class="[item.v_col_width, `el_99_${item.field}_${slug}`]">
        <template v-if="show_validate_rules">
          <div v-if="item.formtype === 'grouper'"
            class="el_13_a29faed9c6953ece95d383bb47210481 py-2 mt-4 text-lg font-medium leading-6 text-gray-500 uppercase rounded-t-lg border-b border-gray-300">
            {{ item.name }}
          </div>
          <div v-else class="el_14_a29faed9c6953ece95d383bb47210481">
            <label :for="item.field" class="relative block mt-1 text-sm font-medium text-primary-800">
              <span class="el_15_a29faed9c6953ece95d383bb47210481 uppercase">{{ item.name }}</span>
              -
              <span class="el_15_a29faed9c6953ece95d383bb47210481 text-gray-500 invisible group-hover:visible">{{
                item.field }}</span>
            </label>
            <div class="el_16_a29faed9c6953ece95d383bb47210481 flex mt-1 rounded-md shadow-sm">
              <input v-model="validate_rules[item.field]" type="text"
                class="flex-1 block w-full min-w-0 border-gray-200 rounded-md focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200">
            </div>
          </div>
        </template>
        <template v-else-if="show_help_descriptions">

          <label :for="item.field" @click="get_ai_description(item)"
            class="relative block mt-1 text-sm font-medium text-primary-800">
            <span class="el_15_a29faed9c6953ece95d383bb47210481 uppercase">{{ item.name }}</span>
          </label>
          <div class="el_16_a29faed9c6953ece95d383bb47210481 flex mt-1 rounded-md shadow-sm">
            <input v-model="item.popover" type="text" :disabled="item.working"
              class="flex-1 block w-full min-w-0 border-gray-200 rounded-md focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200">
          </div>

        </template>
        <template v-else>
          <div class="el_11_a29faed9c6953ece95d383bb47210481 relative mr-2">

            <div v-if="item.formtype === 'grouper'"
              class="el_12_a29faed9c6953ece95d383bb47210481 py-2 mt-4 text-lg font-medium leading-6 text-gray-500 uppercase rounded-t-lg border-b border-gray-300">
              {{ item.name }}
            </div>
            <div v-else-if="item.formtype === 'combo'" class="el_13_a29faed9c6953ece95d383bb47210481">
              <label :for="item.field" @click="item.sticky = !item.sticky"
                class="cursor-pointer relative block text-sm font-medium uppercase text-primary-800">
                <span v-if="item.required"
                  class="el_1_ec1d66a1133b7fd5995513da53cb99d2 inline mr-1 text-sm text-red-400">*</span>
                <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
                  @click="item.show_help = !item.show_help" />
                {{ item.name }}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" v-if="item.sticky" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline text-red-500">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>

                <div class="el_2_ec1d66a1133b7fd5995513da53cb99d2 absolute top-0 right-0">

                  <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />

                  <RefreshIcon class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer" @click="get_combo_data(item)" />
                </div>
              </label>
              <div v-if="combos[item.field]">

                <TgSelect :options="combos[item.field]" :readonly="item.sticky" :append="item.sql ? false : true"
                  class="mt-1" css="p-2" :model="selected_row[item.field]" :disabled="item.lock_edits || item.disabled"
                  @select="selected_row[item.field] = $event" />
              </div>
              <div v-else>
                Loading...
              </div>

              <div v-if="item.show_help && item.popover"
                class="el_6_ff2a24a7cdd683630e9dd891c4ed0f08 absolute left-0 z-40 p-2 mt-2 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
                @click="item.show_help = false">
                <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
              </div>

              <!-- <form-combo :id="selected_row.id" v-model="selected_row[item.field]" :slug="slug" :item="item" :row="row" /> -->
            </div>
            <div v-else-if="item.formtype === 'combo_mobile'" class="el_14_a29faed9c6953ece95d383bb47210481">
              <form-combo-mobile :id="selected_row.id" v-model="selected_row[item.field]" :slug="slug" :item="item"
                @sticky="item.sticky = !item.sticky" :row="row" />
            </div>
            <div v-else-if="item.formtype === 'multiselect'">
              <form-multi-select :id="selected_row.id" v-model="selected_row[item.field]" :slug="slug" :item="item"
                @sticky="item.sticky = !item.sticky" :row="row" />
            </div>
            <div v-else-if="item.formtype === 'combo_array'" class="el_15_a29faed9c6953ece95d383bb47210481">
              <form-combo-array v-model="selected_row[item.field]" :item="item" @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'tags'" class="el_15_a29faed9c6953ece95d383bb47210481">
              <form-tags v-model="selected_row[item.field]" :item="item" @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'flightpicker'" class="el_16_a29faed9c6953ece95d383bb47210481">
              <form-flight-picker v-model="selected_row[item.field]" :curr="selected_row[item.field]" :item="item"
                @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'image'" class="el_17_a29faed9c6953ece95d383bb47210481">
              <form-image v-model="selected_row[item.field]"
                :filename="selected_row[data.pageconfig.cloudinary_filename]" :item="item"
                @sticky="item.sticky = !item.sticky" :folder="data.pageconfig.cloudinary" />
            </div>
            <div v-else-if="item.formtype === 'file'" class="el_18_a29faed9c6953ece95d383bb47210481">
              <form-file v-model="selected_row[item.field]" :item="item" :slug="slug"
                @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'filecloudinary'" class="el_18_a29faed9c6953ece95d383bb47210481">
              <FormFileCloudinary v-model="selected_row[item.field]" :item="item" :slug="slug"
                @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'colorpicker'" class="el_18_a29faed9c6953ece95d383bb47210481">
              <FormColorPicker v-model="selected_row[item.field]" :item="item" :slug="slug"
                @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'textarea'" class="el_19_a29faed9c6953ece95d383bb47210481 relative">
              <form-textarea v-model="selected_row[item.field]" :item="item" @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'switch' || item.formtype === 'checkbox'"
              class="el_20_a29faed9c6953ece95d383bb47210481">
              <form-checkbox v-model="selected_row[item.field]" :item="item" @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'checkbox_inline'" class="el_20_a29faed9c6953ece95d383bb47210481">
              <form-checkbox-inline v-model="selected_row[item.field]" :item="item"
                @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'product_finder'">
              <form-product-finder v-model="selected_row[item.field]" :item="item" :tab="tab"
                @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'date'" class="el_21_a29faed9c6953ece95d383bb47210481">
              <form-date v-model="selected_row[item.field]" :item="item" @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype === 'timestamp'" class="el_21_a29faed9c6953ece95d383bb47210481">
              {{ selected_row[item.field] }}
            </div>
            <div v-else-if="item.formtype === 'displayonly'" class="el_22_a29faed9c6953ece95d383bb47210481">
              <form-displayonly v-model="selected_row[item.field]" :item="item" />
            </div>
            <div v-else-if="item.formtype == 'number'" class="el_23_a29faed9c6953ece95d383bb47210481">
              <form-number v-model="selected_row[item.field]" :item="item" @sticky="item.sticky = !item.sticky" />
            </div>
            <div v-else-if="item.formtype == 'camera'" class="el_24_a29faed9c6953ece95d383bb47210481">
              <form-camera v-model="selected_row[item.field]" :item="item" />
            </div>
            <div v-else class="el_25_a29faed9c6953ece95d383bb47210481 relative">
              <form-input v-model="selected_row[item.field]" :slug="slug" :item="item"
                @update:error="errors[item.field] = $event" @sticky="item.sticky = !item.sticky" />
            </div>

            <div v-if="errors[item.field]" class="el_26_a29faed9c6953ece95d383bb47210481 text-sm text-red-400">
              {{ errors[item.field][0] }}
            </div>

          </div>
        </template>
      </div>
    </div>
    <p v-if="error" class="p-3 mt-10 w-full text-red-600 bg-red-100 rounded-lg">
      {{ error }}
    </p>
  </form>

  <TgSlidePanel :open="showConfigPanel" :on-close="closeConfig" side="left" width="max-w-4xl" :overlay="true" z="z-50">
    <template #title>
      Config
    </template>
    <template #subtitle>
      Page configuration builder.
    </template>
    <template #content>
      <grid-config :slug="slug" :page="data" @grid-config-close="closeConfig" />
    </template>
  </TgSlidePanel>

  <Code2FA v-if="get_otp" :sending="sending" :error="error" @submit="submit_to_db" @cancel="get_otp = false" />

  <tg-alert ref="notice" />
</template>

<script>
import { API, MOBILE } from "@/api";
import { getState } from "@/auth";

import { STORE } from "@/store";
import { UTILS } from "@/utils";
import GridConfig from "@/components/Grids/Config/ConfigPanel.vue";
import TgSlidePanel from "@/components/Common/TgSlidePanel";
import TgLoader from "@/components/Common/TgLoader";
import Code2FA from "@/components/Common/Code2FA";

import _map from "lodash/map";
import _mapKeys from "lodash/mapKeys";
import _has from "lodash/has";
import _each from "lodash/each";
import _get from "lodash/get";
import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import _isString from "lodash/isString";
import _findIndex from "lodash/findIndex";
import _filter from "lodash/filter";
import _isNaN from "lodash/isNaN";
import _isArray from "lodash/isArray";

import TgSelect from "@/components/Common/TgSelect.vue";

import FormInput from "@/components/Grids/Form/FormInput.vue";
import FormNumber from "@/components/Grids/Form/FormNumber.vue";
import FormDate from "@/components/Grids/Form/FormDate.vue";
import FormComboArray from "@/components/Grids/Form/FormComboArray.vue";
import FormMultiSelect from "@/components/Grids/Form/FormMultiSelect.vue";
import FormCheckbox from "@/components/Grids/Form/FormCheckbox.vue";
import FormCheckboxInline from "@/components/Grids/Form/FormCheckboxInline.vue";
import FormTextarea from "@/components/Grids/Form/FormTextarea.vue";
import FormImage from "@/components/Grids/Form/FormImage.vue";
import FormFile from "@/components/Grids/Form/FormFile.vue";
import FormCamera from "@/components/Grids/Form/FormCamera.vue";
import FormComboMobile from "@/components/Grids/Form/FormComboMobile.vue";
import FormDisplayonly from "@/components/Grids/Form/FormDisplayonly.vue";
import FormFlightPicker from "@/components/Grids/Form/FormFlightPicker.vue";
import FormFileCloudinary from "@/components/Grids/Form/FormFileCloudinary.vue";
import FormProductFinder from "@/components/Grids/Form/FormProductFinder.vue";
import FormColorPicker from "@/components/Grids/Form/FormColorPicker.vue";
import FormTags from "@/components/Grids/Form/FormTags.vue";

import {
  CogIcon,
  DuplicateIcon,
  LockOpenIcon,
  RefreshIcon,
  KeyIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";

import dayjs from "dayjs";

import Validators from "./Validators";

export default {
  name: "FormPanel",
  components: {
    Code2FA,
    InformationCircleIcon,
    TgLoader,
    DuplicateIcon,
    GridConfig,
    TgSlidePanel,
    CogIcon,
    TgSelect,
    FormInput,
    FormNumber,
    FormDate,
    FormComboArray,
    FormMultiSelect,
    FormComboMobile,
    FormCheckbox,
    FormTextarea,
    FormCheckboxInline,
    FormFileCloudinary,
    FormProductFinder,
    FormColorPicker,
    FormTags,

    RefreshIcon,
    KeyIcon,
    FormImage,
    FormCamera,
    FormDisplayonly,
    FormFlightPicker,
    LockOpenIcon,
    FormFile,
  },
  props: {
    close: Function,
    slug: String,
    selected: Object,
    primarykeys: Array,
    tab: Object,
  },
  emits: ["formsuccess", "formerror", "update:row", 'unauth'],
  data() {
    return {
      get_otp: false,
      showConfigPanel: false,
      sending: false,
      ready: false,
      record_partial_locked: false,
      record_is_locked: false,
      loading: true,
      show_validate_rules: false,
      show_help_descriptions: false,
      validate_rules: {},
      sending_msg: "",
      form: null,
      photos: [],
      errors: {},
      comp_props: {},
      error: null,
      db_page: null,
      combos: {},
      data: null,
      title: "Create",
      selected_row: {},
      row: null,
      tab_slug: 'cfc3950470e7490ab5810947b639fd03',
      emitter: API.get_emitter(),
    };
  },
  watch: {
    selected_row: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.row = newVal;
      },
    },
  },
  async created() {

    if (_has(this.tab, "tab_slug")) {
      this.tab_slug = this.tab.tab_slug;
    }

    let data = STORE.get(this.slug);

    if (!data) {
      let resp = await API.page({
        slug: this.slug,
        _raw: 1,
        _top: 1,
      }).catch((e) => {
        console.log(e.message);
      });

      if (resp) {
        STORE.setOnly(this.slug, resp.data.data);
        this.data = resp.data.data;
        this.afterCreated();
      }
    } else {
      this.data = data;
      this.afterCreated();
    }
  },
  methods: {
    async get_ai_description(item) {
      item.working = true;
      const prompt = `The application is an Airline catering management sytem and I have a page called ${this.tab.name}, can you write a short description in 15 words or less in a professional tone for the following field name: "${item.name}".`;
      console.log('tab', this.tab)
      try {
        const response = await API.openai({
          model: 'gpt-3.5-turbo-0125',
          prompt: prompt,
          output: 'json'
        });

        item.popover = response.data.data.fields[0].description;
      } catch (error) {
        console.error(`Error calling OpenAI API: ${error.message}`);
      } finally {
        item.working = false
      }
    },
    async saveRules() {
      this.db_page.pageconfig.form = _map(this.db_page.pageconfig.form, (i) => {
        i.validator_rule = _get(this.validate_rules, i.field, null);
        return i;
      });

      await API.put_page(this.db_page);

      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Rules Saved!",
      });
      this.show_validate_rules = false;
    },
    async toggleRules() {
      let resp = await API.page({
        slug: this.slug,
        _raw: 1,
        _top: 1,
      });

      this.db_page = resp.data.data;

      this.validate_rules = {};

      _each(this.db_page.pageconfig.form, (i) => {
        this.validate_rules[i.field] = i.validator_rule;
      });

      this.show_validate_rules = !this.show_validate_rules;
    },
    unlockForm() {
      this.form = _map(this.form, (i) => {
        i.lock_edits = false;
        return i;
      });
    },
    closeConfig() {
      this.data = STORE.get(this.slug);
      this.afterCreated();
      this.showConfigPanel = false;
    },
    openConfig() {
      this.showConfigPanel = true;
    },
    copy() {
      this.selected_row.id = null;
      this.selected_row._id = null;
      this.$emit("update:row", this.selected_row);
      this.title = "Create Row";
      this.record_is_locked = false;
      this.record_partial_locked = false;
      this.unlockForm();
    },
    async get_combo_data(col) {
      let params = {};
      if (!_isEmpty(col.sql)) params.combo = 1;
      let resp = await API.filter(this.slug, col.field, params, false);

      this.combos[col.field] = _map(resp.data.data, (i) => {
        let obj = i;
        if (!_has(i, "value")) obj = { value: i[col.field], label: i[col.field] };
        return obj;
      });

      // this.combos[col.field] = resp.data;

    },
    async afterCreated() {

      try {
        this.comp_props = JSON.parse(this.tab.comp_props);
      } catch (e) { }

      this.form = { ...this.data.pageconfig.form };

      let modal_data = STORE.get("modal_row");

      if (_has(this.tab, "tab_drill_config")) {
        modal_data = this.tab.tab_drill_config.modal_row;
      }

      modal_data = { ...modal_data, ...this.$route.query };

      let default_form = {};
      _each(this.form, (i) => {
        if (i.formtype == "combo") {
          this.get_combo_data(i);
        }

        if (i.formtype == "guid") {
          default_form[i.field] = UTILS.guid();
          return;
        }

        let val = _get(i, "default_value", null);

        try {
          val = _get(this.comp_props, `form.defaults.${i.field}`, val);
        } catch (e) { }

        if (val && i.formtype == "date" && val != "9999-12-31")
          val = dayjs().add(val, "days").format("YYYY-MM-DD");

        if (val && i.formtype == "time") val = dayjs().add(val, "minutes").format("HH:mm");

        if (val) {
          try {
            let number = val * 1;
            val = _isNaN(number) ? val : number;
          } catch { }

          if (_isString(val)) {
            val = val.replace("item.", "");
            val = _get(modal_data, val, val);
          }
        }

        if (val == "user.sub" || val == "user_id" || val == "auth0_id")
          val = this.$auth.user.value.sub;

        if (val == "nickname") val = this.$auth.user.value.nickname;

        default_form[i.field] = val;
      });

      if (_has(this.selected, "id")) {
        const _hashid = _get(this.selected, "_hashid", this.selected.id);

        this.title = "Edit Row: #" + _hashid;

        try {
          let resp = await API.form_id(this.slug, _hashid, this.tab_slug)
          let clean = {};

          _each(resp.data, (val, fld) => {
            if (_has(default_form, fld)) clean[fld] = val;
          });

          this.selected_row = { ...default_form, ...clean };

          this.populate();
        } catch (error) {
          if (error.response.status == 401)
            this.$emit("unauth");
        }
      } else {
        this.selected_row = { ...default_form, ...this.selected };
        this.populate();
      }
    },
    populate() {
      let startdate = _get(this.selected_row, "startdate", false);
      let enddate = _get(this.selected_row, "enddate", false);
      let date_lock = false;
      let date_lock_enddate = false;

      let db_row_locking = _get(this.data.pageconfig, "db_row_locking", false);

      if (db_row_locking === false) {
        if (startdate && enddate) {
          let today = dayjs();
          if (today.isAfter(startdate)) date_lock = true;

          if (today.isAfter(enddate)) date_lock_enddate = true;
        }

        if (date_lock) this.record_partial_locked = true;
        if (date_lock_enddate && date_lock) this.record_is_locked = true;
      }

      if (_has(this.selected, "row_lock")) {
        if (this.selected.row_lock == 1) this.record_is_locked = true;
      }
      if (_has(this.selected, "_locked")) {
        if (this.selected._locked == 1) this.record_is_locked = true;
      }
      if (_has(this.selected, "locked")) {
        if (this.selected.locked == 1) this.record_is_locked = true;
      }
      if (_has(this.selected, "row_locked")) {
        if (this.selected.row_locked == 1) this.record_is_locked = true;
      }

      if (this.data.pageconfig.skip_row_locking) {
        this.record_is_locked = false;
        this.record_partial_locked = false;
      }

      this.form = _map(this.form, (i) => {
        i.lock_edits = false;
        i.field = i.field.toLowerCase();

        if (date_lock && _has(this.selected, "id")) {
          i.lock_edits = true;
        }

        if (this.record_is_locked) i.lock_edits = true;

        i.field = i.field.toLowerCase();

        i.validators = _get(i, "validators", "[]");

        if (_isString(i.validators)) i.validators = JSON.parse(i.validators);

        if (!_has(i, "v_col_width")) i.v_col_width = "col-span-12";

        if (i.field == "enddate") i.lock_edits = date_lock_enddate;
        if (i.field == "_archive") i.lock_edits = false;
        if (_has(i, "allow_edit") && i.allow_edit) i.lock_edits = false;

        if (_has(i, "disable_edit") && i.disable_edit && this.selected_row.id > 0) i.disabled = true;

        if (this.data.pageconfig.skip_row_locking) {
          i.lock_edits = false;
        }

        return i;
      });

      this.loading = false;
      this.ready = true;
    },

    format_row(row) {
      _each(this.form, (i) => {

        if (_has(row, i.field)) {
          if (i.formtype == "date") {
            row[i.field] = dayjs(row[i.field]).format("YYYY-MM-DD");
          }
        }
      });

      return row;
    },
    clean_data(row) {
      this.errors = {};
      let invalid = [];

      _each(this.form, (i) => {
        const fld = i.field;

        if (i.formtype == "grouper" || i.formtype == "displayonly") {
          invalid.push(fld);
        }

        i.field = i.field.toLowerCase();

        if (i.field == "id") return;

        let default_value = _get(i, "default_value", null);

        try {
          default_value = _get(this.comp_props.form.defaults, i.field, default_value);
        } catch (e) { }


        if (i.formtype == "timestamp") {
          row[i.field] = dayjs().format("YYYY-MM-DD HH:mm:ss");
        }

        if (
          default_value == "user.sub" ||
          default_value == "user_id" ||
          default_value == "auth0_id"
        )
          row[i.field] = this.$auth.user.value.sub;

        if (i.formtype == "file" && row[i.field]) {
          let client = getState.client;
          let apikey = client.apikey;
          let file_obj = JSON.parse(row[i.field]);
          row.s3_folder = file_obj.folder;
          row.filename = file_obj.name;
          row.size = file_obj.size;
          row.file_link = `https://ap-southeast-2.amazonaws.com/workers.tplive.au/${apikey}/${row.s3_folder}/${row.filename}`;
        }




        if (_has(row, i.field)) {
          let value = row[i.field];



          if (i.formtype == "checkbox") {
            if (!value) {
              row[i.field] = 0;
            } else {
              row[i.field] = 1;
            }
          } else {
            let error_message = false;

            if (i.required) {
              if (_isNull(value)) error_message = "This field is required";

              if (_isString(value) && _isEmpty(value)) error_message = "This field is required";
            } else {
              if (_isNull(value)) {
                delete row[i.field];
              }
            }

            if (error_message === false) error_message = Validators.validate(value, i);

            if (!_isEmpty(error_message)) this.errors[i.field] = { error: error_message };
          }

          if (i.formtype == "camera" && value) {
            this.photos.push(value);
            // delete row[i.field];
          }
        } else {
          // user failed to submit
          if (i.formtype != "checkbox") {
            if (i.required) {
              this.errors[i.field] = { error: "This field is required" };
            }
          }
        }
      });
      console.log("invalid", invalid);

      // lower case all keys in object  
      row = _mapKeys(row, (v, k) => {
        return k.toLowerCase();
      });

      _each(invalid, (i) => {
        delete row[i];
      });
      console.log("row", row);
      return row;
    },

    get_raw_payload() {
      return this.clean_data(this.selected_row);
    },

    get_payload() {
      // if (this.record_is_locked) {
      //   this.$refs.notice.openModal({
      //     title: "Notice",
      //     content: "This record has been locked from any updates.",
      //   });
      //   this.$emit("formerror");
      //   return;
      // }

      let payload = this.clean_data(this.selected_row);

      if (!_isEmpty(this.errors)) {
        this.$emit("formerror");
        this.sending = false;
        return;
      }

      this.$emit("formsuccess", payload);
    },

    async upload_photo(file, folder) {
      return new Promise((resolve, reject) => {
        const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/tplive/upload";
        const CLOUDINARY_UPLOAD_PRESET = "vue_wvky6qwr";

        let client = getState.client;

        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        formData.append("folder", client.key + "/" + folder);
        formData.append("file", file);
        // formData.append("public_id", this.filename);

        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }

          // Not succesfull
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            alert("error, status code not 200 " + error);
            reject(error);
          }
        };

        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };

        request.send(formData);
      });
    },

    submit() {
      if (this.data.pageconfig.require_2fa) {
        this.get_otp = true;
      } else {
        this.submit_to_db();
      }
    },
    copy_obj(obj) {
      var res = {};
      for (var p in obj) res[p] = obj[p];
      return res;
    },
    cartesianProduct(input, current) {
      if (!input || !input.length) {
        return [];
      }

      var head = input[0];
      var tail = input.slice(1);
      var output = [];

      for (var key in head) {
        for (var i = 0; i < head[key].length; i++) {
          var newCurrent = this.copy_obj(current);
          newCurrent[key] = head[key][i];
          if (tail.length) {
            var productOfTail = this.cartesianProduct(tail, newCurrent);
            output = output.concat(productOfTail);
          } else output.push(newCurrent);
        }
      }
      return output;
    },
    check_for_multi(form) {
      let has_multi = false;
      _each(form, (val, fld) => {
        if (_isArray(val)) has_multi = true;
      });
      return has_multi;
    },
    async submit_to_db(code_2fa) {
      this.error = null;
      this.errors = {};



      let payload = this.clean_data(this.selected_row);


      if (!_isEmpty(this.errors)) {
        this.$emit("formerror");
        this.sending = false;
        return;
      }

      // -------

      if (
        this.primarykeys &&
        _has(this.selected_row, "startdate") &&
        _has(this.selected_row, "enddate")
      ) {
        if (dayjs(this.selected_row.startdate).isAfter(dayjs(this.selected_row.enddate))) {
          this.error = `The end date is before the start date (${this.selected_row.startdate})`;
          this.$emit("formerror");
          this.sending = false;
          return;
        }

        if (dayjs(this.selected_row.startdate).isAfter(dayjs(this.selected_row.enddate))) {
          this.error = `The start date is after the end date (${this.selected_row.enddate})`;
          this.submitting = false;
          return;
        }

        let params = {};

        if (this.primarykeys.length > 0) {
          _each(this.primarykeys, (i) => {
            if (_has(this.selected_row, i)) params[i] = this.selected_row[i];
          });

          if (_has(this.selected_row, "id") && !!this.selected_row.id)
            params["id"] = `<>${this.selected_row.id}`;

          params.enddate = `>=${this.selected_row.startdate}`;
          params.startdate = `<=${this.selected_row.enddate}`;

          let overlaps = await API.get(this.slug, params).catch((e) => {
            return;
          });

          if (overlaps.data.data.length > 0) {
            this.$notify({
              group: "toast",
              type: "danger",
              title: "Error",
              text: "Date overlap found.",
            });
            this.error = "Overlapping date found. Please check.";
            this.$emit("formerror");
            this.sending = false;
            return;
          }
        }
      }

      // -------

      // this.sending = true;

      try {
        // check if offline first page
        if (this.data.pageconfig.offline_forms) {
          if (!_has(this.selected, "_id")) {
            this.selected_row._id = UTILS.guid();
          } else {
            this.selected_row._id = this.selected._id;
          }
          if (_has(this.selected_row, "id")) delete this.selected_row.id;
          MOBILE.form_submit(this.slug, this.selected_row);
          this.$emit("formsuccess", {});
          this.sending = false;
        } else {
          if (this.photos.length > 0) {
            this.sending_msg = "Uploading photo(s)...";
            let jobs = [];
            _each(this.photos, (p) => {
              jobs.push(this.upload_photo(p, "photos"));
            });
            let photos = await Promise.all(jobs);

            payload.images = JSON.stringify(photos[0]);
          }

          this.sending_msg = "Submitting form data...";

          if (this.selected_row.id) {
            let id = _get(this.selected, "_hashid", this.selected.id);
            API.update(this.slug, id, payload, `otp=${code_2fa}`, this.tab_slug)
              .then((resp) => {
                let payload;
                if (resp === "queued") {
                  payload = {
                    insert: false,
                    queued: true,
                    row: null,
                  };
                } else {
                  payload = {
                    insert: false,
                    row: this.format_row(resp.data.data),
                  };
                }
                this.$emit("formsuccess", payload);
                this.sending = false;
              })
              .catch((err) => {
                if (err.response.status == 401) {
                  this.$emit("unauth");
                  return;
                }
                this.$emit("formerror");
                this.$refs.notice.openModal({
                  title: "Form Error",
                  content: _get(err.response.data, 'message', "The server reported an error whilst attempting to update this record."),
                });
                this.sending = false;
              });
          } else {
            let has_multi = this.check_for_multi(payload);

            if (has_multi === true) {
              let arr = [];

              _each(payload, (v, f) => {
                if (f != "id") {
                  let obj = {};
                  v = JSON.parse(JSON.stringify(v));
                  if (!_isArray(v)) v = [v];
                  obj[f] = v;
                  arr.push(obj);
                }
              });

              let rows = this.cartesianProduct(arr);

              try {
                await API.bulk_insert(this.slug, rows);
                payload = {
                  insert: true,
                };
                this.$emit("formsuccess", payload);
                this.sending = false;
              } catch (err) {
                this.$emit("formerror");
                if (_has(err.response, "data.message.validation")) {
                  this.errors = err.response.data.message.validation;
                } else {
                  this.$refs.notice.openModal({
                    title: "Form Error",
                    content:
                      "The server reported an error whilst attempting to insert this record.",
                  });
                }

                this.sending = false;
              }
            } else {
              try {
                let resp = await API.post(this.slug, payload, this.tab_slug);

                let result;
                if (resp === "queued") {
                  result = {
                    insert: false,
                    queued: true,
                    row: null,
                  };
                } else {
                  result = {
                    insert: true,
                    row: this.format_row(resp.data.data),
                  };
                }
                this.$emit("formsuccess", result);

                console.log("form", this.form)

                // clear the form
                _each(this.form, f => {
                  if (!f.sticky) {
                    this.selected_row[f.field] = null;
                  }
                })


                this.sending = false;
              } catch (err) {
                if (err.response.status == 401) {
                  this.$emit("unauth");
                  return;
                }
                this.$emit("formerror");
                if (_has(err.response, "data.message.validation")) {
                  this.errors = err.response.data.message.validation;
                } else {
                  this.$refs.notice.openModal({
                    title: "Form Error",
                    content:
                      "The server reported an error whilst attempting to insert this record.",
                  });
                }

                this.sending = false;
              }
            }
          }
        }
      } catch (e) {
        this.sending = false;
      }
    },
  },
};
</script>

<style></style>
