<template>
  <div class="el_1_064d93b2d8de85a1feb7b951d2425eea relative">
    <label :for="item.field" @click="$emit('sticky')" class="relative cursor-pointer block text-sm font-medium uppercase text-primary-800">
      <span v-if="item.required" class="el_2_064d93b2d8de85a1feb7b951d2425eea inline mr-1 text-sm text-red-400">*</span>
      <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
        @click="show_help = !show_help" />
      {{ item.name }}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" v-if="item.sticky" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline text-red-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

    </label>
    <div class="el_4_064d93b2d8de85a1feb7b951d2425eea flex rounded-md shadow-sm">
      <input :value="modelValue" readonly type="text" tabindex="0"
        :disabled="loading || item.disabled || item.lock_edits" :class="[error ? 'border-red-500' : '',item.sticky ? 'bg-yellow-50' : '']"
        class="flex-1 block w-full min-w-0  border-r-0 border-gray-200 rounded-l-md focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
        @blur="open = false">
      <button :disabled="loading || item.disabled || item.lock_edits" tabindex="-1"
        class="inline-flex items-center px-3 font-bold text-gray-500 bg-gray-200 border border-l-0 border-gray-200 rounded-r-md"
        @click="openCal">
        <CalendarIcon class="w-5 h-5 cursor-pointer" />
      </button>
    </div>
    <div v-if="show_help && item.popover"
      class="el_5_064d93b2d8de85a1feb7b951d2425eea absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
      @click="show_help = false">
      <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
    </div>

    <div v-if="open && !item.sticky"  class="el_6_064d93b2d8de85a1feb7b951d2425eea relative w-68">
      <TgDatePicker :min="min" :max="max" :dt="modelValue" @on:update="datePicked" />
    </div>
  </div>
</template>

<script>
import TgDatePicker from "@/components/Common/TgDatePicker.vue";
import dayjs from "dayjs";
import { CalendarIcon, InformationCircleIcon } from "@heroicons/vue/outline";
export default {
  components: {
    CalendarIcon,
    InformationCircleIcon,
    TgDatePicker,
  },
  props: {
    item: Object,
    error: String,
    modelValue: {
      type: [Date, String],
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      show_help: false,
      open: false,
      loading: false,
      min: null,
      max: null,
      val: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: false,
      handler(newVal) {
        this.val = newVal;
      },
    },
  },
  created() {
    if (this.item.dt_min) this.min = dayjs().add(this.item.dt_min);

    if (this.item.dt_max) this.max = dayjs().add(this.item.dt_max);
  },
  methods: {
    datePicked(dt) {
      this.open = false;
      console.log("dt = ", dt);
      this.$emit("update:modelValue", dt);
    },
    openCal() {
      if (this.item.disabled || this.item.lock_edits) return;
      this.open = !this.open;
    },
  },
};
</script>

<style></style>
