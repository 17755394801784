<template>
  <label :for="item.field" @click="$emit('sticky')" class="relative cursor-pointer block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_1526ac473d548279751dbf3bb9e6f0df inline mr-1 text-sm text-red-400">*</span>
    <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
      @click="show_help = !show_help" />

    {{ item.name }}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" v-if="item.sticky" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline text-red-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

    <div class="el_2_1526ac473d548279751dbf3bb9e6f0df absolute top-0 right-0">
      <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />
    </div>
  </label>
  <SwitchGroup as="div" class="flex items-center mt-4" @click="toggle()">
    <Switch  :value="modelValue" :disabled="item.loading || item.disabled || item.lock_edits || item.sticky"
      :class="[modelValue ? 'bg-primary-400' : 'bg-gray-200  ', ' disabled:opacity-50 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none  ']">
      <span class="el_3_1526ac473d548279751dbf3bb9e6f0df" aria-hidden="true"
        :class="[modelValue ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
    </Switch>
  </SwitchGroup>
  <div v-if="show_help && item.popover"
    class="el_4_1526ac473d548279751dbf3bb9e6f0df absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
    @click="show_help = false">
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import { InformationCircleIcon, KeyIcon } from "@heroicons/vue/outline";
import { Switch, SwitchGroup } from "@headlessui/vue";

export default {
  components: {
    Switch,
    SwitchGroup,
    InformationCircleIcon,
    KeyIcon,
  },
  props: {
    item: Object,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null },
  data() {
    return {
      show_help: false,
    };
  },
  methods: {
    toggle() {
      if (this.item.disabled) return;

      this.$emit("update:modelValue", !this.modelValue);
    },
  },
};
</script>