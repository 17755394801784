<template>
  <div class="el_1_583829268557c491036fdfb47007b472">
    <div class="el_2_583829268557c491036fdfb47007b472 absolute top-5 right-6">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>
    </div>

    <!-- <div class="flex space-x-2 pt-5">
      <div v-for="t in tabs" :key="t.id" class="flex-1 p-2 rounded-t-md cursor-pointer"
        :class="current_tab == t.id ? 'bg-primary-500 text-white' : 'bg-gray-200'" @click="current_tab = t.id">
        {{ t.name }}
      </div>
    </div> -->

    <div v-if="current_tab == 2" class="">
      here
    </div>
    <div v-if="current_tab == 1" class="flex flex-col space-y-5 p-3">
      <!-- <input v-model="model" class="block w-full border border-gray-300 rounded-md shadow-sm">
      <textarea v-model="data.description" rows="15" class="block w-full border border-gray-300 rounded-md shadow-sm" />
      <TgOpenAi :content="data.description" @accept="data.description = $event" /> -->

      <div v-if="data.pageconfig" class="flex space-x-3 justify-between">
        <div class="flex-1 flex flex-col space-y-1 border rounded-md p-2 border-gray-300">
          <div class="text-center font-bold bg-gray-200 p-1">GRID</div>
          <div v-for="i in data.pageconfig.select" :key="i.field" class="flex-1 flex space-x-1">
            <div class="font-bold flex-1" @click="get_ai_description(i)">
              {{ i.name }}
            </div>
            <textarea v-model="i.popover" rows="4" type="text" :disabled="i.working"
              class="block flex-1 p-0 border border-gray-300 disabled:bg-gray-200 rounded-md shadow-sm"></textarea>
          </div>
          <button
            class="bg-primary-500 hover:bg-primary-700  text-white font-bold py-2 px-4 rounded disabled:bg-gray-400 disabled:cursor-wait"
            :disabled="working" @click="ask(data.pageconfig.select)">
            {{ working ? 'Working...' : 'Ask OpenAI' }}
          </button>
        </div>
        <div class="flex-1 flex flex-col space-y-1 border rounded-md p-2 border-gray-300">
          <div class="text-center font-bold bg-gray-200 p-1">FORM</div>
          <div v-for="i in data.pageconfig.form" :key="i.field" class="flex-1 flex space-x-1">
            <div class="font-bold flex-1" @click="get_ai_description(i)">
              {{ i.name }}
            </div>
            <textarea v-model="i.popover" rows="4" type="text" :disabled="i.working"
              class="block flex-1 p-0 border border-gray-300 disabled:bg-gray-200 rounded-md shadow-sm"></textarea>
          </div>
          <button
            class="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-400 disabled:cursor-wait"
            :disabled="working" @click="ask(data.pageconfig.form)">
            {{ working ? 'Working...' : 'Ask OpenAI' }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <Code2FA v-if="get_otp" :sending="sending" :error="error" @submit="save" @cancel="get_otp = false" />
</template>

<script>
import { STORE } from "@/store";
import { API } from "@/api";
import TgBtn from "@/components/Common/TgBtn";
import Code2FA from "@/components/Common/Code2FA";
import TgMarkdown from "@/components/Common/TgMarkdown";
import _each from "lodash/each";
import _has from "lodash/has";

import TgOpenAi from "@/components/Common/TgOpenAi";

export default {
  name: "ConfigSettings",
  components: {
    TgBtn,
    Code2FA,
    // TgOpenAi,
    // TgMarkdown,
  },
  props: {
    slug: String,
    tab: Object
  },
  emits: ["on:save"],
  data() {
    return {
      tabs: [
        { name: "AI", id: 1 },
        { name: "Markdown", id: 2 },
      ],
      current_tab: 1,
      working: false,
      sending: false,
      data: {},
      prompt: null,
      error: null,
      model: 'gpt-3.5-turbo-0125',
      response: null,
      get_otp: false,
    };
  },

  async mounted() {
    let resp = await API.page({ slug: this.slug });
    let data = resp.data.data;
    if (!_has(data, "markdown")) {
      data.markdown = "";
    }
    this.data = resp.data.data;
  },
  methods: {
    async get_ai_description(item) {
      item.working = true;
      const prompt = `The application is an Airline catering management sytem and I have a page called ${this.tab.name}, can you write a short description in 15 words or less in a professional tone for the following field name: "${item.name}".`;
      console.log('tab', this.tab)
      try {
        const response = await API.openai({
          model: 'gpt-3.5-turbo-0125',
          prompt: prompt,
          output: 'json'
        });

        item.popover = response.data.data.fields[0].description;
      } catch (error) {
        console.error(`Error calling OpenAI API: ${error.message}`);
      } finally {
        item.working = false
      }
    },
    async ask(arr) {
      this.working = true
      let json = [];

      _each(arr, i => {
        i.working = true;
        json.push({ field: i.field, name: i.name });
      })


      json = JSON.stringify(json)

      // this.prompt = `Here is the current description for the page "${this.data.title}": "${this.data.description}". Can you please rewrite this description in a more detailed manner using a professional tone and return in markdown format?  To help you, here is a sample of fields: "${json}". Can you provide short descriptions for each field.`;
      // this.prompt = `This is the description for the page "${this.data.description}". Can you write a short description in a professional tone for each of the following fields: "${json}".`;
      this.prompt = `The application is an Airline catering management sytem and I have a page called ${this.tab.name}, can you write a short description in 15 words or less in a professional tone for each of the following fields: "${json}".`;

      try {
        const response = await API.openai({
          model: this.model,
          prompt: this.prompt,
          output: 'json'
        });
        this.response = _each(response.data.data.fields, i => {
          // find the index of the field in seelct
          let index = arr.findIndex(x => x.field === i.field);
          if (index > -1) {
            arr[index].working = false;
            arr[index].popover = i.description;
          }

          // index = this.data.pageconfig.form.findIndex(x => x.field === i.field);
          // if (index > -1) {
          //   this.data.pageconfig.form[index].popover = i.description;
          // }
        })
      } catch (error) {
        console.error(`Error calling OpenAI API: ${error.message}`);
      } finally {
        this.working = false
      }
    },
    async asdddk() {
      this.working = true
      let json = [];

      _each(this.data.pageconfig.select, i => {
        json.push({ field: i.field, name: i.name, type: i.type });
      })

      json = JSON.stringify(json)

      this.prompt = `Here is the current description for the page "${this.data.title}": "${this.data.description}". Can you please rewrite this description in a more detailed manner using a professional tone and return in markdown format?  To help you, here is a sample of fields: "${json}". Can you provide short descriptions for each field.`;
      this.prompt = `Can you write a short description in a professional tone for each of the following fields: "${json}".`;

      try {
        const response = await API.openai({
          model: this.model,
          prompt: this.prompt,
          output: 'json'
        });
        this.response = response.data.data
      } catch (error) {
        console.error(`Error calling OpenAI API: ${error.message}`);
      } finally {
        this.working = false
      }
    },
    save(otp) {
      this.sending = true;
      STORE.set(this.slug, this.data, otp)
        .then(() => {
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Page Saved!",
          });
          this.sending = false;
          this.get_otp = false;
          this.$emit("on:save");
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.grow-wrap>textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}

.grow-wrap>textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
</style>