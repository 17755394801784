import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import _mapValues from "lodash/mapValues";
import _isEmpty from "lodash/isEmpty";
import _isString from "lodash/isString";
import _each from "lodash/each";
import { getState } from "@/auth";
import { _ } from "core-js";

export const UTILS = {
  groupBy(seq, keys) {
    if (!keys.length) return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _mapValues(_groupBy(seq, first), (value) => {
      return this.groupBy(value, rest);
    });
  },

  isMoney(value) {
    if (value == 0) return true;

    return /^-?\d+(\.\d{2})?$/.test(value);
  },

  // force the number to be a float with "x" decimal places
  forceFloat(value, x) {
    return parseFloat(value).toFixed(x);
  },

  remove_dups(arr) {
    return arr.filter(
      (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
    );
  },

  _clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },

  str_rand(minLength = 0, acc = "") {
    if (acc.length <= minLength) {
      const str = Math.random().toString(36).slice(2);
      return this.str_rand(minLength, acc.concat(str));
    }

    return acc.slice(0, minLength);
  },

  parseJSONToCSVStr(jsonData) {
    if (jsonData.length == 0) {
      return "";
    }

    let keys = Object.keys(jsonData[0]);

    let columnDelimiter = ",";
    let lineDelimiter = "\n";

    let csvColumnHeader = keys.join(columnDelimiter);
    let csvStr = csvColumnHeader + lineDelimiter;

    jsonData.forEach((item) => {
      keys.forEach((key, index) => {
        if (index > 0 && index < keys.length - 1) {
          csvStr += columnDelimiter;
        }
        csvStr += item[key];
      });
      csvStr += lineDelimiter;
    });

    return encodeURIComponent(csvStr);
  },

  async csv_file_2_json(event) {
    const file = event.target.files.item(0);
    const raw = await file.text();
    let lines = raw.split(/(?:\r\n|\n)+/).filter(function (el) {
      return el.length != 0;
    });
    let headers = lines.splice(0, 1)[0].split(",");
    let valuesRegExp = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\",]+)/g;

    let elements = [];
    let matches;

    for (let i = 0; i < lines.length; i++) {
      let element = {};
      let j = 0;

      while ((matches = valuesRegExp.exec(lines[i]))) {
        var value = matches[1] || matches[2];
        value = value.replace(/\"\"/g, '"');

        element[headers[j]] = value;
        j++;
      }

      elements.push(element);
    }
    return elements;
  },

  remove_blanks(obj) {
    let clean = {};
    _each(obj, (val, fld) => {
      console.log(val, fld);
      if (_isString(val)) {
        if (!_isEmpty(val)) clean[fld] = val;
      } else {
        clean[fld] = val;
      }
    });
    return clean;
  },

  template_render(item, value) {
    let user = getState.user;

    if (typeof value === "string" || value instanceof String) {
      value = value.replace("user_id", user.sub);
      value = value.replace("user.email", user.email);

      _each(item, (v, f) => {
        value = value.replace(`item.${f}`, v);
      });
    }

    if (value) {
      return value.toString();
    } else {
      return "";
    }
  },
  //var csv is the CSV file with headers
  csvJSON(csv) {

    var lines = csv.split("\n");

    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step 
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {

      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);

    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  },
  is_mobile: () => {
    // return this.$isMobile();
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    let is_mobile = navigator.userAgent.toLowerCase().match(/mobile/i);
    if (is_mobile) return true;
    return false;
  },
  is_dnata: () => {
    let client = getState.client;

    if (client.key == "dnata") {
      if (window.location.href.toLowerCase().match(/mobile/i)) return true;
    }
    return false;
  },
  primary: {
    100: "#EEF9FC",
    200: "#DCF2F9",
    300: "#74CCE7",
    400: "#3eb9dd",
    500: "#2FB2DA",
    600: "#23a0c6",
    700: "#229ABF",
    800: "#1f8cad",
    900: "#16667c",
  },
  rand_string: (len) => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var password = "";
    for (var i = 0; i <= len; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  },
  guid: (prefix) => {
    var guid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    guid = guid.toUpperCase();

    if (prefix) {
      return `${prefix}_${guid}`;
    }

    return guid
  },
  color_to_hex(clr) {
    return _get(this.primary, clr, clr);
  },
};
