<template>
  <div id="date_picker" class="el_1_c66ee4ac25ccad9f518116a65bea5288 z-50 mt-2 w-full max-w-sm bg-white rounded-md shadow-lg h-68" :class="inline ? 'mx-auto' : 'absolute left-0 top-18'">
    <div class="el_2_c66ee4ac25ccad9f518116a65bea5288 flex items-center py-1 mb-1 text-white bg-primary-400">
      <div class="el_3_c66ee4ac25ccad9f518116a65bea5288 flex-none pl-2 w-8">
        <ChevronDoubleLeftIcon class="w-5 h-5 text-white cursor-pointer" @click="prev" />
      </div>
      <div class="el_4_c66ee4ac25ccad9f518116a65bea5288 flex-grow font-bold text-center uppercase">
        {{ month_name }} {{ year }}
      </div>
      <div class="el_5_c66ee4ac25ccad9f518116a65bea5288 flex-none w-8">
        <ChevronDoubleRightIcon class="w-5 h-5 text-white cursor-pointer" @click="next" />
      </div>
    </div>
    <div class="el_6_c66ee4ac25ccad9f518116a65bea5288 grid grid-cols-7 gap-2 items-center px-3 py-1">
      <div v-for="n in ['M','T','W','T','F','S','S']" :key="n" class="el_7_c66ee4ac25ccad9f518116a65bea5288 col-span-1 p-1 text-right text-primary-700">
        {{ n }}
      </div>
      <div v-for="n in days" :key="n.day" class="el_8_c66ee4ac25ccad9f518116a65bea5288 col-span-1 text-right">
        <span v-if="n.blank" class="el_9_c66ee4ac25ccad9f518116a65bea5288">&nbsp;</span>
        <span v-else-if="n.valid" class="el_10_c66ee4ac25ccad9f518116a65bea5288 p-1 rounded-md cursor-pointer hover:bg-primary-400 hover:text-white" :class="[(val == n.date ? 'bg-primary-400 text-white' : ''), n.css]" @click="selected(n)">{{ n.day }}</span>
        <span v-else class="el_11_c66ee4ac25ccad9f518116a65bea5288 p-1 rounded-md opacity-25 cursor-not-allowed" :class="n.css">{{ n.day }}</span>
      </div>
    </div>
    <div class="el_12_c66ee4ac25ccad9f518116a65bea5288 flex items-center px-3 py-2">
      <div class="el_13_c66ee4ac25ccad9f518116a65bea5288 flex-1 text-left">
        <a class="el_14_c66ee4ac25ccad9f518116a65bea5288 underline cursor-pointer text-primary-400" @click="today">Today</a>
      </div>
      <div class="el_15_c66ee4ac25ccad9f518116a65bea5288 flex-1 text-right">
        <a class="el_16_c66ee4ac25ccad9f518116a65bea5288 underline cursor-pointer text-primary-400" @click="forever">Forever</a>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "@/api";
import dayjs from "dayjs";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/vue/outline";
export default {
  components: {
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
  },
  props: {
    dt: String,
    min: String,
    max: String,
    inline: Boolean,
  },
  emits: { "on:update": null },
  data() {
    return {
      show_help: false,
      open: false,
      year: null,
      month: null,
      day: null,
      month_name: null,
      current: dayjs(),
      emitter: null,
      val: this.dt,
      first_day_num_of_month: null,
      days: [],
      days_in_month: null,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: false,
      handler(newVal) {
        this.val = newVal;
      },
    },
  },
  created() {
    this.emitter = API.get_emitter();
    this.onMonthChange();
  },
  methods: {
    today() {
      let valid = true;
      let today = dayjs().format("YYYY-MM-DD");

      if (this.min) {
        if (dayjs(today).isBefore(this.min)) valid = false;
      }
      if (this.max) {
        if (dayjs(today).isAfter(this.max)) valid = false;
      }

      if (valid) {
        this.val = "9999-12-31";
        this.val = dayjs().format("YYYY-MM-DD");
        this.current = dayjs();
        this.onMonthChange();
        this.$emit("on:update", this.val);
      }
    },
    forever() {
      let valid = true;

      if (this.min) {
        if (dayjs("9999-12-31").isBefore(this.min)) valid = false;
      }
      if (this.max) {
        if (dayjs("9999-12-31").isAfter(this.max)) valid = false;
      }

      if (valid) {
        this.val = "9999-12-31";

        this.$emit("on:update", this.val);
      }
    },
    selected(day) {
      console.log(day);
      this.val = day.date;
      this.$emit("on:update", day.date);
    },
    buildDays() {
      this.days = [];
      let n;
      let today = dayjs().format("YYYY-MM-DD");

      if (this.first_day_num_of_month == 1) {
        n = 1;
      } else {
        n = 1 - this.first_day_num_of_month + 1;
      }

      if (n == 2) n = -5;

      while (n <= this.days_in_month) {
        let day;
        let dt = this.year + "-" + this.month + "-" + n;
        let day_dt = dayjs(dt);

        if (n <= 0) {
          day = {
            blank: true,
          };
        } else {
          day = {
            blank: false,
            day: n,
            date: day_dt.format("YYYY-MM-DD"),
          };
        }

        let valid = true;

        if (this.min) {
          if (day_dt.isBefore(this.min)) valid = false;
        }
        if (this.max) {
          if (day_dt.isAfter(this.max)) valid = false;
        }

        day.valid = valid;

        day.css = "";
        if (day.date == today) day.css = "border border-primary-300";

        this.days.push(day);
        n++;
      }
    },
    prev() {
      this.current = this.current.subtract(1, "month");
      this.onMonthChange();
    },
    next() {
      this.current = this.current.add(1, "month");
      this.onMonthChange();
    },
    onMonthChange() {
      this.year = this.current.format("YYYY");
      this.month = this.current.format("MM");
      this.day = this.current.format("DD");
      this.month_name = this.current.format("MMM");
      this.first_day_num_of_month = this.current.startOf("month").day();
      this.days_in_month = this.current.daysInMonth();
      this.buildDays();
    },
  },
};
</script>
