<template>
  <label :for="item.field" class="block relative text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_3388cc28607f403bff50891a2104e027 inline mr-1 text-sm text-red-400">*</span> {{ item.name }}
  </label>
  <div class="el_2_3388cc28607f403bff50891a2104e027 flex items-center p-2 mt-1 bg-white rounded-md shadow-sm">
    <div class="el_3_3388cc28607f403bff50891a2104e027 flex-1">
      <img class="mx-auto" :src="comp">
      <div class="el_4_3388cc28607f403bff50891a2104e027 p-2 text-sm text-center text-red-400 cursor-pointer" @click="del">
        Remove
      </div>
    </div>
    <div xv-if="filename" class="el_5_3388cc28607f403bff50891a2104e027 flex-1">
      <TgCloudinaryUploader :folder="folder" :filename="filename" @upload:success="upload_success" />
    </div>
  </div>
</template>

<script>
import TgCloudinaryUploader from "@/components/Common/TgCloudinaryUploader.vue";
import _head from "lodash/head";
export default {
  components: {
    TgCloudinaryUploader,
  },
  props: {
    item: Object,
    filename: String,
    folder: String,
    slug: String,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
      no_image: false,
    };
  },

  computed: {
    comp() {
      return `//res.cloudinary.com/tplive/image/upload/c_scale,q_auto:low,h_150/${this.modelValue}`;
    },
  },
  methods: {
    del() {
      this.$emit("update:modelValue", "");
    },
    upload_success(image) {
      image = _head(image);
      let url = image.secure_url.replace(
        "https://res.cloudinary.com/tplive/image/upload/",
        ""
      );
      this.no_image = null;
      this.$emit("update:modelValue", url);
    },
    onImgError() {
      this.no_image =
        "//res.cloudinary.com/tplive/image/upload/c_scale,q_auto:low,h_150/v1600668443/touchpoint/NO_IMG_600x600";
    },
  },
};
</script>