<template>
  <div class="el_1_0200f624e87a9c63ebaa11bd942b0057 flex justify-between items-center p-1 mb-1 rounded-lg border border-gray-200 hover:bg-primary-100">
    <div class="el_2_0200f624e87a9c63ebaa11bd942b0057 flex-shrink-0">
      <div class="el_3_0200f624e87a9c63ebaa11bd942b0057 flex items-center">
        <MenuIcon class="mr-1 w-4 h-4 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        <LockClosedIcon :class="data.lock ? 'text-red-400' : 'text-gray-300'" class="mr-2 w-4 h-4 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="toggle('lock')" />
        <AdjustmentsIcon :class="data.quick_filter ? 'text-green-400' : 'text-gray-300'" class="mr-2 w-4 h-4 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="toggle('quick_filter')" />
        <DeviceTabletIcon :class="data.button_filter ? 'text-green-400' : 'text-gray-300'" class="mr-2 w-4 h-4 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="toggle('button_filter')" />
        <EyeIcon :class="!data.hide ? 'text-green-400' : 'text-gray-300'" class="mr-2 w-4 h-4 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="toggle('hide')" />
      </div>
    </div>
    <div class="el_4_0200f624e87a9c63ebaa11bd942b0057 flex-1">
      {{ data.name }}
    </div>
    <div class="el_5_0200f624e87a9c63ebaa11bd942b0057 flex-shrink-0">
      <tg-badge color="danger" @click="remove()">
        X
      </tg-badge>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { UTILS } from "@/utils";
import {
  MenuIcon,
  LockClosedIcon,
  AdjustmentsIcon,
  DeviceTabletIcon,
  EyeIcon,
} from "@heroicons/vue/solid";

export default {
  name: "FilterItem",
  components: {
    TgBadge,
    LockClosedIcon,
    EyeIcon,
    MenuIcon,
    AdjustmentsIcon,
    DeviceTabletIcon,
  },
  props: {
    item: Object,
  },
  emits: ["remove-item", "toggle-item-feature"],
  data() {
    return {
      data: this.item,
      is_mobile: UTILS.is_mobile(),
    };
  },
  methods: {
    toggle(fld) {
      this.data[fld] = !this.data[fld];
      this.$emit("toggle-item-feature", { fld: fld, item: this.data });
    },
    remove() {
      this.$emit("remove-item", { fld: "filters", item: this.data });
    },
  },
};
</script>

<style>
</style>