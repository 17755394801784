<template>
  <div class="el_1_550294a68020feb29926f9e0658f2836 fixed top-2 right-2 z-30">
    <button class="p-2 w-20 text-white rounded-md bg-primary-700" @click="openForm">
      Submit
    </button>
  </div>
  <tg-modal :open="showForm" size="w-112" class="z-50" flex="true" @modal-close="closeForm">
    <template #header class="justify-center items-center">
      <span v-if="!submitting" class="el_2_550294a68020feb29926f9e0658f2836">Sign Off Sheet</span>
      <span v-if="submitting" class="el_3_550294a68020feb29926f9e0658f2836">Please Wait...</span>
    </template>
    <template #content>
      <div v-if="submitting" class="el_4_550294a68020feb29926f9e0658f2836">
        <TgLoader />
      </div>
      <form-panel v-if="!submitting" ref="FormPanel" :slug="slug" :selected="selected_row" @formerror="formHasError" @formsuccess="receiveFormData" />
    </template>
    <template #footer>
      <TgSubmit :disabled="submitting" @click="submitForm">
        <span v-if="!selected_row" class="el_5_550294a68020feb29926f9e0658f2836">Submit</span>
      </TgSubmit>
    </template>
  </tg-modal>

  <tg-alert ref="alert_box">
    <template #title>
      QA Check Failed
    </template>
    <template #content>
      You must mark all items as complete
    </template>
  </tg-alert>
</template>

<script>
import _each from "lodash/each";
import _get from "lodash/get";
import TgModal from "@/components/Common/TgModal.vue";
import FormPanel from "@/components/Grids/FormPanel.vue";
import TgSubmit from "@/components/Common/TgSubmit";
import TgLoader from "@/components/Common/TgLoader";
import { MOBILE } from "@/api";
import _has from "lodash/has";
import _values from "lodash/values";

export default {
  components: {
    TgModal,
    FormPanel,
    TgSubmit,
    TgLoader,
  },
  props: {
    slug: String,
    item: Object,
    col: Object,
    tab: Object,
    refreshParent: Function,
    params: String,
    grouped: Array,
  },
  data() {
    return {
      selected_row: null,
      submitting: false,
      showForm: false,
      form: null,
      props: null,
    };
  },
  mounted() {
    this.props = JSON.parse(_get(this.tab, "comp_props", "{}"));

    console.log("Mobile Submit Button", this.tab.comp_props);
  },
  methods: {
    formHasError() {
      this.submitting = false;
    },
    openForm() {
      let invalid = false;

      let qa_check = _get(this.props, "qa_check", false);

      if (qa_check === true) {
        _each(JSON.parse(JSON.stringify(this.grouped)), (g) => {
          _each(g.items, (i) => {
            let custom_ack = _get(i, "custom_ack", false);

            if (custom_ack === false) invalid = true;
          });
        });
      }

      if (invalid === true) {
        this.$refs.alert_box.openModal();
      } else {
        this.showForm = true;
      }
    },
    closeForm() {
      this.showForm = false;
    },
    submitForm() {
      this.submitting = true;
      this.$refs.FormPanel.get_payload();
    },
    receiveFormData(formsuccess) {
      this.form = formsuccess;
      this.submit();
    },
    async submit() {
      let flat = [];
      let form = this.form;

      _each(JSON.parse(JSON.stringify(this.grouped)), (g) => {
        _each(g.items, (i) => {
          let row = {
            serviceparent: i.serviceparent,
            service: i.service,
            flightkeyload: i.flightkeyload,
            ipadkey: i.ipadkey,
            masterlistkey: i.masterlistkey,
            suggestload: i.suggestload,
          };

          if (_has(i, "devicecount")) row.devicecount = i.devicecount;

          if (_has(i, "submitted_quantity"))
            row.submitted_quantity = i.submitted_quantity;

          if (_has(i, "submitted")) row.submitted_quantity = i.submitted;

          if (_has(i, "submitted_rollover"))
            row.submitted_rollover = i.submitted_rollover;

          if (_has(i, "submitted_damaged"))
            row.submitted_damaged = i.submitted_damaged;

          row.user_id = this.$auth.user.value.sub;
          row.deviceversion = process.env.VUE_APP_VERSION;

          flat.push({ ...form, ...row });
        });
      });

      let clean = {};

      _each(flat, (i) => {
        if (!_has(clean, i.ipadkey)) {
          clean[i.ipadkey] = i;
        } else {
          clean[i.ipadkey].suggestload += parseFloat(i.suggestload);
          clean[i.ipadkey].submitted_quantity += parseFloat(
            i.submitted_quantity
          );
          clean[i.ipadkey].submitted_rollover += parseFloat(
            i.submitted_rollover
          );
          clean[i.ipadkey].submitted_damaged += parseFloat(i.submitted_damaged);
        }
      });

      let resp = await MOBILE.bulk_insert(this.slug, _values(clean)).catch(
        (e) => {
          console.log(e);
        }
      );

      // await API.proc({
      //   proc: "SSDFp_680_Submissions_NEW_IPAD",
      //   params: [guid],
      // });

      this.showForm = false;
      this.submitting = false;
      this.$router.go(-1);
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Submit received!",
      });

      if (resp) {
        // this.$router.go(-1);
        let back = JSON.parse(this.$route.query.back);

        this.$router.push({
          name: "mobilepage",
          params: back.params,
          query: back.query,
        });
      } else {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
</style>