<template>
  <div v-if="config.show" class="el_1_336371133fbaacfa46a273afd86b3ac3 flex flex-col">
    <button
      v-if="item[col.field] >= 0 && show === true"
      class="flex-1 py-1 text-4xl text-white rounded-md"
      :class="[config.css, item[col.field] == 0 ? 'opacity-50' : '']"
      @click="openCalc"
    >
      {{ item[col.field] || 0 }}
    </button>
    <div class="el_2_336371133fbaacfa46a273afd86b3ac3 flex-1 text-sm text-gray-500">
      {{ item.quantity_dispatched_label }} {{ item.quantity_delivered_label }}
    </div>
  </div>
  <div v-else class="el_3_336371133fbaacfa46a273afd86b3ac3 flex flex-col">
    <button
      v-if="item[col.field] >= 0"
      class="flex-1 py-1 text-4xl text-white rounded-md"
      :class="[config.css, item[col.field] == 0 ? 'opacity-50' : '']"
      @click="openCalc"
    >
      {{ item[col.field] || 0 }}
    </button>
    <div class="el_4_336371133fbaacfa46a273afd86b3ac3 flex-1 text-sm text-gray-500">
      {{ item.quantity_dispatched_label }} {{ item.quantity_delivered_label }}
    </div>
  </div>

  <tg-modal :open="showCalc" size="w-full" class="z-50" @modal-close="closeCalc">
    <template #header class="justify-center items-center">
      {{ item.masterdescription }}
    </template>

    <template #content>
      <div class="el_5_336371133fbaacfa46a273afd86b3ac3 flex flex-col p-3 space-x-3 md:flex-row">
        <div class="el_6_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 space-y-3 md:w-8/12">
          <div
            class="el_7_336371133fbaacfa46a273afd86b3ac3 flex flex-col border border-gray-200 divide-y divide-gray-200 divide"
          >
            <div
              class="el_8_336371133fbaacfa46a273afd86b3ac3 flex-1 justify-between items-center p-2 text-white bg-gray-600"
            >
              Item Details
            </div>
            <div
              class="el_9_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_10_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Flight Key
              </div>
              <div class="el_11_336371133fbaacfa46a273afd86b3ac3 flex-1">
                {{ item.flightkeymeal }}
              </div>
            </div>
            <div
              class="el_12_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_13_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Description
              </div>
              <div class="el_14_336371133fbaacfa46a273afd86b3ac3 flex-1">
                {{ item.masterdescription_sub || item.masterdescription }}
              </div>
            </div>
            <div
              class="el_15_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_16_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Item key
              </div>
              <div class="el_17_336371133fbaacfa46a273afd86b3ac3 flex-1">
                {{ item.masterlistkey_sub || item.masterlistkey }}
              </div>
            </div>
            <div
              class="el_18_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_19_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Menu Item
              </div>
              <div class="el_20_336371133fbaacfa46a273afd86b3ac3 flex-1">
                {{ item.menuitem }}
              </div>
            </div>
            <div
              class="el_21_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_22_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Departing
              </div>
              <div class="el_23_336371133fbaacfa46a273afd86b3ac3 flex-1">
                {{ item.ltd }}
              </div>
            </div>
            <div
              class="el_24_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_25_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Model Refreshed
              </div>
              <div class="el_26_336371133fbaacfa46a273afd86b3ac3 flex-1">
                {{ humanDuration(item.minutes_since_refreshed) }} (every
                {{ item.refreshinterval }} mins)
              </div>
            </div>
          </div>

          <div class="el_27_336371133fbaacfa46a273afd86b3ac3 flex flex-col">
            <div
              class="el_28_336371133fbaacfa46a273afd86b3ac3 flex-1 justify-between items-center p-2 text-white bg-gray-600"
            >
              Bill of Materials
            </div>
            <TgLoader v-if="loading_bom" class="py-10" />
            <div v-else class="el_29_336371133fbaacfa46a273afd86b3ac3 border">
              <div
                v-if="bom.length == 0"
                class="el_30_336371133fbaacfa46a273afd86b3ac3 py-10 text-center text-gray-500"
                @click="get_bom"
              >
                No BOM data found for this item. Tap here to retry.
              </div>
              <template v-else>
                <div
                  v-for="(bom_items, bom_type) in bom"
                  :key="bom_type"
                  class="el_31_336371133fbaacfa46a273afd86b3ac3"
                >
                  <div
                    class="el_32_336371133fbaacfa46a273afd86b3ac3 px-3 font-bold text-gray-500 uppercase bg-gray-100"
                  >
                    {{ bom_type }}
                  </div>
                  <div
                    v-for="b in bom_items"
                    :key="b.masterlistkey"
                    class="el_33_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 ml-10 bg-white"
                  >
                    <div class="el_34_336371133fbaacfa46a273afd86b3ac3 flex-1">
                      {{ b.bom_description }} -
                      <span class="el_35_336371133fbaacfa46a273afd86b3ac3 text-sm text-gray-400">{{
                        b.bom_loadscale
                      }}</span>
                    </div>
                    <div
                      class="el_36_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 justify-between items-center space-x-3"
                    >
                      {{ b.bom_loadquantity }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div
            class="el_37_336371133fbaacfa46a273afd86b3ac3 flex flex-col border border-gray-200 divide-y divide-gray-200 divide"
          >
            <div
              class="el_38_336371133fbaacfa46a273afd86b3ac3 flex-1 justify-between items-center p-2 text-white bg-gray-600"
            >
              Load Quantities
            </div>
            <div
              v-if="type != 'production'"
              class="el_39_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_40_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Produced
                <div class="el_41_336371133fbaacfa46a273afd86b3ac3 text-sm text-gray-400">
                  Meals produced by kitchen
                </div>
              </div>
              <div
                class="el_42_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center space-x-3"
              >
                <div
                  v-if="item.submitts_produced"
                  class="el_43_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 p-3 text-2xl font-bold text-center bg-gray-200 rounded-lg"
                  @click="onUpdate(item.produced_provided)"
                >
                  {{ item.produced_provided || "-" }}
                </div>
                <div v-else class="el_44_336371133fbaacfa46a273afd86b3ac3 text-red-400">
                  Not Submitted
                </div>
                <div
                  v-if="item.submitts_produced"
                  class="el_45_336371133fbaacfa46a273afd86b3ac3 flex-1 text-gray-400"
                >
                  Received<br>{{ humanTime(item.submitts_produced) }}
                </div>
              </div>
            </div>
            <div
              v-if="type == 'delivery'"
              class="el_46_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_47_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Dispatched
                <div class="el_48_336371133fbaacfa46a273afd86b3ac3 text-sm text-gray-400">
                  Meals dispatched by warehouse
                </div>
              </div>
              <div
                class="el_49_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center space-x-3"
              >
                <div
                  v-if="item.submitts_dispatched"
                  class="el_50_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 p-3 text-2xl font-bold text-center bg-gray-200 rounded-lg"
                  @click="onUpdate(item.dispatched_provided)"
                >
                  {{ item.dispatched_provided || "-" }}
                </div>
                <div v-else class="el_51_336371133fbaacfa46a273afd86b3ac3 text-red-400">
                  Not Submitted
                </div>
                <div
                  v-if="item.submitts_dispatched"
                  class="el_52_336371133fbaacfa46a273afd86b3ac3 flex-1 text-gray-400"
                >
                  Received<br>{{ humanTime(item.submitts_dispatched) }}
                </div>
              </div>
            </div>
            <div
              v-if="type != 'production'"
              class="el_53_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_54_336371133fbaacfa46a273afd86b3ac3 flex-1">
                Contracted
                <div class="el_55_336371133fbaacfa46a273afd86b3ac3 text-sm text-gray-400">
                  Meals required as per contract
                </div>
              </div>
              <div
                class="el_56_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center space-x-3"
              >
                <div
                  v-if="item.quantity_contracted"
                  class="el_57_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 p-3 text-2xl font-bold text-center bg-gray-200 rounded-lg"
                  @click="onUpdate(item.quantity_contracted)"
                >
                  {{ item.quantity_contracted || "-" }}
                </div>
                <div v-else class="el_58_336371133fbaacfa46a273afd86b3ac3 text-red-400">
                  Not Submitted
                </div>
                <div
                  v-if="item.quantity_contracted"
                  class="el_59_336371133fbaacfa46a273afd86b3ac3 flex-1 text-gray-400"
                >
                  Received<br>{{ humanDuration(item.minutes_since_contracted) }}
                </div>
              </div>
            </div>
            <div
              class="el_60_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_61_336371133fbaacfa46a273afd86b3ac3 flex-1">
                PAX Booked Qty
                <div class="el_62_336371133fbaacfa46a273afd86b3ac3 text-sm text-gray-400">
                  Meals for booked passengers
                </div>
              </div>
              <div
                class="el_63_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center space-x-3"
              >
                <div
                  class="el_64_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 p-3 text-2xl font-bold text-center rounded-lg"
                  :class="qty_label == 'Booked' ? 'bg-primary-400 text-white' : 'bg-gray-200'"
                  @click="onUpdate(item.qtylive)"
                >
                  {{ item.qtylive || "NA" }}
                </div>
                <div
                  v-if="qty_label == 'Booked'"
                  class="el_65_336371133fbaacfa46a273afd86b3ac3 flex-1 font-bold text-primary-500"
                >
                  Active Window
                </div>
              </div>
            </div>
            <div
              v-if="type != 'production'"
              class="el_66_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center p-2 bg-white"
            >
              <div class="el_67_336371133fbaacfa46a273afd86b3ac3 flex-1">
                PAX Checkin
                <div class="el_68_336371133fbaacfa46a273afd86b3ac3 text-sm text-gray-400">
                  Meals for checked in passengers
                </div>
              </div>
              <div
                class="el_69_336371133fbaacfa46a273afd86b3ac3 flex flex-1 justify-between items-center space-x-3"
              >
                <div
                  class="el_70_336371133fbaacfa46a273afd86b3ac3 flex-shrink-0 p-3 text-2xl font-bold text-center rounded-lg"
                  :class="qty_label == 'Checkin' ? 'bg-primary-400 text-white' : 'bg-gray-200'"
                  @click="onUpdate(item.qtycheckin)"
                >
                  {{ item.qtycheckin || "NA" }}
                </div>
                <div
                  v-if="qty_label == 'Checkin'"
                  class="el_71_336371133fbaacfa46a273afd86b3ac3 flex-1 font-bold text-primary-500"
                >
                  Active Window
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="el_72_336371133fbaacfa46a273afd86b3ac3 flex-1">
          <div
            class="el_73_336371133fbaacfa46a273afd86b3ac3 p-2 text-2xl font-bold text-center uppercase"
          >
            Catering Quantity
          </div>
          <MobileIncrementCacl :qty="row[col.field]" @update-qty="onUpdate" />
        </div>
      </div>
    </template>
  </tg-modal>

  <tg-alert ref="alert_box">
    <template #title>
      Quantity Error
    </template>
    <template #content>
      {{ alert_msg }}
    </template>
  </tg-alert>
</template>

<script>
import MobileIncrementCacl from "@/components/Mobile/MobileIncrementCacl.vue";
import TgModal from "@/components/Common/TgModal.vue";
import _get from "lodash/get";
import _has from "lodash/has";
import _groupBy from "lodash/groupBy";
import { API } from "@/api";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import TgLoader from "@/components/Common/TgLoader";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);

export default {
  components: {
    MobileIncrementCacl,
    TgModal,
    TgLoader,
  },
  props: {
    slug: String,
    item: Object,
    col: Object,
    tab: Object,
    refresh: Function,
    params: String,
    itemIndex: Number,
    groupIndex: Number,
    cachekey: String,
    access: Boolean,
  },
  emits: ["update-item", "on:update", "formsuccess"],
  data() {
    return {
      type: null,
      qty_label: null,
      bom: [],
      row: this.item,
      show: null,
      alert_msg: "",
      config: {},
      loading_bom: false,
      showCalc: false,
    };
  },
  created() {
    this.type = this.$route.query.type;

    if (this.$route.query.type == "dispatch") {
      this.qty_label = this.item.quantity_dispatched_label;
    }
    if (this.$route.query.type == "delivery") {
      this.qty_label = this.item.quantity_delivered_label;
    }

    if (!this.params) {
      this.config = {};
    } else {
      this.config = JSON.parse(this.params);
    }

    if (_has(this.config, "show")) {
      let val = _get(this.item, this.config.show, false);
      if (val === 1) val = true;
      this.show = val;
    }

    this.config.css = _get(this.config, "css", "bg-primary-100 text-primary-800");
  },
  methods: {
    async get_bom() {
      this.loading_bom = true;
      this.bom = [];
      let resp = await API.get("142C028C794045679C09651B5CFE80D3", {
        id: this.item.id,
        _top: 20,
        _fields: ["bom_loadquantity", "bom_description", "bom_type", "bom_loadscale"],
        orderBy: "bom_type_asc",
      }).catch((e) => {
        this.loading = false;
        console.log(e);
      });

      if (resp) {
        if (resp.data.data.length > 0) this.bom = _groupBy(resp.data.data, "bom_type");
        this.loading_bom = false;
      }
    },
    humanTime(dz) {
      if (dz) {
        var x = dayjs.utc();
        var y = dayjs(dz);

        return dayjs.duration(x.diff(y)).humanize(true);
      }
      return;
    },

    humanDuration(minutes) {
      return dayjs.duration({ minutes: minutes }).humanize(true);
    },

    async onUpdate(qty) {
      if (qty == "NA") return;

      if (_has(this.config, "max_qty_fld", false)) {
        let max_qty_fld = this.config.max_qty_fld;
        let max_qty = _get(this.item, max_qty_fld, 999999);

        if (qty > max_qty) {
          this.alert_msg = _get(this.config, "alert_msg", "The quanitty is too big.");
          this.$refs.alert_box.openModal();
          return;
        }
      }

      this.closeCalc();

      this.row[this.col.field] = qty;
      this.row.v_row_css = "bg-green-100";

      this.$emit("on:update", {
        itemIndex: this.itemIndex,
        groupIndex: this.groupIndex,
        row: this.row,
      });
    },
    openCalc() {
      this.showCalc = true;
      this.get_bom();
    },
    closeCalc() {
      this.$refs.alert_box.closeModal();
      this.showCalc = false;
    },
  },
};
</script>

<style></style>
