<template>
  <div v-if="data" class="el_1_38fa3d47fed893949f8443b2fd2d71e9">
    <div class="el_2_38fa3d47fed893949f8443b2fd2d71e9 absolute top-5 right-6">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>

      <tg-btn color="primary" :click="copy">
        Copy Form
      </tg-btn>
      <tg-btn color="gray" :click="paste">
        Paste Form
      </tg-btn>
    </div>
    <div v-if="openFormFieldsConfigModal" class="el_3_38fa3d47fed893949f8443b2fd2d71e9">
      <form-fields-config-modal
        :open="openFormFieldsConfigModal"
        :slug="slug"
        :table="data.data_table"
        :select="data.pageconfig.form"
        @update:select="data.pageconfig.form = $event"
        @modal-close="openFormFieldsConfigModal = false"
      />
    </div>

    <div class="el_4_38fa3d47fed893949f8443b2fd2d71e9 flex w-full bg-gray-100">
      <div class="el_5_38fa3d47fed893949f8443b2fd2d71e9 flex-grow w-6/12 m-3">
        <tg-panel>
          <template #header>
            Available Fields
          </template>
          <template #header-buttons>
            <tg-btn color="gray" class="mr-1" :click="getFields" :disabled="loading">
              Refresh
            </tg-btn>
          </template>
          <template #content>
            <div class="el_6_38fa3d47fed893949f8443b2fd2d71e9 flex-grow">
              <input
                v-model="search_query"
                type="text"
                class="block w-full my-2 border-b border-gray-300 rounded-lg focus:outline-none focus:ring-primary-500 focus:border-primary-400"
                placeholder="Search..."
              >

              <div
                v-for="(item, index) in available_fields_results"
                :key="item.field"
                class="el_7_38fa3d47fed893949f8443b2fd2d71e9 w-full p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100"
                @click="add(item, index)"
              >
                {{ item.name }}
              </div>
            </div>
          </template>
        </tg-panel>
      </div>
      <div class="el_8_38fa3d47fed893949f8443b2fd2d71e9 flex-grow w-6/12 m-3">
        <tg-panel>
          <template #header>
            Form Fields
          </template>
          <template #header-buttons>
            <tg-btn color="gray" class="mr-1" :click="toggleFormFieldsConfigModal">
              Config
            </tg-btn>
            <tg-btn color="gray" :click="addHeader">
              Header
            </tg-btn>
          </template>
          <template #content>
            <draggable
              v-model="data.pageconfig.form"
              item-key="field"
              handle=".handle"
              ghost-class="ghost"
            >
              <template #item="{ element }">
                <form-item
                  :item="element"
                  @toggle-item-feature="toggleItemFeature"
                  @remove-item="removeItem"
                />
              </template>
            </draggable>
          </template>
        </tg-panel>
      </div>
    </div>
  </div>
  <Code2FA
    v-if="get_otp"
    :sending="sending"
    :error="error"
    @submit="save"
    @cancel="get_otp = false"
  />
</template>

<script>
import { STORE } from "@/store";
import { UTILS } from "@/utils";
import Code2FA from "@/components/Common/Code2FA";

import TgPanel from "@/components/Common/TgPanel.vue";
import TgBtn from "@/components/Common/TgBtn";

import FormItem from "./FormItem.vue";

import { API } from "@/api";
import FormFieldsConfigModal from "./FormFieldsConfigModal";

import _map from "lodash/map";
import _each from "lodash/each";
import _findIndex from "lodash/findIndex";

import { FORM_DEFAULT, EXCLUDED_FIELDS } from "@/default";
import draggable from "vuedraggable";

export default {
  name: "ConfigForm",
  components: {
    TgPanel,
    TgBtn,
    Code2FA,
    FormItem,
    draggable,
    FormFieldsConfigModal,
  },
  props: {
    slug: String,
  },
  emits: ["on:save"],
  data() {
    return {
      sending: false,
      loading: false,
      fields: null,
      error: null,
      get_otp: false,
      search_query: "",
      openFormFieldsConfigModal: false,
      data: null,
    };
  },

  computed: {
    available_fields_results() {
      if (this.search_query) {
        let filtered = this.fields.filter((item) => {
          return this.search_query
            .toLowerCase()
            .split(" ")
            .every((v) => item.field.toLowerCase().includes(v));
        });
        return filtered;
      } else {
        return this.fields;
      }
    },
  },

  async mounted() {
    let resp = await API.page({ slug: this.slug });
    this.data = resp.data.data;
    this.getFields();
  },
  methods: {
    addHeader() {
      let item = {
        field: UTILS.rand_string(8),
        name: "Grouper",
        formtype: "grouper",
      };
      this.data.pageconfig.form.push({ ...FORM_DEFAULT, ...item });
    },

    copy() {
      localStorage.setItem("clipboard", JSON.stringify(this.data.pageconfig.form));
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Copied!",
      });
    },
    paste() {
      let form = JSON.parse(localStorage.getItem("clipboard"));
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Pasted!",
      });
      this.data.pageconfig.form = form;
      localStorage.removeItem("clipboard");
    },
    save(otp) {
      this.sending = true;
      STORE.set(this.slug, this.data, otp)
        .then(() => {
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Page Saved!",
          });
          this.sending = false;
          this.get_otp = false;
          this.$emit("on:save");
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
        });
    },
    add(item, index) {
      this.data.pageconfig.form.push({ ...FORM_DEFAULT, ...item });
      this.fields.splice(index, 1);
    },
    toggleFormFieldsConfigModal() {
      this.openFormFieldsConfigModal = !this.openFormFieldsConfigModal;
    },
    toggleItemFeature(params) {
      let arr = params.fld;
      let item = params.item;

      if (arr === "pivot_arr") {
        this.data.pageconfig.select = _map(this.data.pageconfig.select, (i) => {
          if (i.field != item.field) i.pivot_arr = false;
          return i;
        });
        this.data.pageconfig[arr] = [params.item];
      } else {
        let index = _findIndex(this.data.pageconfig[arr], {
          field: params.item.field,
        });

        if (index === -1) {
          this.data.pageconfig[arr].push(params.item);
        } else {
          this.data.pageconfig[arr].splice(index, 1);
        }
      }
    },
    removeItem(params) {
      let arr = params.fld;
      let item = params.item;

      let index = _findIndex(this.data.pageconfig[arr], { field: item.field });

      if (index >= 0) {
        this.data.pageconfig[arr].splice(index, 1);
      }
    },
    async getFields() {
      this.loading = true;
      this.fields = [];

      let exclude = EXCLUDED_FIELDS;

      _each(this.data.pageconfig.form, (i) => {
        exclude.push(i.field);
      });

      let resp = await API.get_fields(this.slug, {
        tablename: this.data.data_table,
        orderBy: "field_order_ASC",
        "!field": EXCLUDED_FIELDS,
      }).catch((e) => {
        console.log(e.message);
      });

      this.loading = false;

      if (resp) {
        this.fields = _map(resp.data.data, (i) => {
          i.field = i.field.toLowerCase();
          return i;
        });
      }
    },
  },
};
</script>

<style></style>
