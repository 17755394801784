<template>
  <div class="el_1_095e32e4c3034f27765b7c9d03574de0 relative px-5 py-2">
    <nav class="flex -mb-px space-x-8" aria-label="Tabs">
      <a v-for="t in tabs" :key="t.name" class="el_2_095e32e4c3034f27765b7c9d03574de0" :class="[
        t.id === current
          ? 'border-primary-400 text-primary-800'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
      ]" :aria-current="t.id === current ? 'page' : undefined" @click="current = t.id">
        {{ t.name }}
      </a>
    </nav>

    <config-settings v-if="current === 0" :slug="slug" :tab="tab" @on:save="onCloseNull" @on:close="onClose" />
    <config-data v-if="current === 1" :slug="slug" @on:save="onCloseNull" />
    <config-form v-if="current === 2" :slug="slug" @on:save="onCloseNull" />
    <config-api v-if="current === 3" :slug="slug" @on:save="onCloseNull" />

    <config-slots v-if="current === 4" :slug="slug" @on:save="onCloseNull" />
    <config-raw-json v-if="current === 5" :slug="slug" @on:save="onCloseNull" />
    <config-open-ai v-if="current === 6" :slug="slug" :tab="tab" @on:save="onCloseNull" />

    <!-- <config-transform v-if="current === 5" :slug="slug" @on:save="onCloseNull" />
    <config-database v-if="current === 3" :slug="slug" @on:save="onCloseNull" />
    <config-mobile v-if="current === 7" :slug="slug" @on:save="onCloseNull" />
    
    <config-uat v-if="current === 9" :tab="tab" :slug="slug" />
    <config-api-php v-if="current === 10" :tab="tab" :slug="slug" @on:save="onCloseNull" /> -->
  </div>
</template>

<script>
import { ref } from "vue";
import ConfigSettings from "./ConfigSettings.vue";
import ConfigData from "./ConfigData.vue";
import ConfigForm from "./ConfigForm.vue";
// import ConfigDatabase from "./ConfigDatabase.vue";
import ConfigApi from "./ConfigApi.vue";
// import ConfigTransform from "./ConfigTransform.vue";
import ConfigSlots from "./ConfigSlots.vue";
// import ConfigMobile from "./ConfigMobile.vue";
import ConfigRawJson from "./ConfigRawJson.vue";
import ConfigOpenAi from "./ConfigOpenAi.vue";
// import ConfigUat from "./ConfigUat.vue";
// import ConfigApiPhp from "./ConfigApiPhp";

export default {
  components: {
    ConfigSettings,
    ConfigData,
    ConfigForm,
    // ConfigDatabase,
    ConfigApi,
    // ConfigTransform,
    ConfigSlots,
    // ConfigMobile,
    // ConfigUat,
    ConfigRawJson,
    ConfigOpenAi,
    // ConfigApiPhp,
  },
  props: {
    slug: String,
    tab: Object,
  },
  emits: ["grid-config-close"],
  setup() {
    const open = ref(true);

    const tabs = [
      { name: "Settings", id: 0 },
      { name: "Grid", id: 1 },
      { name: "Form", id: 2 },
      // { name: "Database" },
      { name: "API", id: 3 },
      // { name: "Transform" },
      { name: "Slots", id: 4 },
      // { name: "Mobile" },
      { name: "Raw JSON", id: 5 },
      { name: "OpenAI", id: 6 },
      // { name: "UAT" },
      // { name: "API PHP" },
    ];

    return {
      open,
      tabs,
    };
  },
  data() {
    return {
      current: 0,
      sending: false,
    };
  },
  methods: {
    onCloseNull() { },
    onClose() {
      this.open = false;
      this.$emit("grid-config-close");
    },
  },
};
</script>
