<template>
  <div v-if="item[col.field] >= 0" class="el_1_db14439f588c60beeecb618eddaabaa0 flex">
    <button class="flex-1 py-3 rounded-md" :class="config.css">
      {{ item[col.field] }}
    </button>
  </div>
</template>

<script>
import _get from "lodash/get";

export default {
  components: {},
  props: {
    slug: String,
    item: Object,
    col: Object,
    refresh: Function,
    params: String,
    itemIndex: Number,
    groupIndex: Number,
  },
  emits: ["update-item"],
  data() {
    return {
      config: {},
    };
  },
  created() {
    if (!this.params) {
      this.config = {};
    } else {
      this.config = JSON.parse(this.params);
    }
    if (this.item[this.col.field] >= 0) {
      this.config.css = _get(this.config, "css", "");
      if (this.item[this.col.field] == 0) {
        this.config.css += " opacity-50";
      }
    } else {
      this.config.css = "hidden";
    }
  },
};
</script>

<style></style>
