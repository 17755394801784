<template>
  <div class="relative flex-1">
    <div class="flex bg-gray-200 border z-20 items-center border-gray-200 rounded-sm"
      :class="required && !val ? 'border-red-400' : ''" @click="open_options">
      <input ref="search_box" v-model="val" :title="val" :class="css" :readonly="readonly" :placeholder="placeholder"
        class="w-full outline-none bg-white disabled:bg-gray-100 read-only:bg-yellow-50 " :disabled="disabled"
        @focus="$event.target.select()" @keydown="change" @blur="check_input">
      <button class="flex-shrink-0 px-2" :disabled="disabled || readonly">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-gray-700"
          :class="show ? 'transform rotate-180' : ''">
          <path fill-rule="evenodd"
            d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <div v-show="show" class="absolute z-40 top-9 left-0 bg-white w-full">
      <div
        class="flex flex-col divide-y divide-gray-200 text-left border border-gray-200 h-96 bg-white overflow-y-auto shadow-lg">
        <div v-for="(item, index) in result_query" :key="index" :class="item[label] == val ? 'bg-gray-200' : ''"
          class="px-2 py-1 bg-white hover:bg-gray-100" @click="select(item)">
          {{ item[label] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import _findIndex from "lodash/findIndex";
export default {
  props: {
    options: Array,
    label: {
      type: [String, Number],
      default: "label",
      required: true,
    },
    placeholder: {
      type: [String],
      default: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: false,
    },
    sql: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: "value",
      required: true,
    },
    css: {
      type: [String],
      default: "p-1",
      required: false,
    },
    model: [String, Number],
  },
  emits: ["select"],
  data() {
    return {
      show: false,
      search: "",
      val: "",
    };
  },
  computed: {
    result_query() {
      if (this.search) {
        let filtered = this.options.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item[this.label].toLowerCase().includes(v));
        });
        return filtered;
      } else {
        return this.options;
      }
    },
  },
  watch: {
    model: {
      immediate: true,
      deep: false,
      handler(val) {
        this.show = false;
        let params = {};
        params[this.value] = val;
        let index = _findIndex(this.options, params);

        if (index >= 0) {
          let row = this.options[index];
          if (row) this.val = row[this.label];
        } else {
          if (this.append) {
            this.val = val;
          } else {
            this.val = null;
          }
        }
      },
    },
  },
  mounted() {
    window.addEventListener("keydown", this.escapeListener);
  },
  unmounted() {
    window.removeEventListener("keydown", this.escapeListener);
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.show = false;
      }
    });
  },
  methods: {
    check_input() {
      if (this.readonly)
        return;
      if (this.append) {
        this.$emit("select", this.val);
      } else {
        let params = {};
        params[this.value] = this.val;
        let index = _findIndex(this.options, params);
        if (index < 0 && !_isEmpty(this.sql)) {
          this.val = "";
        }

        if (this.val == "") this.$emit("select", "");
      }
    },
    open_options() {
      if (this.readonly)
        return;
      this.$refs.search_box.focus();
      this.show = !this.show;
    },
    escapeListener(event) {
      if (event.key === "Escape") {
        event.preventDefault();
        this.show = false;
      }
    },
    change() {
      if (this.readonly)
        return;
      this.search = this.val;
      this.show = true;
    },
    select(item) {
      if (this.readonly)
        return;
      this.search = "";
      this.show = false;
      this.val = item[this.label];
      this.$emit("select", item[this.value]);
    },
  },
};
</script>

<style></style>
