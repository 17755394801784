<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" :initial-focus="closeButtonRef" @close="closeModal">
      <div class="el_1_4e22b4c1d3a50ab8e69eaf5239f9c53f fixed inset-0 z-50 overflow-y-auto">
        <div class="el_2_4e22b4c1d3a50ab8e69eaf5239f9c53f min-h-screen px-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </TransitionChild>

          <span class="el_3_4e22b4c1d3a50ab8e69eaf5239f9c53f inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <div class="el_4_4e22b4c1d3a50ab8e69eaf5239f9c53f inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <DialogTitle as="h3" class="text-2xl font-medium leading-6 text-gray-900">
                <slot name="title">
                  {{ config.title }}
                </slot>
              </DialogTitle>
              <div class="el_5_4e22b4c1d3a50ab8e69eaf5239f9c53f mt-2">
                <p class="text-lg text-gray-500">
                  <slot name="content">
                    {{ config.content }}
                  </slot>
                </p>
              </div>

              <div class="el_6_4e22b4c1d3a50ab8e69eaf5239f9c53f mt-4">
                <button ref="closeButtonRef" type="button" class="inline-flex justify-center px-4 py-2 mr-5 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none" @click="closeModal">
                  Cancel
                </button>
                <slot name="footer" />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  data() {
    return {
      isOpen: false,
      config: {},
    };
  },
  methods: {
    closeModal() {
      this.isOpen = false;
    },
    openModal(config) {
      this.config = config;
      this.isOpen = true;
    },
  },
};
</script>
