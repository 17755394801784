import { reactive, provide } from 'vue';

// Create a reactive object
export const globalVariable = reactive({
    value: null,
    updateValue(newValue) {
        globalVariable.value = newValue;
    }
});

// Provide the global variable to the Vue app
provide('globalVariable', globalVariable);