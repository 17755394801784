<template>
  <notificationGroup group="toast">
    <div
      class="el_1_26cdacf7bea11d02e03bd06f5d44a5fa flex fixed inset-0 z-50 justify-end items-end p-6 px-4 py-6 pointer-events-none">
      <div class="el_2_26cdacf7bea11d02e03bd06f5d44a5fa w-full max-w-sm">
        <notification v-slot="{ notifications }">
          <div v-for="n in notifications" :key="n.id" class="el_3_26cdacf7bea11d02e03bd06f5d44a5fa">
            <div
              class="el_4_26cdacf7bea11d02e03bd06f5d44a5fa flex overflow-hidden relative mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-lg">
              <XIcon class="absolute top-2 right-2 w-6 h-6 text-gray-300 cursor-pointer hover:text-primary-500" />

              <div class="el_5_26cdacf7bea11d02e03bd06f5d44a5fa flex justify-center items-center w-12" :class="[
                n.type == 'warning' ? 'bg-yellow-500' : '',
                n.type == 'info' ? 'bg-primary-500' : '',
                n.type == 'success' ? 'bg-green-500' : '',
                n.type == 'danger' ? 'bg-red-600' : '',
              ]">
                <InformationCircleIcon v-if="n.type == 'info'" class="w-6 h-6 text-white" />
                <CheckIcon v-if="n.type == 'success'" class="w-6 h-6 text-white" />
                <ExclamationIcon v-if="n.type == 'warning'" class="w-6 h-6 text-white" />
                <ExclamationIcon v-if="n.type == 'danger'" class="w-6 h-6 text-white" />
              </div>

              <div class="el_6_26cdacf7bea11d02e03bd06f5d44a5fa px-4 py-2 -mx-3">
                <div class="el_7_26cdacf7bea11d02e03bd06f5d44a5fa mx-3 text-left" @click="close(n.id)">
                  <span class="el_8_26cdacf7bea11d02e03bd06f5d44a5fa text-lg font-semibold" :class="[
                    n.type == 'warning' ? 'text-yellow-500' : '',
                    n.type == 'info' ? 'text-primary-500' : '',
                    n.type == 'success' ? 'text-green-500' : '',
                    n.type == 'danger' ? 'text-red-600' : '',
                  ]">
                    {{ n.title }}</span>
                  <p class="text-base text-gray-600">
                    {{ n.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </notification>
      </div>
    </div>
  </notificationGroup>

  <div v-if="!$route.meta.public" class="el_9_26cdacf7bea11d02e03bd06f5d44a5fa">
    <div v-if="$auth.loading.value"
      class="el_10_26cdacf7bea11d02e03bd06f5d44a5fa grid place-items-center h-screen bg-white">
      <div class="el_11_26cdacf7bea11d02e03bd06f5d44a5fa px-10 bg-white rounded-md w-112">
        <TgLoader />
        <div class="el_12_26cdacf7bea11d02e03bd06f5d44a5fa text-3xl text-gray-400">
          Validating your session...
        </div>
      </div>
    </div>
  </div>
  <!-- <TgSupport v-if="ready" /> -->

  <router-view />

  <div v-if="updateExists"
    class="el_13_26cdacf7bea11d02e03bd06f5d44a5fa fixed right-0 bottom-0 left-0 p-2 text-lg font-bold bg-green-100"
    @click="refreshApp">
    Tap here to get the latest version!
  </div>
  <!-- Inactivity overlay -->
  <div v-if="isInactive" class="inactivity-overlay" @click="login">
    <p>Session Timeout. Please login again. </p>
  </div>
</template>

<script>
import TgLoader from "@/components/Common/TgLoader";
import _get from "lodash/get";
import update from "@/mixins/update";
import { API } from "@/api";

import { globalVariable } from '@/globalVariable';

import { InformationCircleIcon, XIcon, CheckIcon, ExclamationIcon } from "@heroicons/vue/outline";
export default {
  components: {
    TgLoader,
    InformationCircleIcon,
    XIcon,
    // TgSupport,
    ExclamationIcon,
    CheckIcon,
  },
  mixins: [update],
  data() {
    return {
      ready: false,
      isInactive: false,
      inactivityTimeout: (60000 * 1) * 240, // 55 minutes 
      activityTimer: null,
      tokenRefreshTimer: null,
      refreshInterval: (5000), // 50 minutes 
      // refreshInterval: (60000 * 5), // 50 minutes 

    };
  },
  mounted() {
    setInterval(() => {
      this.ready = true;
      this.checkTokenExpiration();
    }, 5000);
    window.addEventListener('mousemove', this.refreshAccessToken);
    // Call the setup method when the component is mounted

  },
  beforeUnmount() {
    // Clear the timer when the component is about to be destroyed
    this.clearTokenRefreshTimer();
    // Clean up event listeners and timers when the component is destroyed
    window.removeEventListener('mousemove', this.refreshAccessToken);

    clearTimeout(this.activityTimer);
  },
  methods: {
    async login() {
      await this.$auth.logout({
        returnTo: location.origin,
      });
    },
    async checkTokenExpiration() {
      if (this.isInactive)
        return;
      try {
        const accessToken = globalVariable.value;
        if (accessToken) {
          const [, payload] = accessToken.split('.');
          const decodedPayload = JSON.parse(atob(payload));

          const expirationTime = decodedPayload.exp;
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

          // Check if the token is about to expire (e.g., within the next 5 minutes)
          const timeToExpiration = expirationTime - currentTime;
          const bufferTime = 5; // 5 minutes

          if (timeToExpiration < bufferTime) {
            this.isInactive = true;
            // Request a new token if the current token is about to expire

            window.removeEventListener('mousemove', this.refreshAccessToken);
            return;
          } else {
            // console.log('#### token ok ####');
          }


        }

      } catch (error) {
        console.error('Error during token expiration check:', error);
      }
    },
    cleanup() {
      console.log('Cleaning up');
      this.clearTokenRefreshTimer();
      window.removeEventListener('mousemove', this.refreshAccessToken);
      window.removeEventListener('keydown', this.handleUserActivity);
      clearTimeout(this.activityTimer);
    },
    setupInactivityCheck() {
      console.log('Setting up inactivity check');
      // Set up event listeners
      // window.addEventListener('mousemove', this.refreshAccessToken);
      // window.addEventListener('keydown', this.handleUserActivity);

      // Start the inactivity check timer
      this.activityTimer = setTimeout(() => {
        this.isInactive = true;
        this.cleanup();
        // log user out if inactive
      }, this.inactivityTimeout);
    },
    handleUserActivity() {
      // Reset the inactivity check timer on user activity
      // this.refreshAccessToken();
      // this.isInactive = false;
      // clearTimeout(this.activityTimer);
      // this.activityTimer = setTimeout(() => {
      //   this.isInactive = true;
      //   this.cleanup();
      // }, this.inactivityTimeout);
    },
    async refreshAccessToken() {
      const accessToken = globalVariable.value;
      if (accessToken) {
        try {
          const token = await this.$auth.getTokenSilently();
          globalVariable.updateValue(token);
          API.updateInterceptor(); // Update Axios interceptor with new token

        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }
    },
    setupTokenRefresh() {
      // Set up a timer to refresh the token periodically
      this.tokenRefreshTimer = setInterval(() => {
        // this.checkTokenExpiration();
        this.refreshAccessToken();
      }, this.refreshInterval);
    },
    clearTokenRefreshTimer() {
      // Clear the timer to stop refreshing the token when the component is destroyed
      if (this.tokenRefreshTimer) {
        clearInterval(this.tokenRefreshTimer);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Calibri, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  font-size: 12px;
}

.inactivity-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 9999;
}

.tour-highlight {
  border-width: 4px !important;
  border-style: solid !important;
  border-color: rgba(2, 133, 199, 0.945) !important;
  @apply rounded-md;
}

.tour-highlight-black {
  border: 4px solid #000000 !important;
  @apply rounded-md;
}

.tourcontent {

  ol,
  ul {
    @apply pl-5 my-3 list-inside;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}

trav {
  display: block;
  font-style: italic;
  background: theme("colors.yellow.100");
  @apply before:content-['TJ'];
  @apply rounded-md ml-5 px-2 py-1 mb-2 before:pr-3 before:font-bold w-[600px] break-words whitespace-pre-line;
}

lach {
  display: block;
  font-style: italic;
  background: theme("colors.stone.200");
  // color: white;
  @apply before:content-['LK'];
  @apply rounded-md ml-5 px-2 py-1 mb-2 before:pr-3 before:font-bold w-[600px] break-words whitespace-pre-line;
}

gus {
  display: block;
  font-style: italic;
  background: theme("colors.primary.100");
  @apply before:content-['AK'];
  @apply rounded-md ml-5 px-2 py-1 mb-2 before:pr-3 before:font-bold w-[600px] break-words whitespace-pre-line;
}

mike {
  display: block;
  font-style: italic;
  background: theme("colors.green.100");
  @apply before:content-['ML'];
  @apply rounded-md ml-5 px-2 py-1 mb-2 before:pr-3 before:font-bold w-[600px] break-words whitespace-pre-line;
}

des {
  display: block;
  font-style: italic;
  background: theme("colors.pink.100");
  @apply before:content-['DA'];
  @apply rounded-md ml-5 px-2 py-1 mb-2 before:pr-3 before:font-bold w-[600px] break-words whitespace-pre-line;
}

.dots {
  .danger {
    @apply bg-red-400 text-white;
  }

  .success {
    @apply bg-green-400 text-white;
  }

  .warning {
    @apply bg-yellow-400 text-white;
  }
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

.h-85 {
  height: calc(100% - 15%);
}

.h-90 {
  height: calc(100% - 10%);
}

.h-95 {
  height: calc(100% - 5%);
}

.h-900 {
  height: 900px;
}

.w-250 {
  width: 250px;
}

.ghost {
  background: theme("colors.primary.200");
}

.tgHead {
  background-color: theme("colors.gray.100");
}

.grp-primary,
.grp-gray {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  text-transform: uppercase;
  // margin-right: 0.125rem;
}

.grp-primary {
  background-color: theme("colors.primary.100");
  color: theme("colors.primary.800");
  border: 1px solid theme("colors.primary.200");
  font-weight: 600;
}

.grp-gray {
  background-color: theme("colors.gray.200") !important;
  color: theme("colors.gray.800") !important;
  border: 1px solid theme("colors.gray.300");
}

.col-primary {
  background-color: theme("colors.primary.50");
  color: theme("colors.primary.800");
}

.col-gray {
  background-color: theme("colors.gray.100");
  color: theme("colors.gray.800") !important;
}

.col-primary-s {
  border-left: 1px solid theme("colors.primary.300") !important;
}

.col-primary-e {
  border-right: 1px solid theme("colors.primary.300") !important;
}

.col-gray-s {
  border-left: 1px solid theme("colors.gray.400") !important;
}

.col-gray-e {
  border-right: 1px solid theme("colors.gray.400") !important;
}

.color_default {
  background: #efefef;
}

.color_blue {
  background: #e3f2fd;
}

.color_green {
  background: #e8f5e9;
}

.color_red {
  background: #ffebee;
}

.color_gray {
  background: #f5f5f5;
}

.color_orange {
  background: #fff3e0;
}

.color_purple {
  background: #ede7f6;
}

.color_cyan {
  background: #e0f7fa;
}

.color_yellow {
  background: #fff9c4;
}

.col-key {
  background: theme("colors.primary.50");
  color: theme("colors.primary.500");
  font-weight: 600;
  // border-color: theme("colors.primary.150") !important;
}

.tgCol {
  border-right: 1px solid theme("colors.gray.200");
  padding: 1.75px 5px 1.75px 5px;
}

.tgColFields {
  background: theme("colors.gray.100") !important;
  color: theme("colors.gray.600") !important;
  border-top: 1px solid theme("colors.gray.300");
  border-bottom: 1px solid theme("colors.gray.300");
}

.tgCol:first-child {
  border-left: 1px solid theme("colors.gray.200");
}

.tgGrpCol {
  text-align: center;
  font-size: 12px;
}

.group-header>.grpBlue {
  background-color: theme("colors.blue.400");
  border-right: 1px solid white;
  color: white;
}

.tgRow>.grpBlue {
  background-color: theme("colors.blue.50");
  border-color: white;
}

.gridpage {
  // .tgRow {
  //   opacity: 0.8;
  //   font-weight: 300;
  // }
}

.json {
  background: theme("colors.gray.600") !important;
  color: theme("colors.yellow.200");
  // font-size: 1rem !important;
  letter-spacing: 0.05em;
}

.sql {
  background: theme("colors.gray.600") !important;
  color: theme("colors.primary.200");
  letter-spacing: 0.05em;
}

.tgRowLinks {
  background: theme("colors.gray.200");
}

.help {
  .container {
    font-size: 14px !important;
    @apply bg-white mx-auto w-[1024px] rounded-lg p-40 pt-20 shadow-lg;

    h3 {
      font-size: 17px !important;
      @apply font-bold text-gray-700;
    }

    // h3::before {
    //   content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" /></svg>');
    //   width: 16px;
    //   height: 16px;
    //   display: inline-block;
    //   @apply mr-2 text-gray-500;
    // }

    h4 {
      font-size: 14px !important;
      @apply font-bold text-gray-600;
    }

    ol,
    ul {
      @apply pl-5 my-3 list-outside;
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }

    h1 {
      font-size: 2.5rem;
      text-transform: uppercase;
      color: theme("colors.primary.500");
      margin-bottom: 1.25rem;
      @apply border-b border-gray-200;
    }

    h2,
    .h2,
    .h2t,
    .h2b {
      font-size: 18px;
      text-transform: uppercase;
      color: theme("colors.primary.500");

      margin-bottom: 1rem;
      @apply font-thin;
    }

    .h2t {
      border-top: 1px solid theme("colors.gray.200");
    }

    .h2b {
      border-bottom: 1px solid theme("colors.gray.200");
    }

    h3,
    .h3 {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    h4,
    .h4 {
      font-size: 13px;
      font-weight: 700;
    }

    p {
      letter-spacing: 0.025em;
      line-height: 1.625;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    ul,
    ol {
      margin-bottom: 30px;
      margin-left: 50px;

      li {
        letter-spacing: 0.025em;
        line-height: 1.625;
      }
    }

    // ul.list-decimal {
    //   list-style-type: decimal;
    // }
    // ul.list-disc {
    //   list-style-type: disc;
    // }

    .note {
      padding: 20px;
      margin: 10px 0 10px 0;
      border-radius: 10px;
    }

    img {
      max-height: 24rem;
    }

    a {
      color: theme("colors.primary.500") !important;
      text-decoration: underline;
      cursor: pointer;
    }

    table {
      width: 100%;
    }

    tr {
      border-bottom: 1px solid theme("colors.gray.200");
      border-left: 1px solid theme("colors.gray.200");
      border-right: 1px solid theme("colors.gray.200");
    }

    tr:first-child {
      border-top: 1px solid theme("colors.gray.200");
    }

    th,
    td {
      padding: 1px 5px 1px 5px;
    }

    th {
      background: theme("colors.gray.100");
    }
  }
}

.blink-250 {
  animation: blinker 2.5s linear infinite;
}

.blink-200 {
  animation: blinker 2s linear infinite;
}

.blink-150 {
  animation: blinker 1.5s linear infinite;
}

.blink-100 {
  animation: blinker 1s linear infinite;
}

.blink-50 {
  animation: blinker 0.5s linear infinite;
}

.blink-25 {
  animation: blinker 0.25s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink-border-100 {
  animation: blink_border 1s linear infinite;
}

.blink-border-50 {
  animation: blink_border 0.5s linear infinite;
}

.blink-border-25 {
  animation: blink_border 0.25s linear infinite;
}

@keyframes blink_border {
  50% {
    border-color: #fff;
  }
}
</style>
