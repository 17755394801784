<template>
  <label :for="item.field" class="relative block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_ee66fa49597769e856b5be681db6a83b inline mr-1 text-sm text-red-400">*</span> {{ item.name }}
  </label>
  <div class="el_2_ee66fa49597769e856b5be681db6a83b flex items-center p-2 mt-1 bg-white rounded-md shadow-sm">
    <div class="el_3_ee66fa49597769e856b5be681db6a83b flex-1">
      <TgAwsSecureDownload :file="file_obj" />
    </div>
    <div class="el_4_ee66fa49597769e856b5be681db6a83b flex-1">
      <TgAwsSecureUploader :slug="slug" @upload:success="upload_success" />
    </div>
  </div>
  <div v-if="show_help && item.popover" class="el_5_ee66fa49597769e856b5be681db6a83b absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300" @click="show_help = false">
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import TgAwsSecureUploader from "@/components/Common/TgAwsSecureUploader.vue";
import TgAwsSecureDownload from "@/components/Common/TgAwsSecureDownload.vue";

export default {
  components: {
    TgAwsSecureUploader,
    TgAwsSecureDownload,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    modelValue: String,
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
      file_obj: {},
    };
  },

  methods: {
    upload_success(file) {
      console.log(file);
      try {
        this.file_obj = JSON.parse(file);
      } catch {}
      this.$emit("update:modelValue", file);
    },
  },
};
</script>