import _get from 'lodash/get';
import _each from 'lodash/each';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

const Validators = {

    validate(value, item) {

        if (!value)
            return;

        let error = false;

        _each(item.validators, v => {
            
            if (v.type == 'length') {
                value = value.toString();
                let len = value.length;
                if (len < _get(v, 'min', 0) || len > _get(v, 'max', 9999999999)) {
                    error = `This field must be between ${v.min} and ${v.max} characters`;
                }
            }

            if (v.type == 'time') {

                if (/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value) === false) {
                    error = "Invalid time."
                }
            }
            if (v.type == 'integer') {
                if (/^\d+$/.test(value) === false) {
                    error = "Number only. Without decimals"
                }
            }
            if (v.type == 'float') {
                if (/^\d*\.?\-?\d*$/.test(value) === false) {
                    error = "Number only. Can contain decimals"
                }
            }
            
        })

        return error;
    }
}

export default Validators