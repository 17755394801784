<template>
  <div v-if="data" class="py-5">
    <div class="el_2_abc84ce152ab4b0aed82945ee82893c5 absolute top-5 right-6">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>
    </div>

    <div class="flex space-x-2">
      <div v-for="t in tabs" :key="t.id" class="flex-1 p-2 rounded-t-md cursor-pointer"
        :class="current_tab == t.id ? 'bg-primary-500 text-white' : 'bg-gray-200'" @click="current_tab = t.id">
        {{ t.name }}
      </div>
    </div>

    <div class="border border-gray-300 p-5">
      <div v-if="current_tab == 2" class="flex justify-between space-x-5">
        <div class="flex-1">
          <div class="flex flex-col space-y-2">
            <div class="flex-1">
              <input v-model="search_text"
                class="p-2 border border-gray-300 w-full rounded-md text-lg focus:outline-primary-300"
                placeholder="Search users..." :disabled="loading" @keyup.enter="getallusers">
            </div>
            <div class="flex-1 flex flex-col divide-y divide-gray-200">
              <TgLoader v-if="loading" />
              <div v-else>
                <div v-for="u in all_users" :key="u.email"
                  class="flex-1 p-2 bg-white rounded-md cursor-pointer hover:bg-gray-200"
                  :class="u.hide ? 'hidden' : ''" @click="add_user(u)">
                  <div>
                    {{ u.fullname }} <span class="text-sm text-gray-500">{{ u.email }}</span>
                  </div>
                  <div class="text-sm text-gray-400">
                    {{ u.company || "Not Available" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <label class="font-bold text-gray-700">
            Allowed API Users -
            <span class="text-blue-400 cursor-pointer hover:underline" @click="getusers">
              <svg xmlns="http://www.w3.org/2000/svg" class="inline w-5 h-5" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
            </span>
          </label>

          <div class="">
            <TgLoader v-if="loading_users" />
            <div v-else>
              <div v-for="u in users" :key="u.id" class="flex p-2 mb-1 bg-white" :class="u.css">
                <div class="flex-1" title="click to copy" @click="copyText(u.token)">
                  {{ u.fullname }}
                  <div class="text-sm text-gray-400">
                    {{ u.email }}
                  </div>
                </div>
                <div class="flex-shrink-0 text-right flex space-x-2">
                  <div class="p-1 rounded-md text-white cursor-pointer"
                    :class="u.access_get ? 'bg-green-500 text-white' : 'bg-gray-600'"
                    @click="update_user(u, 'access_get')">
                    GET
                  </div>
                  <div class="p-1 rounded-md text-white cursor-pointer"
                    :class="u.access_post ? 'bg-green-500 text-white' : 'bg-gray-600'"
                    @click="update_user(u, 'access_post')">
                    POST
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="inline w-6 h-6 hover:text-red-400 cursor-pointer"
                    fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" @click="remove(u)">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="current_tab == 3" class="flex flex-col space-y-5">
        <div class="flex-1 bg-white p-2">
          <label for="testing" class="block text-sm font-bold text-gray-700"> API Details </label>
          <div class="flex flex-col mt-1 space-y-2">
            <div class="flex-1 flex space-x-1 items-center">
              <div class="uppercase p-2 bg-gray-200 rounded-md flex-shrink-0 w-20 text-sm text-center"
                @click="copyText(endpoint)">
                EP
              </div>
              <div class="p-2 border border-gray-200 flex-1 text-sm">
                {{ endpoint }}
              </div>
            </div>
            <div class="flex-1 flex space-x-1 items-center">
              <div class="uppercase p-2 bg-gray-200 rounded-md flex-shrink-0 w-20 text-sm text-center"
                @click="copyText(apikey)">
                APP ID
              </div>
              <div class="p-2 border border-gray-200 flex-1 text-sm">
                {{ apikey }}
              </div>
            </div>
            <div class="flex-1 flex space-x-1 items-center">
              <div class="uppercase p-2 bg-gray-200 rounded-md flex-shrink-0 w-20 text-sm text-center"
                @click="copyText(testing.token)">
                API Key
              </div>
              <div class="flex-1">
                <select v-model="testing.token" class="w-full border-gray-200" placeholder="select a user">
                  <option v-for="u in users" :key="u.token" :value="u.token">
                    {{ u.fullname }} - {{ u.token }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <label for="testing" class="block text-sm font-bold text-gray-700">
            Step 2. Search Parameters
          </label>
          <div class="flex flex-col mt-1 space-y-2">
            <div v-for="f in filters" :key="f.field" class="">
              <input v-model="testing.params[f.field]" :placeholder="f.name"
                :class="f.lock ? 'border-red-500 border-2 border-dotted' : 'border-gray-300 border'"
                class="block p-1 w-full rounded-md shadow-sm sm:text-sm">
            </div>
            <div class="flex-1">
              <TgBtn color="gray" :disabled="!testing.token" @click="query_api">
                Send
              </TgBtn>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <label for="testing" class="block text-sm font-bold text-gray-700">
            Step 2. Response - Result -
            <span class="text-primary-400" @click="copyTextSample()">Copy Sample</span>
          </label>
          <pre class="overflow-auto rounded-md json json_resp"
            @click="copyText(testing.response_2)">{{ testing.response_2 }}</pre>
        </div>
      </div>
      <div v-if="current_tab == 4">
        <label class="text-sm font-bold text-gray-700">
          Last 10 Requests -
          <span class="el_59_abc84ce152ab4b0aed82945ee82893c5 text-blue-400 cursor-pointer hover:underline"
            @click="get_requests">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline w-5 h-5" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
          </span>
        </label>

        <div class="p-2">
          <div v-for="r in last_requests" :key="r.id" class="flex p-2 mb-1 bg-white divide-y divide-gray-200">
            <div class="flex-1">
              <div class="flex p-3 space-x-2">
                <div class="flex-1">
                  {{ r.email }}
                </div>
                <div class="flex-shrink-0 text-right text-sm text-gray-700">
                  {{ r.created }}
                </div>
              </div>

              <div title="click to copy" class="text-sm text-gray-400" @click="copyText(r.payload)">
                {{ r.payload }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="current_tab == 1" class="flex flex-col space-y-2 divide-y divide-gray-300">
        <div class="flex-1">
          <div class="flex p-3 space-x-2">
            <div v-if="data.pageconfig.api.type != 'POST_OUT'" class="el_10_abc84ce152ab4b0aed82945ee82893c5 flex-1">
              <label for="placeholder" class="block text-sm font-bold text-gray-700">
                UPLOAD to S3
              </label>
              <div class="el_11_abc84ce152ab4b0aed82945ee82893c5 mt-1">
                <input id="placeholder" v-model="data.pageconfig.api.s3_bucket" type="text" name="s3_bucket"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>
            <div v-if="data.pageconfig.api.type != 'POST_OUT'" class="el_12_abc84ce152ab4b0aed82945ee82893c5 flex-1">
              <label for="placeholder" class="block text-sm font-bold text-gray-700">
                Signed TTL
              </label>
              <div class="el_13_abc84ce152ab4b0aed82945ee82893c5 mt-1">
                <input id="placeholder" v-model="data.pageconfig.api.s3_file_ttl" type="number" name="TTL in seconds"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>
          </div>
        </div>
        <div v-if="data.pageconfig.api.type != 'POST_OUT'" class="el_14_abc84ce152ab4b0aed82945ee82893c5 flex-1">
          <div class="el_15_abc84ce152ab4b0aed82945ee82893c5 flex p-3 space-x-2">
            <div v-if="data.pageconfig.api.type != 'POST_OUT'" class="el_16_abc84ce152ab4b0aed82945ee82893c5 flex-1">
              <label for="target_worker" class="block text-sm font-bold text-gray-700">
                PUSH API - Target Worker
              </label>
              <div class="el_17_abc84ce152ab4b0aed82945ee82893c5 mt-1">
                <input id="target_worker" v-model="data.pageconfig.api.target_worker" type="text" name="target_worker"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>
            <div class="el_18_abc84ce152ab4b0aed82945ee82893c5 flex-1">
              <label for="filters" class="block text-sm font-bold text-gray-700">
                Default Filters - JSON format just like tabs
              </label>
              <div class="el_19_abc84ce152ab4b0aed82945ee82893c5 mt-1">
                <input id="filters" v-model="data.pageconfig.api.filters" type="text" name="filters"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>
            <div class="el_20_abc84ce152ab4b0aed82945ee82893c5 flex-1">
              <label for="max_rows_returned" class="block text-sm font-bold text-gray-700">
                Max rows returned - <small class="text-gray-600">Default is 500</small>
              </label>
              <div class="el_21_abc84ce152ab4b0aed82945ee82893c5 mt-1">
                <input id="max_rows_returned" v-model="data.pageconfig.api.max_rows_returned" type="text"
                  name="max_rows_returned"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <label class="block text-sm font-bold text-gray-700">
            Firewall Whitelist IPs
          </label>
          <div class="el_17_abc84ce152ab4b0aed82945ee82893c5 mt-1">
            <textarea v-model="data.pageconfig.api.whitelist_ip" type="text" rows="10"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
          </div>
        </div>
      </div>
      <div v-if="current_tab == 5" class="flex flex-col space-y-2">
        <div class="flex-1">
          <div class="uppercase text-gray-600 text-sm">
            title
          </div>
          <input v-model="data.pageconfig.api.doco.title" class="w-full p-2 rounded-md border border-gray-300">
        </div>
        <div class="flex-1">
          <div class="uppercase text-gray-600 text-sm">
            description
          </div>
          <textarea v-model="data.pageconfig.api.doco.description" rows="5"
            class="w-full p-2 rounded-md border border-gray-300" />
        </div>
        <div class="flex-1">
          <div class="flex justify-between items-center p-1">
            <div class="uppercase text-gray-800 font-bold text-sm">
              Attributes
            </div>
            <button class="text-sm bg-gray-200 rounded-md px-2 py-1" @click="copy_attributes">Copy</button>
            <button class="text-sm bg-gray-200 rounded-md px-2 py-1" @click="redo_attributes">Redo</button>
          </div>
          <div class="">
            <textarea class="w-full h-96" v-model="attributes"></textarea>
            <!-- <div v-for="i in data.pageconfig.select" :key="i.col" class="grid grid-cols-12 gap-1">
              <div class="col-span-3">
                {{ i.field }}
              </div>
              <input v-model="i.type"
                class="col-span-1 w-full focus:ring-0 focus:ring-offset-0 rounded-md border border-gray-200">
              <input v-model="i.description"
                class="col-span-8 focus:ring-0 focus:ring-offset-0 w-full rounded-md border border-gray-200">
            </div> -->
          </div>
        </div>
      </div>

      <div v-if="current_tab == 6" class="flex flex-col space-y-2">

        <div class="flex-1">
          <label for="transform_schema" class="block text-sm font-bold text-gray-700">
            Transform Schema V2
          </label>
          <div class="mt-1">
            <textarea v-model="data.pageconfig.api.transform_schema_v2" rows="30"
              class="block w-full rounded-md border border-gray-300 shadow-sm" />
          </div>
        </div>
        <div class="flex-1">
          <label for="transform_schema" class="block text-sm font-bold text-gray-700">
            Transform Schema V1
          </label>
          <div class="mt-1">
            <textarea id="transform_schema" v-model="data.pageconfig.api.transform_schema" name="transform_schema"
              rows="30" class="block w-full rounded-md border border-gray-300 shadow-sm" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Code2FA v-if="get_otp" :sending="sending" :error="error" @submit="save" @cancel="get_otp = false" />
</template>

<script>
import { STORE } from "@/store";
import TgBtn from "@/components/Common/TgBtn";
import Code2FA from "@/components/Common/Code2FA";
import { API, API_EXTERNAL } from "@/api";
import _isArray from "lodash/isArray";
import TgLoader from "@/components/Common/TgLoader";

import _get from "lodash/get";
import _has from "lodash/has";
import _each from "lodash/each";

export default {
  name: "ConfigApi",
  components: {
    TgBtn,
    TgLoader,
    Code2FA,
  },
  props: {
    slug: String,
  },
  emits: { "on:save": null },
  data() {
    return {
      tabs: [
        { name: "Settings", id: 1 },
        { name: "Schema", id: 6 },
        { name: "Permissions", id: 2 },
        { name: "Documentation", id: 5 },
        { name: "Testing", id: 3 },
        { name: "Logs", id: 4 },
      ],
      apikey: API.apikey,
      endpoint: API.get_api_endpoint(this.slug),
      loading: false,
      loading_users: false,
      sending: false,
      attributes: [],
      last_requests: [],
      req_loading: false,
      new_user: null,
      error: null,
      current_tab: 1,
      get_otp: false,
      all_users: null,
      search_text: "",
      users: null,
      data: null,
      filters: null,
      testing: {
        params: {},
      },
      response: null,
    };
  },
  computed: {
    all_users_query() {
      if (this.search_text) {
        let filtered = this.all_users.filter((item) => {
          return this.search_text
            .toLowerCase()
            .split(" ")
            .every((v) => {
              return item.email.toLowerCase().includes(v);
            });
        });
        return filtered;
      } else {
        return this.all_users;
      }
    },
  },
  async mounted() {
    this.getusers();
    this.getallusers();
    this.get_requests();
    let resp = await API.page({ slug: this.slug });

    let data = resp.data.data;

    data.pageconfig.api = _get(data.pageconfig, "api", {});
    if (_isArray(data.pageconfig.api) && data.pageconfig.api.length == 0) data.pageconfig.api = {};

    if (!data.pageconfig.api.doco) {
      data.pageconfig.api.doco = {};
    }

    this.filters = data.pageconfig.filters;

    if (!_has(data.pageconfig.api.doco, 'attributes')) {
      data.pageconfig.api.doco.attributes = [];
      let attributes = []
      _each(data.pageconfig.select, (i) => {
        attributes.push({
          name: i.name,
          field: i.field,
          type: i.type,
          filter: i.filters,
          description: i.description,
        })
      });

      this.attributes = JSON.stringify(attributes, null, 2);
    } else {
      this.attributes = JSON.stringify(data.pageconfig.api.doco.attributes, null, 2);
    }



    this.data = data;
  },
  methods: {

    async redo_attributes() {

      let attributes = []
      _each(this.data.pageconfig.select, (i) => {
        attributes.push({
          name: i.name,
          field: i.field,
          type: i.type,
          filter: i.filters,
          description: i.description,
        })
      });

      this.attributes = JSON.stringify(attributes, null, 2);

    },
    async copy_attributes() {


      let txt = "Here is an attributes object for an api, can you update/add the descriptions for each field and take a guess at the field type and update it? " + this.attributes;

      try {
        await navigator.clipboard.writeText(txt);
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Copied!",
        });
      } catch ($e) { }
    },
    async update_user(user, fld) {
      user.sending = true;
      user[fld] = !user[fld];
      let payload = {};
      payload[fld] = user[fld];
      await API.update("8CE369FC2B2D4316BCE26BEE0EA819A8", user._hashid, payload);
    },
    async copyTextSample() {
      try {
        await navigator.clipboard.writeText(this.testing.sample);
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Sample copied!",
        });
      } catch ($e) { }
    },
    async query_api() {
      this.testing.response_2 = "Please wait...";
      let resp;
      try {
        resp = await API.external_get(this.slug, this.testing.token, this.testing.params);
      } catch (e) {
        this.testing.response_2 = JSON.stringify(e.response, null, 2);
        console.log(e);
      }

      if (resp) {
        this.testing.sample = JSON.stringify(resp.data).replaceAll('"', '\\"');
        this.testing.response_2 = JSON.stringify(resp.data, null, 2);
      }
    },
    async post_out_api() {
      this.testing.response = "Please wait...";
      let resp;
      try {
        resp = await API_EXTERNAL.post_out(this.slug, this.testing.params);
      } catch (e) {
        this.testing.response = JSON.stringify(e.response, null, 2);
        console.log(e);
      }

      if (resp) {
        this.testing.response = JSON.stringify(resp.data, null, 2);
      }
    },
    async get_bearer() {
      this.testing.response_1 = "Please wait...";
      let resp;
      try {
        resp = await API_EXTERNAL.get_token(this.testing.token);
      } catch (e) {
        this.testing.response_1 = JSON.stringify(e.response, null, 2);
        console.log(e);
      }

      if (resp) {
        this.testing.response_1 = JSON.stringify(resp.data, null, 2);
        this.testing.bearer = resp.data.access_token;
      }
    },
    async send_post_in() {
      this.testing.response = "Please wait...";
      let resp;
      try {
        resp = await API_EXTERNAL.post(this.slug, this.testing.token, this.testing.payload);
      } catch (e) {
        this.testing.response = JSON.stringify(e.response, null, 2);
        console.log(e);
      }

      if (resp) {
        this.testing.response = JSON.stringify(resp.data, null, 2);
      }
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Text copied!",
        });
      } catch ($e) { }
    },
    async remove(i) {
      i.css = "opacity-50 bg-red-100";
      await API.del("8CE369FC2B2D4316BCE26BEE0EA819A8", i.id);
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "User Removed!",
      });
      this.getusers();
    },
    async add_user(user) {
      user.hide = true;
      this.loading_users = true;
      await API.insert("8CE369FC2B2D4316BCE26BEE0EA819A8", {
        user_id: user.auth0_id,
        slug: this.slug,
      });
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "User Added!",
      });
      this.getusers();
    },
    async getusers() {
      this.loading_users = true;
      this.users = [];

      let resp = await API.get("8CE369FC2B2D4316BCE26BEE0EA819A8", {
        slug: this.slug,
        _top: 1000,
      });

      if (resp) {
        this.users = resp.data.data;
      }
      this.loading_users = false;
    },
    async get_requests() {
      this.req_loading = true;
      this.last_requests = [];
      let resp = await API.get("71E1A583E4BD4EFC93CD8A1C94839998", {
        slug: this.slug,
        _top: 10,
        orderBy: "created_desc",
      });

      if (resp) {
        this.last_requests = resp.data.data;
        this.req_loading = false;
      }
    },
    async getallusers() {
      this.loading = true;
      let params = {
        _fields: ["name", "lastname", "fullname", "email", "auth0_id", "company"],
        orderBy: "lastname",
      };
      if (this.search_text) {
        params["email"] = `*${this.search_text}*`;
      }
      let resp = await API.get("1940160BCC7462833DC609BD5677FBED", params);

      if (resp) {
        this.all_users = resp.data.data;
        this.loading = false;
      }
    },
    async generate(u) {
      let params = {};

      params.label = this.slug + " / " + u.email;
      params.worker = "create-api-doco";
      params.payload = JSON.stringify({ slug: this.slug, user: u });

      await API.insert_worker_job(params);

      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Api document queued up!",
      });
    },

    save(otp) {
      this.sending = true;

      // let attributes = [];


      // _each(this.data.pageconfig.select, (i) => {
      //   if (i.description == "-") return;
      //   attributes.push({
      //     name: i.name,
      //     field: i.field,
      //     type: i.type,
      //     filter: i.filters,
      //     description: i.description,
      //   });
      // });

      // this.data.pageconfig.api.doco.attributes = attributes;

      this.data.pageconfig.api.doco.attributes = JSON.parse(this.attributes);


      STORE.set(this.slug, this.data, otp)
        .then(() => {
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Page Saved!",
          });
          this.sending = false;
          this.get_otp = false;
          this.$emit("on:save");
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.json_resp {
  font-size: 0.9rem !important;
  min-height: auto;
  padding: 3px;
}
</style>
