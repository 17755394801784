import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueNumerals from "vue-numerals";
import TgDivDown from "@/components/Common/TgDivDown";
import "./assets/tailwind.css";
import TgAlert from "@/components/Common/TgAlert";
import { setupAuth } from "./auth";
import Notifications from "vue3-vt-notifications";
// import './registerServiceWorker'
import Maska from "maska";
import MobileSubmit from "@/components/Mobile/MobileSubmit.vue";
import MobileButtonQty from "@/components/Mobile/MobileButtonQty.vue";
import MobileEmptyButtonQty from "@/components/Mobile/MobileEmptyButtonQty.vue";
import MobileNumberPad from "@/components/Mobile/MobileNumberPad.vue";
import MobileSubmitLastmile from "@/components/Mobile/MobileSubmitLastmile.vue";
import Flight from "@/components/Touchpoint/Schedules/Flight/Fight.vue";
import TgBadge from "@/components/Common/TgBadge";
import TgHelpDot from "@/components/Common/TgHelpDot";
import TgTour from "@/components/Common/TgTour";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const sweet_options = {
  confirmButtonColor: "#3eb9dd",
};

// import VueMobileDetection from 'vue-mobile-detection'

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

let app = createApp(App)
  .use(router)
  .use(VueNumerals)
  .use(Notifications)
  .use(Maska)
  .use(VueSweetalert2, sweet_options);
//.use(VueMobileDetection)
app.component("TgDivDown", TgDivDown); // global registration - can be used anywhere
app.component("TgAlert", TgAlert); // global registration - can be used anywhere

// Mobile components for offline
app.component("MobileButtonQty", MobileButtonQty); // global registration - can be used anywhere
app.component("MobileEmptyButtonQty", MobileEmptyButtonQty); // global registration - can be used anywhere
app.component("MobileSubmit", MobileSubmit); // global registration - can be used anywhere
app.component("MobileNumberPad", MobileNumberPad); // global registration - can be used anywhere
app.component("MobileSubmitLastmile", MobileSubmitLastmile); // global registration - can be used anywhere
app.component("Flight", Flight); // global registration - can be used anywhere
app.component("TgBadge", TgBadge); // global registration - can be used anywhere
app.component("TgHelpDot", TgHelpDot); // global registration - can be used anywhere
app.component("TgTour", TgTour); // global registration - can be used anywhere

app.config.globalProperties.$filters = {
  timeAgo(date) {
    return dayjs(date).fromNow();
  },
};

app.directive("draggable", {
  mounted: function (el, binding) {
    if (binding.value.position) {
      el.style.position = binding.value.position;
    } else {
      el.style.position = "absolute";
    }

    let final = {
      key: binding.value.key,
    };
    var startX, startY, initialMouseX, initialMouseY;

    function round(x, num) {
      let half = num / 2;
      return x % num >= half ? parseInt(x / num) * num + num : parseInt(x / num) * num;
    }

    function mousemove(e) {
      let grid = binding.value.grid;
      let gutter = binding.value.gutter;
      let max_dx = document.getElementById(binding.value.parent).offsetWidth;
      let max_dy = document.getElementById(binding.value.parent).offsetHeight;

      var dx = e.clientX - initialMouseX;
      var dy = e.clientY - initialMouseY;
      dx = round(dx, grid);
      dy = round(dy, grid);
      let left = startX + dx;
      let top = startY + dy;

      if (top < 0) {
        top = 0;
      }

      if (left < 0) {
        left = 0;
      }

      let right = left + binding.value.width;
      if (right > max_dx) left = max_dx - binding.value.width - gutter;

      let bottom = top + binding.value.height;
      if (bottom > max_dy) top = max_dy - binding.value.height;

      final.top = top;
      final.left = left;
      final.rect = document.getElementById(binding.value.parent).getBoundingClientRect();

      el.style.top = top + "px";
      el.style.left = left + "px";

      return false;
    }

    function mousemove_anywhere(e) {
      var dx = e.clientX - initialMouseX;
      var dy = e.clientY - initialMouseY;
      let top = startY + dy;
      let left = startX + dx;
      final.top = top;
      final.left = left;
      el.style.top = top + "px";
      el.style.left = left + "px";

      return false;
    }

    function mousemove_free(e) {
      let grid = binding.value.grid;
      var dx = e.clientX - initialMouseX;
      var dy = e.clientY - initialMouseY;
      dx = round(dx, grid);
      dy = round(dy, grid);
      let top = startY + dy;
      let left = startX + dx;
      final.top = top;
      final.left = left;
      el.style.top = top + "px";
      el.style.left = left + "px";

      return false;
    }

    function mouseup() {
      const vm = binding.instance;
      vm.afterMove(final);

      if (binding.value.anywhere) {
        document.removeEventListener("mousemove", mousemove_anywhere);
      } else {
        if (binding.value.free) {
          document.removeEventListener("mousemove", mousemove_free);
        } else {
          document.removeEventListener("mousemove", mousemove);
        }
      }

      document.removeEventListener("mouseup", mouseup);
    }

    el.addEventListener("mousedown", function (e) {
      let class_names = e.srcElement.classList.value;

      if (class_names.includes("handle") && binding.value.enabled) {
        startX = el.offsetLeft;
        startY = el.offsetTop;
        initialMouseX = e.clientX;
        initialMouseY = e.clientY;
        if (binding.value.anywhere) {
          document.addEventListener("mousemove", mousemove_anywhere);
        } else {
          if (binding.value.free) {
            document.addEventListener("mousemove", mousemove_free);
          } else {
            document.addEventListener("mousemove", mousemove);
          }
        }
        document.addEventListener("mouseup", mouseup);
      }
      return false;
    });
  },
});

app.directive("resize", {
  mounted: function (el, binding) {
    el.style.position = "absolute";
    let final = {
      key: binding.value.key,
    };
    let x = 0;
    let w = 0;

    function mousemove(e) {
      let dx = e.clientX - x;
      let width = w + dx;
      final.width = width;
      el.style.width = `${width}px`;

      return false;
    }

    function mouseup() {
      const vm = binding.instance;
      vm.afterResize(final);
      document.removeEventListener("mousemove", mousemove);
      document.removeEventListener("mouseup", mouseup);
    }

    el.addEventListener("mousedown", function (e) {
      let class_names = e.srcElement.classList.value;
      if (class_names.includes("resize")) {
        // Get the current mouse position
        x = e.clientX;
        const styles = window.getComputedStyle(el);
        w = parseInt(styles.width, 10);

        document.addEventListener("mousemove", mousemove);
        document.addEventListener("mouseup", mouseup);
      }
      return false;
    });
  },
});

setupAuth().then((auth) => {
  app.use(auth).mount("#app");
});
