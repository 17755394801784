<template>
  <div v-for="col in cols" :key="col.field" :class="col.row_css" class="el_1_42222ae98dabe9fe9c3e212623c5a7e7 flex items-center px-1 border-b border-gray-200 hover:bg-gray-100">
    <div class="el_2_42222ae98dabe9fe9c3e212623c5a7e7 flex-1 w-44 text-sm text-gray-600 uppercase">
      {{ col.name }}
    </div>
    <div class="el_3_42222ae98dabe9fe9c3e212623c5a7e7 text-lg" :class="col.css">
      {{ val(col) }} &nbsp;
      <span v-if="$auth.isAdmin()" class="el_4_42222ae98dabe9fe9c3e212623c5a7e7">
        <span v-if="col.field == 'model_refreshed'" class="el_5_42222ae98dabe9fe9c3e212623c5a7e7">
          <!-- <span v-if="model_running == 0" class="el_6_42222ae98dabe9fe9c3e212623c5a7e7 text-sm cursor-pointer hover:underline text-primary-400" @click="refresh_flight">
            Refresh
          </span>
          <span v-else class="el_7_42222ae98dabe9fe9c3e212623c5a7e7 text-sm text-green-600">
            Running...
          </span> -->
        </span>
        <span v-if="col.field == 'pax_updated'" class="el_8_42222ae98dabe9fe9c3e212623c5a7e7">
          <!-- <span v-if="pax_running == 0" class="el_9_42222ae98dabe9fe9c3e212623c5a7e7 text-sm cursor-pointer hover:underline text-primary-400" @click="refresh_pax">
            Refresh
          </span>
          <span v-else class="el_10_42222ae98dabe9fe9c3e212623c5a7e7 text-sm text-green-600">
            Running...
          </span> -->
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import _has from "lodash/has";
import { API } from "@/api";
export default {
  props: {
    cols: Array,
    item: Object,
  },
  data() {
    return {
      model_running: this.item.force_model_refresh,
      pax_running: this.item.force_pax_refresh,
    };
  },
  computed: {
    val() {
      return (col) => {
        let val = this.item[col.field];

        if (_has(col, "type") && col.type == "datetime") {
          val = dayjs(val).format("MMM DD HH:mm");
          if (val == "Invalid Date") val = "-";
        }
        if (_has(col, "type") && col.type == "time") {
          val = dayjs(val).format("HH:mm");
          if (val == "Invalid Date") val = "-";
        }

        return val;
      };
    },
  },
  methods: {
    async refresh_flight() {
      this.model_running = 1;
      await API.update("4C2CF7AD2EFE4146A51996525E878E7D", this.item.id, {
        force_model_refresh: 1,
      });
    },
    async refresh_pax() {
      this.pax_running = 1;
      await API.update("4C2CF7AD2EFE4146A51996525E878E7D", this.item.id, {
        force_pax_refresh: 1,
      });
    },
  },
};
</script>

<style>
</style>