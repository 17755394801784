<template>
  <label :for="item.field" @click="$emit('sticky')" class="relative block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_da1a5dd607a80d73fb51dc5b84c1fa12 inline mr-1 text-sm text-red-400">*</span>
    {{ item.name }}
    <div class="el_2_da1a5dd607a80d73fb51dc5b84c1fa12 absolute top-0 right-0">
      <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />
      <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
        @click="show_help = !show_help" />
    </div>
  </label>
  <div class="el_3_da1a5dd607a80d73fb51dc5b84c1fa12 relative flex mt-1 rounded-md shadow-sm">
    <select :id="item.field" :value="modelValue" tabindex="0" :name="item.field"
      :disabled="item.loading || item.disabled || item.lock_edits"
      :readonly="item.sticky"
      class="block w-full py-2 pl-3 pr-10 read-only:bg-yellow-50 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
      :class="error ? 'border-red-500' : ''" @input="$emit('update:modelValue', $event.target.value)">
      <ChevronDownIcon :class="open ? 'transform rotate-180' : ''"
        class="absolute w-5 h-5 font-extrabold text-gray-600 cursor-pointer right-3 top-3" />
      <option />
      <option v-for="i in options" :key="i" :value="i">
        {{ i }}
      </option>
    </select>
  </div>
  <div v-if="show_help && item.popover"
    class="el_4_da1a5dd607a80d73fb51dc5b84c1fa12 absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
    @click="show_help = false">
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import { InformationCircleIcon, KeyIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default {
  components: {
    InformationCircleIcon,
    KeyIcon,
    ChevronDownIcon,
  },
  props: {
    item: Object,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null },
  data() {
    return {
      options: JSON.parse(this.item.combo_array),
      show_help: false,
      open: false,
    };
  },
  created() { },
};
</script>
