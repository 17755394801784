<template>
  <div class="el_1_536489377b344ff25cbd389e76066c78 p-3 mb-2 bg-gray-100 cursor-pointer" :class="(open ? active : '')">
    <div class="el_2_536489377b344ff25cbd389e76066c78" @click="toggle">
      <ChevronRightIcon :class="open ? 'transform rotate-90' : ''" class="inline-block w-5 h-5 mr-2 text-gray-500" />

      <slot name="header" />
    </div>
    <div v-if="open" class="el_3_536489377b344ff25cbd389e76066c78 p-3 mt-2 bg-white rounded-lg">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/outline";

export default {
  name: "TgDivDown",
  components: {
    ChevronRightIcon,
  },
  props: {
    active: {
      type: String,
      default: "bg-primary-200",
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>

<style>
</style>