<template>
  <div class="el_1_5584a7d524ca5447d2d6593ed25a7e7f grid h-screen place-items-center bg-primary-400">
    <div class="el_2_5584a7d524ca5447d2d6593ed25a7e7f px-10 pt-20 pb-10 bg-white rounded-md shadow-2xl w-112">
      <img class="w-auto mb-10"
        src="https://res.cloudinary.com/tplive/image/upload/v1532346884/touchpoint/rgb_logos_png/Touchpoint_RGB_pos_land_sml.png"
        alt="Touchpoint">


      <p class="mt-5 mb-10 text-2xl">
        Enter your Touchpoint Code:
      </p>


      <div v-if="$route.query.msg == 1"
        class="text-red-400 text-lg font-bold border border-red-200 bg-red-50 rounded-md p-2 my-2">
        Session Expired
      </div>


      <div class="el_3_5584a7d524ca5447d2d6593ed25a7e7f text-left">
        <label for="code" class="block text-lg font-medium text-gray-700">Client Code</label>
        <div class="el_4_5584a7d524ca5447d2d6593ed25a7e7f mt-1">
          <input id="code" v-model="form.code" type="search" name="code" :disabled="sending"
            class="block w-full py-3 text-lg border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
            placeholder="" @keyup.enter="submit">
        </div>
        <div v-if="error" class="el_5_5584a7d524ca5447d2d6593ed25a7e7f mt-1 text-red-400">
          {{ error }}
        </div>
      </div>

      <div class="el_6_5584a7d524ca5447d2d6593ed25a7e7f mt-10 text-center">
        <div color="primary" :disabled="sending" class="el_7_5584a7d524ca5447d2d6593ed25a7e7f 
        justify-center w-full p-5 text-white uppercase rounded-md cursor-pointer bg-primary-300
         hover:bg-primary-700 disabled:bg-gray-200" @click="submit">
          <span v-if="!sending" class="el_8_5584a7d524ca5447d2d6593ed25a7e7f">Continue</span>
          <span v-if="sending" class="el_9_5584a7d524ca5447d2d6593ed25a7e7f">Please Wait...</span>
        </div>
      </div>

      <div class="el_10_5584a7d524ca5447d2d6593ed25a7e7f mt-20 text-gray-400">
        v{{ version }}
      </div>
    </div>
  </div>
</template>

<script>
import authConfig from "@/auth_config.json";
import _has from "lodash/has";
import { API } from "@/api";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      sending: false,
      error: false,
      version: process.env.VUE_APP_VERSION,
      form: {
        code: null,
      },
    };
  },
  created() {
    this.sending = true;

    if (_has(this.$route.query, "code")) {
      this.$router.push({
        name: "welcome",
        params: { client: this.$route.query.client },
      });
    }
    if (_has(this.$route.query, "c")) {
      this.form.code = this.$route.query.c;
      this.submit();
    }
    this.sending = false;
  },
  methods: {
    submit() {
      this.error = false;
      let code = this.form.code.toLowerCase();
      if (!_has(authConfig.client, code)) {
        this.error = "Invalid client code";
      } else {
        API.logout_msg = null;
        this.sending = true;
        this.$router.push({
          name: "welcome",
          params: { client: code },
        });
      }
    },
  },
};
</script>

<style></style>