<template>
  <div class="el_1_24cb52f02e835a5d81755537b44dc8a2 flex items-center p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100">
    <div class="el_2_24cb52f02e835a5d81755537b44dc8a2 w-6/12 pl-2">
      <div class="el_3_24cb52f02e835a5d81755537b44dc8a2 inline-flex items-center ">
        <MenuIcon class="w-4 h-4 mr-3 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        <SortAscendingIcon :class="data.direction ? 'text-green-400' : 'text-gray-300'" class="w-4 h-4 mr-2 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="setSortDirection(true)" />
        <SortDescendingIcon :class="!data.direction ? 'text-green-400' : 'text-gray-300'" class="w-4 h-4 mr-2 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="setSortDirection(false)" />

        {{ data.name }}
      </div>
    </div>
    <div class="el_4_24cb52f02e835a5d81755537b44dc8a2 w-6/12 text-right">
      <tg-badge color="danger" @click="remove()">
        X
      </tg-badge>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { MenuIcon } from "@heroicons/vue/solid";
import { SortAscendingIcon } from "@heroicons/vue/solid";
import { SortDescendingIcon } from "@heroicons/vue/solid";
import _has from "lodash/has";

export default {
  name: "SortItem",
  components: {
    TgBadge,
    MenuIcon,
    SortAscendingIcon,
    SortDescendingIcon,
  },
  props: {
    item: Object,
  },
  emits: ["remove-item"],
  data() {
    let item = this.item;

    if (!_has(this.item, "direction")) item.direction = false;
    return {
      data: item,
    };
  },
  methods: {
    setSortDirection(state) {
      this.data.direction = state;
    },
    remove() {
      this.$emit("remove-item", { fld: "orderby", item: this.data });
    },
  },
};
</script>

<style>
</style>