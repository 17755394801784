<template>
  <label :for="item.field" @click="$emit('sticky')" class="relative cursor-pointer hover:font-bold block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_ff2a24a7cdd683630e9dd891c4ed0f08 inline mr-1 text-sm text-red-400">*</span>
    <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
      @click="show_help = !show_help" />
    {{ item.name }} 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" v-if="item.sticky" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline text-red-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

    <div class="el_2_ff2a24a7cdd683630e9dd891c4ed0f08 absolute top-0 right-0">
      <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />

    </div>
  </label>
  <div class="el_3_ff2a24a7cdd683630e9dd891c4ed0f08 flex mt-1 rounded-md shadow-sm">
    <span v-if="item.prefix"
      class="el_4_ff2a24a7cdd683630e9dd891c4ed0f08 inline-flex items-center px-3 font-bold text-gray-500 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
      {{ item.prefix }}
    </span>
    <input v-maska="item.maska" autocomplete="off" role="presentation" tabindex="0" :value="modelValue" type="text"
      :disabled="item.loading || item.disabled  || item.lock_edits" :class="error ? 'border-red-500' : ''"
      :readonly="item.sticky"
      class="flex-1 block w-full read-only:bg-yellow-50 min-w-0 border-gray-200 rounded-md focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
      @blur="onBlur" @input="$emit('update:modelValue', $event.target.value)">
    <span v-if="item.suffix"
      class="el_5_ff2a24a7cdd683630e9dd891c4ed0f08 inline-flex items-center px-3 font-bold text-gray-500 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
      {{ item.suffix }}
    </span>
  </div>
  <div v-if="show_help && item.popover"
    class="el_6_ff2a24a7cdd683630e9dd891c4ed0f08 absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
    @click="show_help = false">
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import { InformationCircleIcon, KeyIcon } from "@heroicons/vue/outline";
import { API } from "@/api";
export default {
  components: {
    InformationCircleIcon,
    KeyIcon,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
    };
  },
  methods: {
    onBlur() {
      if (this.item.unique) {
        let payload = {
          _top: 1,
        };
        payload[this.item.field] = this.modelValue;

        API.get(this.slug, payload)
          .then((resp) => {
            if (resp.data.data.length > 0) {
              this.$emit("update:error", {
                error: "This value already exists",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
