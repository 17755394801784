<template>
  <div v-if="file.skinny" class="el_1_4e1b44b5a4115078f2291ca10bf2f276 flex text-left">
    <div v-if="!sending" class="el_2_4e1b44b5a4115078f2291ca10bf2f276 space-y-1 ">
      <div class="el_3_4e1b44b5a4115078f2291ca10bf2f276 text-gray-400 cursor-pointer hover:text-primary-400" @click="get_secure_download_url">
        <div class="el_4_4e1b44b5a4115078f2291ca10bf2f276 text-center text-primary-400 hover:underline">
          {{ file.name }}
        </div>
      </div>
    </div>
    <div v-else class="el_5_4e1b44b5a4115078f2291ca10bf2f276 text-center">
      <div class="el_6_4e1b44b5a4115078f2291ca10bf2f276 text-gray-500">
        {{ status }}
      </div>
    </div>
  </div>
  <div v-else class="el_7_4e1b44b5a4115078f2291ca10bf2f276 flex justify-center max-w-lg px-6 pt-5 pb-6">
    <div v-if="!sending" class="el_8_4e1b44b5a4115078f2291ca10bf2f276 space-y-1 text-center">
      <div class="el_9_4e1b44b5a4115078f2291ca10bf2f276 text-gray-400 cursor-pointer hover:text-primary-400" @click="get_secure_download_url">
        <DocumentTextIcon class="w-12 h-12 mx-auto" />
        <div class="el_10_4e1b44b5a4115078f2291ca10bf2f276 text-center text-primary-400 hover:underline">
          {{ file.name }}
        </div>
      </div>
    </div>
    <div v-else class="el_11_4e1b44b5a4115078f2291ca10bf2f276 text-center">
      <TgLoader />
      <div class="el_12_4e1b44b5a4115078f2291ca10bf2f276 text-gray-500">
        {{ status }}
      </div>
    </div>
  </div>
</template>

<script>
import TgLoader from "@/components/Common/TgLoader";
import { API } from "@/api";
import { DocumentTextIcon } from "@heroicons/vue/outline";

export default {
  components: {
    TgLoader,
    DocumentTextIcon,
  },
  props: {
    file: Object,
    slug: String,
  },
  emits: { "upload:success": null },
  data: function () {
    return {
      status: "",
      sending: false,
    };
  },
  methods: {
    async get_secure_download_url() {
      this.sending = true;

      this.status = "Getting secure download location...";

      let resp = await API.get_secure_upload(
        this.slug,
        this.file.name,
        this.file.folder
      ).catch((e) => {
        console.log(e);
      });

      if (resp) {
        this.sending = false;
        let url = resp.data.data;
        let win = window.open(url, "_blank");
        win.focus();
      }
    },
  },
};
</script>

<style>
</style>