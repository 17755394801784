<template>
  <div class="el_1_b36390e190596e2e300a664a3345e171 flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <div v-if="!sending" class="el_2_b36390e190596e2e300a664a3345e171 space-y-1 text-center">
      <svg class="w-12 h-12 mx-auto text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div class="el_3_b36390e190596e2e300a664a3345e171 flex text-sm text-gray-600">
        <label for="file-upload" class="relative font-medium text-indigo-600 bg-white rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          <input id="assetsFieldHandle" ref="file" type="file" multiple name="fields[assetsFieldHandle][]" class="absolute w-px h-px overflow-hidden opacity-0" accept=".pdf,.jpg,.jpeg,.png" @change="onChange">
        </label>
        Drag and drop file here
      </div>
      <p class="text-xs text-gray-500">
        max size 50MB
      </p>
    </div>
    <div v-else class="el_4_b36390e190596e2e300a664a3345e171 text-center">
      <TgLoader />
      <div class="el_5_b36390e190596e2e300a664a3345e171 text-gray-500">
        {{ status }}
      </div>
    </div>
  </div>
</template>

<script>
import TgLoader from "@/components/Common/TgLoader";
import axios from "axios";
import { API } from "@/api";
import md5 from "blueimp-md5";

export default {
  components: {
    TgLoader,
  },
  props: {
    slug: String,
  },
  emits: { "upload:success": null },
  data: function () {
    return {
      filelist: [],
      status: "",
      folder: null,
      secure_upload_url: null,
      sending: false,
    };
  },
  methods: {
    async onChange() {
      this.filelist = [...this.$refs.file.files];
      this.sending = true;
      this.get_secure_upload_url(this.filelist[0]);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    async get_secure_upload_url(file) {
      this.status = "Getting secure upload location...";
      this.folder = `DOCUMENTS/${this.slug}/${md5(new Date())}`;

      let params = {
        action: "put",
        folder: this.folder,
        pub: 0,
        filetype: file.type,
      };

      let resp = await API.get_secure_upload_url(
        this.slug,
        file.name,
        params
      ).catch((e) => {
        console.log(e);
      });

      if (resp) {
        this.secure_upload_url = resp.data.data.url;
        this.upload(file);
      }
    },
    async upload(file) {
      this.status = "Uploading...";
      console.log("file = ", file);

      var options = {
        headers: {
          "Content-Type": file.type,
        },
      };

      let payload = JSON.stringify({
        folder: this.folder,
        name: file.name,
        size: file.size,
        type: file.type,
      });

      let resp = await axios
        .put(this.secure_upload_url, file, options)
        .catch((e) => {
          console.log(resp);
        });

      if (resp) {
        console.log(resp);
        this.sending = false;
        this.$emit("upload:success", payload);
      }
    },
  },
};
</script>

<style>
</style>