<template>
  <div class="fixed inset-0 z-50">
    <div class="relative text-left">
      <div class="p-1 bg-gray-400 flex space-x-2 items-center">
        <div class="flex-1 text-white text-left flex items-center space-x-3">
          <div class="px-1 bg-gray-500 uppercase rounded-md text-sm">
            key
          </div>
          <div>{{ slug }}</div>
        </div>
        <div class="justify-end flex-shrink-0 flex space-x-2">
          <button class="py-1 px-4 bg-gray-600 rounded-lg text-white disabled:opacity-50" @click="get">
            Refresh
          </button>
          <button class="py-1 px-4 bg-primary-400 rounded-lg text-white disabled:opacity-50" @click="get_otp = true">
            Save
          </button>
          <button class="py-1 px-4 bg-gray-600 rounded-lg text-white disabled:opacity-50" @click="close">
            Close
          </button>
        </div>
      </div>
      <div v-if="error" class="p-2 text-red-500 bg-red-100">
        {{ error }}
      </div>
      <codemirror v-model="json_str" :style="{height: '100vh'}" :autofocus="true" :indent-with-tab="false" :tab-size="2" :extensions="extensions" @blur="validate" />
    </div>
  </div>

  <Code2FA
    v-if="get_otp"
    :sending="sending"
    :error="error"
    @submit="save"
    @cancel="get_otp = false"
  />
</template>

<script>
import { API } from "@/api";
import { UTILS } from "@/utils";
import { Codemirror } from "vue-codemirror";
import { html } from "@codemirror/lang-html";
import Code2FA from "@/components/Common/Code2FA";
import { oneDark } from "@codemirror/theme-one-dark";
export default {
  components: {
    Codemirror,
    Code2FA,
  },
  props: {
    slug: String,
  },
  emits: ["json-close"],
  data() {
    return {
      error: null,
      json_str: "",
      get_otp: false,
      extensions: [html(), oneDark],
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async save(otp) {
      let page = this.validate();
      if (page === false) {
        this.$notify({
          group: "toast",
          type: "danger",
          title: "Alert",
          text: "The JSON has errors!",
        });
        return;
      }
      await API.put_page(UTILS._clone(page),otp);
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Page Saved!",
      });
      this.close();
    },
    close() {
      this.$emit("json-close");
    },
    validate() {
      this.error = null;
      try {
        let json = JSON.parse(this.json_str);
        return json;
      } catch (e) {
        this.error = "JSON code has errors";
        return false;
      }
    },
    async get() {
      let resp = await API.page({ slug: this.slug });
      this.json_str = JSON.stringify(resp.data.data, null, 2);
    },
  },
};
</script>

<style>
</style>