<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog as="div" auto-reopen="true" class="fixed inset-0 z-40 overflow-y-auto" @close="close">
      <div
        class="el_1_bcf5e8fcad35a03b7692269a66f3eebe flex items-end justify-center min-h-screen px-4 pt-4 pb-20 ml-2 mr-2">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="el_2_bcf5e8fcad35a03b7692269a66f3eebe hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="el_3_bcf5e8fcad35a03b7692269a66f3eebe fixed inset-y-0 z-20 flex flex-col bg-white max-w-full"
            :class="size">
            <div
              class="el_4_bcf5e8fcad35a03b7692269a66f3eebe flex items-center justify-between p-4 shadow bg-primary-700">
              <div class="el_5_bcf5e8fcad35a03b7692269a66f3eebe">
                <DialogTitle class="text-lg font-medium text-white">
                  <slot name="header" />
                </DialogTitle>
                <p class="mt-1 text-sm text-white">
                  <slot name="subtitle" />
                </p>
              </div>
              <div class="flex-shrink-0 flex space-x-3 items-center">
                <div id="modal-bar-top-right" class="el_5_dfd659a4d1a28d34e55e3f82c282feaa" />
                <div>
                  <button ref="closeButtonRef"
                    class="p-1 text-white rounded-full hover:text-primary-150 focus:outline-none" @click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" title="Close Panel" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="el_6_bcf5e8fcad35a03b7692269a66f3eebe flex flex-col flex-1">
              <div class="el_7_bcf5e8fcad35a03b7692269a66f3eebe relative flex-1">
                <div id="modal_content" ref="modal_content"
                  class="el_8_bcf5e8fcad35a03b7692269a66f3eebe absolute inset-0 overflow-y-scroll bg-white">

                  <slot name="content" />
                </div>
              </div>
              <div :id="footer_id"
                class="el_9_bcf5e8fcad35a03b7692269a66f3eebe flex items-center justify-between px-3 py-4 text-right bg-gray-200 border-t border-gray-300">
                <button type="button"
                  class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  @click="close">
                  Cancel
                </button>
                <slot name="footer" />
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
// import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    // XIcon,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    size: String,
    flex: Boolean,
    open: Boolean,
  },
  emits: { "modal-close": null },
  data() {
    return {
      footer_id: this.id + "_modal_submit_bar",
    };
  },
  methods: {
    close() {
      this.$emit("modal-close");
    },
  },
};
</script>

<style scoped></style>
