<template>
  <div class="py-1">
    <div v-if="response">
      <textarea v-model="response" :disabled="working"
        class="p-2 border h-72 w-full rounded-md border-primary-100 bg-primary-100 disabled:bg-gray-200" />
    </div>
    <div class="flex justify-between items-center">
      <div class="flex space-x-2 items-center">
        <div class="font-bold">
          Tone:
        </div>
        <button v-for="t in tones" :key="t" class="text-xs py-1 px-2 uppercase"
          :class="tone == t ? 'bg-primary-500 text-white' : 'bg-gray-300'" @click="tone = t">
          {{ t }}
        </button>
        <div class="font-bold">
          Output:
        </div>
        <button v-for="t in outputs" :key="t" class="text-xs py-1 px-2 uppercase"
          :class="output == t ? 'bg-primary-500 text-white' : 'bg-gray-300'" @click="output = t">
          {{ t }}
        </button>
        <div class="font-bold">
          Transform:
        </div>
        <button v-for="t in lengths" :key="t" class="text-xs py-1 px-2 uppercase"
          :class="length == t ? 'bg-primary-500 text-white' : 'bg-gray-300'" @click="length = t">
          {{ t }}
        </button>
        <button
          class="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-400 disabled:cursor-wait"
          :disabled="working" @click="ask()">
          Ask OpenAI
        </button>
      </div>
      <button v-if="response"
        class="bg-gray-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-400 disabled:cursor-wait"
        @click="accept()">
        Accept
      </button>
    </div>
  </div>
</template>

<script>
import { API } from "@/api";
export default {
  props: {
    content: String,
  },
  data() {
    return {
      outputs: ['text', 'html', 'markdown'],
      output: 'text',
      tones: ['professional', 'simple', 'relaxed'],
      tone: 'professional',
      lengths: ['condense', 'expand', 'simplify'],
      length: 'elaborate',
      working: false,
      response: null,
    };
  },
  methods: {
    async ask() {
      this.working = true
      let output = "";
      if (this.output == 'html')
        output = `Convert response to ${this.output} and only return the code within the body tags`;

      if (this.output == 'markdown')
        output = `Convert response to ${this.output}`;

      let prompt = `${this.length} the following text using a more ${this.tone} tone. ${output}. Here is the text: "${this.content}"`;
      const response = await API.openai({
        model: "gpt-3.5-turbo",
        prompt: prompt,
      });
      this.response = response.data.data
      this.working = false
    },
    async accept() {
      this.$emit("accept", this.response);
      this.response = null;
    },
  },
};
</script>

<style></style>