<template>
  <div class="el_1_aab47c1b5b3996f11f131b1cc7c9d168 flex space-x-4">
    <div class="el_2_aab47c1b5b3996f11f131b1cc7c9d168 flex-shrink-0 w-36">
      <label class="relative block text-sm font-medium uppercase text-primary-800"> Date </label>
      <select
        v-model="form.departuredate"
        tabindex="0"
        :disabled="form.loading_departuredate || item.disabled || item.lock_edits"
        class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
        :class="error ? 'border-red-500' : ''"
        @change="getDeparturePorts"
      >
        <option />
        <option v-for="i in combos.departuredates" :key="i" :value="i.departuredate">
          {{ i.departuredate }}
        </option>
      </select>
    </div>
    <div class="el_3_aab47c1b5b3996f11f131b1cc7c9d168 flex-shrink-0 w-28">
      <label class="relative block text-sm font-medium uppercase text-primary-800"> Port </label>
      <select
        v-model="form.departureport"
        tabindex="0"
        :disabled="form.loading_departureport || item.disabled || item.lock_edits"
        class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
        :class="error ? 'border-red-500' : ''"
        @change="getFlights"
      >
        <option />
        <option v-for="i in combos.departureports" :key="i" :value="i.departureport">
          {{ i.departureport }}
        </option>
      </select>
    </div>
    <div class="el_4_aab47c1b5b3996f11f131b1cc7c9d168 flex-1">
      <label
        :for="item.field"
        class="relative block text-sm font-medium uppercase text-primary-800"
      >
        <span
          v-if="item.required"
          class="el_5_aab47c1b5b3996f11f131b1cc7c9d168 inline mr-1 text-sm text-red-400"
        >*</span>
        {{ item.name }}
        <div class="el_6_aab47c1b5b3996f11f131b1cc7c9d168 absolute top-0 right-0">
          <KeyIcon v-if="item.unique" class="inline w-4 h-4 ml-2 mr-1 text-red-500" />
          <InformationCircleIcon
            v-if="item.popover"
            class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
            @click="show_help = !show_help"
          />
        </div>
      </label>
      <div class="el_7_aab47c1b5b3996f11f131b1cc7c9d168 flex rounded-md shadow-sm">
        <select
          :id="item.field"
          :value="modelValue"
          tabindex="0"
          :name="item.field"
          :disabled="form.loading_flrkey || item.disabled || item.lock_edits"
          class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-200"
          :class="error ? 'border-red-500' : ''"
          @input="$emit('update:modelValue', $event.target.value)"
        >
          <option />
          <option v-for="i in combos.flights" :key="i.flrkey" :value="i.flrkey">
            {{ i.flrkey }} ({{ i.operationalstatus }})
          </option>
        </select>
      </div>
      <div
        v-if="show_help && item.popover"
        class="el_8_aab47c1b5b3996f11f131b1cc7c9d168 absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
        @click="show_help = false"
      >
        <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
      </div>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon, KeyIcon } from "@heroicons/vue/outline";
import { API } from "@/api";
import dayjs from "dayjs";
import _get from "lodash/get";

export default {
  components: {
    InformationCircleIcon,
    KeyIcon,
  },
  props: {
    item: Object,
    error: String,
    curr: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null },
  data() {
    return {
      show_help: false,
      combos: {},
      form: {
        loading_departuredate: true,
        loading_departureport: true,
        loading_flrkey: true,
      },
    };
  },
  created() {
    this.combos.flights = [{ flrkey: this.curr }];
    this.getDepartureDates();
  },
  methods: {
    async getDepartureDates() {
      this.form.loading_departuredate = true;
      let dt_min = _get(this.item, "dt_min", -7);
      let dt_max = _get(this.item, "dt_max", 7);
      let resp = await API.get("4C2CF7AD2EFE4146A51996525E878E7D", {
        std_flight: 1,
        _distinct: 1,
        orderBy: "departuredate_desc",
        departuredate:
          dayjs().subtract(dt_min, "days").format("YYYY-MM-DD") +
          " to " +
          dayjs().add(dt_max, "days").format("YYYY-MM-DD"),
        _fields: ["departuredate"],
      });

      if (resp) {
        this.combos.departuredates = resp.data.data;
        this.form.loading_departuredate = false;
      }
    },
    async getDeparturePorts() {
      this.form.loading_departureport = true;
      let resp = await API.get("4C2CF7AD2EFE4146A51996525E878E7D", {
        departuredate: this.form.departuredate,
        std_flight: 1,
        _distinct: 1,
        orderBy: "departureport",
        _fields: ["departureport"],
      });

      if (resp) {
        this.combos.departureports = resp.data.data;
        this.form.loading_departureport = false;
      }
    },
    async getFlights() {
      this.form.loading_flrkey = true;
      let resp = await API.get("4C2CF7AD2EFE4146A51996525E878E7D", {
        departuredate: this.form.departuredate,
        departureport: this.form.departureport,
        std_flight: 1,
        _distinct: 1,
        _fields: ["flrkey", "departureport", "operationalstatus", "flightnumber"],
        orderBy: "flightnumber",
      });

      if (resp) {
        this.combos.flights = resp.data.data;
        this.form.loading_flrkey = false;
      }
    },
  },
};
</script>
