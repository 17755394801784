<template>
  <div class="grid h-screen place-items-center fixed inset-0 bg-black/60 z-50">
    <div class="px-16 py-20 bg-white rounded-lg shadow-2xl w-[400px]">
      <div class="flex flex-col space-y-7 text-center">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-28 h-28 mx-auto text-gray-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </svg>
        </div>
        <div class="text-3xl font-medium font-bold">
          Verify Your Identity
        </div>
        <div class="text-lg">
          Check your preferred one-time password application for a code.
        </div>
        <div v-if="error" class="text-red-400">
          {{ error }}
        </div>
        <div>
          <input
            v-model="code_2fa"
            :disabled="sending"
            placeholder="Enter your one time code"
            class="p-5 text-2xl w-full text-center bg-white border rounded-lg"
            @keyup.enter="submit"
          >
        </div>

        <button
          v-if="!sending"
          type="button"
          :disabled="!code_2fa"
          class="disabled:opacity-20 text-lg items-center text-center justify-center py-5 font-medium text-white bg-primary-500 border border-transparent rounded-md hover:bg-primary-700 focus:outline-none"
          @click="submit"
        >
          Continue
        </button>
        <div
          v-if="!sending"
          class="text-gray-400 hover:text-primary-400 cursor-pointer"
          @click="cancel"
        >
          Cancel
        </div>

        <button
          v-if="sending"
          type="button"
          disabled
          class="inline-flex text-lg items-center text-center justify-center py-5 font-medium text-gray-500 bg-gray-200 border border-transparent rounded-md focus:outline-none"
        >
          Verifying...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sending: Boolean,
    error: String,
  },
emits: ['submit', 'cancel'],
  data() {
    return {
      code_2fa: "",
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.code_2fa);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
