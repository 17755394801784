<!-- eslint-disable vue/no-v-html -->
<template>
  <TgJsonPageEditor v-if="json_slug" :slug="json_slug" @json-close="json_slug = null" />
  <tg-toolbar-item v-if="!inprogress" class="el_1_8bb190ce6d39ebe23f259541d225e335 text-left relative">
    <tg-btn color="gray" @click="show_tours = !show_tours">
      Guided Tours
    </tg-btn>
    <div v-if="show_tours"
      class="el_2_8bb190ce6d39ebe23f259541d225e335 w-112 absolute bg-white top-10 flex flex-col divide-y divide-gray-100 border-2 border-gray-400 shadow-lg rounded-lg">
      <div class="flex justify-between items-center p-1 bg-gray-400 text-white">
        <div>Available Tours</div>
        <div v-if="isTpAdmin" class="flex space-x-3 cursor-pointer">
          <div class="text-sm hover:underline" @click="edit_mode = true">
            Edit
          </div>
          <div class="text-sm hove:underline" @click="json_slug = help_slug">
            JSON
          </div>
          <div class="text-sm hover:underline" @click="create_tour">
            Create New
          </div>
        </div>
      </div>

      <!-- show a message if  groups empty object -->
      <div v-if="Object.keys(groups).length === 0" class="el_3_8bb190ce6d39ebe23f259541d225e335">
        <div class="px-2 py-1 bg-gray-200">
          No tours available
        </div>
      </div>

      <div v-for="group in groups" :key="group.name" class="el_19_8bb190ce6d39ebe23f259541d225e335">
        <div class="px-2 py-1 bg-gray-200">
          {{ group.name }}
        </div>
        <div v-for="item in group.items" :key="item.name" class="el_19_8bb190ce6d39ebe23f259541d225e335">
          <div class="flex space-x-3 p-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5 flex-shrink-0">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
            </svg>
            <div class="flex-1 text-sky-400 hover:underline" @click="select_tour(item)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </tg-toolbar-item>

  <div v-if="show" class="el_4_8bb190ce6d39ebe23f259541d225e335">
    <div v-if="teleport_target">
      <Teleport :to="teleport_target">
        <div id="_help_panel" v-draggable="{ key: 'panel', anywhere: true, enabled: true }"
          class="el_5_8bb190ce6d39ebe23f259541d225e335 text-base handle absolute w-[400px] z-30 rounded-lg shadow-lg p-5 text-left bg-gray-700/90 border-4 border-sky-500 text-white font-normal"
          :style="position">
          <div v-if="current_tour.steps.length > 0" class="el_6_8bb190ce6d39ebe23f259541d225e335 tourcontent">
            <div
              class="el_7_8bb190ce6d39ebe23f259541d225e335 border-b border-white pb-1 flex justify-between items-center">
              <div>
                {{ step + 1 }} of {{ current_tour.steps.length }} -
                {{ current_tour.steps[step].header }}
              </div>
              <div class="flex-shrink-0 flex space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5 cursor-pointer hover:text-primary-400" @click="manage">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5 cursor-pointer hover:text-primary-400" @click="finish">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="el_8_8bb190ce6d39ebe23f259541d225e335 py-3" v-html="current_tour.steps[step].content" />

            <div v-if="current_tour.steps[step].action"
              class="el_9_8bb190ce6d39ebe23f259541d225e335 mb-3 bg-sky-500/90 blink-border-50 border-4 border-sky-400 p-2 rounded italic justify">
              <div :class="flash" class="el_10_8bb190ce6d39ebe23f259541d225e335 font-bold pt-1 pb-2 text-sm">
                Action Required
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="el_11_8bb190ce6d39ebe23f259541d225e335" v-html="current_tour.steps[step].action" />
            </div>
            <div v-if="current_tour.steps[step].note"
              class="el_12_8bb190ce6d39ebe23f259541d225e335 mb-3 bg-gray-500 p-2 rounded italic justify">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="el_13_8bb190ce6d39ebe23f259541d225e335" v-html="current_tour.steps[step].note" />
            </div>
            <div v-if="current_tour.steps[step].alert"
              class="el_14_8bb190ce6d39ebe23f259541d225e335 mb-3 bg-red-500 text-white p-2 rounded italic justify">
              <div
                class="el_15_8bb190ce6d39ebe23f259541d225e335 font-bold pt-1 pb-2 text-sm uppercase blink-50 text-center">
                warning
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="el_16_8bb190ce6d39ebe23f259541d225e335" v-html="current_tour.steps[step].alert" />
            </div>

            <div
              class="el_17_8bb190ce6d39ebe23f259541d225e335 flex space-x-2 justify-between border-t border-white pt-4">
              <button v-if="current_tour.steps[step].show_prev" :class="current_tour.steps[step].prev_css"
                class="p-2 bg-sky-600 text-white  hover:bg-sky-800 rounded-md" @click="back">
                Previous
              </button>
              <div class="el_18_8bb190ce6d39ebe23f259541d225e335">
                <button v-if="step + 1 < current_tour.steps.length && current_tour.steps[step].show_next"
                  class="p-2 bg-sky-600 text-white rounded-md hover:bg-sky-800" @click="forward">
                  Next
                </button>
                <button v-if="step == current_tour.steps.length - 1"
                  class="p-2 bg-sky-600  hover:bg-sky-800 text-white rounded-md" @click="finish">
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </Teleport>
    </div>
  </div>
  <Teleport v-if="edit_mode" to="body">

    <div id="_helpForm" v-draggable="{ key: 'handle', position: 'fixed', anywhere: true, enabled: true }"
      style="z-index: 99999"
      class="fixed handle top-5 left-5 border-gray-400 text-gray-700 border-2 z-40 shadow-lg rounded-lg bg-white px-5 pb-5 text-left font-normal">
      <div class="p-2 border-b border-gray-200 font-bold text-lg flex space-x-2 justify-between handle">
        <div class="flex-1 handle">
          Manage Steps
        </div>
        <div class="flex-1 flex space-x-3 justify-end">
          <template v-if="current_tour.id">
            <div class="text-sm text-gray-400 text-right hover:underline" @click="restart">
              Reload
            </div>

            <div class="text-sm text-gray-400 text-right hover:underline" @click="json_slug = help_slug">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4 inline">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
              </svg>
              JSON
            </div>
          </template>
          <div class="text-sm text-gray-400 text-right hover:underline" @click="edit_mode = false">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4 inline">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            Close
          </div>
        </div>
      </div>

      <div class="flex space-x-5 my-4">
        <div class="flex-shrink-0 w-96">
          <div class="flex flex-col space-y-5">
            <div class="flex-1 text-left divide-y border border-gray-200 rounded-md">
              <draggable v-model="page.items" item-key="name" handle=".tour_dragger"
                class="flex flex-col border-b border-gray-200" @end="save">
                <template #item="{ element }">
                  <div class="flex space-x-3 p-2 tour_dragger group"
                    :class="element.id == current_tour.id ? 'bg-gray-300' : ''">
                    <div class="flex-1" @click="set_current_tour(element)">
                      {{ element.name }}
                    </div>
                    <div class="invisible flex-shrink-0 justify-end group-hover:visible" @click="copy(element)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                      </svg>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <div v-if="current_tour.id" class="flex-1 flex flex-col space-y-3 text-left">
              <div class="flex-1">
                <div>Group</div>
                <input v-model="current_tour.group" class="p-2 border border-gray-200 w-full rounded-md">
              </div>
              <div class="flex-1">
                <div>Tour Name</div>
                <input v-model="current_tour.name" class="p-2 border border-gray-200 w-full rounded-md">
              </div>
              <div>
                <SwitchGroup as="div" class="flex items-center" @click="current_tour.global = !current_tour.global">
                  <Switch :value="current_tour.global" :class="[
                    current_tour.global ? 'bg-primary-400' : 'bg-gray-400',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                  ]">
                    <span aria-hidden="true" :class="[
                      current_tour.global ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]" />
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-500 capitalize">
                      Global Step</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
              <div class="flex-1 flex space-x-3">
                <button class="py-1 px-4 bg-primary-400 rounded-lg text-white disabled:opacity-50" :disabled="saving"
                  @click="save">
                  Submit
                </button>
                <button class="py-1 px-4 bg-red-400 rounded-lg text-white disabled:opacity-50" :disabled="saving"
                  @click="del_tour">
                  Delete Tour
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="current_tour.id" class="flex-1 flex">
          <div class="flex-shrink-0 w-112 text-left divide-y border border-gray-200 rounded-md">
            <div class="p-1 bg-gray-200 border-b border-gray-400">
              <div v-if="clipboard" class="hover:underline" @click="paste_step">
                Paste Step
              </div>
              <div class="hover:text-sky-500 cursor-pointer" @click="create_step">
                Create Step
              </div>
            </div>
            <draggable v-model="current_tour.steps" item-key="id" handle=".step_dragger"
              class="flex flex-col border-b border-gray-200" @end="save">
              <template #item="{ element, index }">
                <div class="flex justify-between items-center group">
                  <div
                    class="flex-shrink-0 w-10 text-center bg-gray-200 py-2 px-4 font-bold step_dragger cursor-move hover:bg-gray-300">
                    {{ index }}
                  </div>
                  <div class="text-left flex-1 px-2 hover:underline hover:text-sky-400 cursor-pointer"
                    @click="form = element">
                    {{ element.header || "Empty" }}
                  </div>
                  <div class="flex-shrink-0 justify-end " @click="copy_step(element)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5 cursor-pointer hover:text-sky-500">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                  </div>
                  <div class="flex-shrink-0 justify-end " @click="del_step(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5 cursor-pointer hover:text-red-500">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>

                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <div v-if="form.id" class="flex-1">
            <div class="flex flex-col space-y-3 p-5 text-gray-700">
              <div class="flex space-x-3">
                <div class="flex-1">
                  <div>Element Class</div>
                  <input v-model="form.element_class" class="p-2 border border-gray-200 w-full rounded-md">
                </div>
                <div class="flex-shrink-0">
                  <div>Index</div>
                  <input v-model="form.el_index" class="p-2 border w-12 text-center border-gray-200 rounded-md">
                </div>
              </div>
              <div class="flex-1">
                <div>Panel Element (optional)</div>
                <input v-model="form.panel_el" class="p-2 border border-gray-200 w-full rounded-md">
              </div>
              <div class="flex-1">
                <div>Header</div>
                <input v-model="form.header" class="p-2 border border-gray-200 w-full rounded-md">
              </div>
              <div>
                <div>Content</div>
                <textarea v-model="form.content" class="p-2 border h-72 border-gray-200 w-full rounded-md" />
                <TgOpenAi :content="form.content" @accept="form.content = $event" />
              </div>
              <div>
                <div>Action</div>
                <textarea v-model="form.action" class="p-2 border h-36 border-gray-200 w-full rounded-md" />
              </div>
              <div>
                <div>Note</div>
                <textarea v-model="form.note" class="p-2 border h-36 border-gray-200 w-full rounded-md" />
              </div>
              <div>
                <SwitchGroup as="div" class="flex items-center" @click="form.show_prev = !form.show_prev">
                  <Switch :value="form.show_prev" :class="[
                    form.show_prev ? 'bg-primary-400' : 'bg-gray-400',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                  ]">
                    <span aria-hidden="true" :class="[
                      form.show_prev ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]" />
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-500 capitalize">
                      Show Previous button</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
              <div>
                <SwitchGroup as="div" class="flex items-center" @click="form.show_next = !form.show_next">
                  <Switch :value="form.show_next" :class="[
                    form.show_next ? 'bg-primary-400' : 'bg-gray-400',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                  ]">
                    <span aria-hidden="true" :class="[
                      form.show_next ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]" />
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-500 capitalize">
                      Show Next button</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
              <div>
                <SwitchGroup as="div" class="flex items-center" @click="form.auto_move = !form.auto_move">
                  <Switch :value="form.auto_move" :class="[
                    form.auto_move ? 'bg-primary-400' : 'bg-gray-400',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                  ]">
                    <span aria-hidden="true" :class="[
                      form.auto_move ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]" />
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-500 capitalize">
                      Watch for next element to appear</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
              <div class="flex space-x-3 justify-between">
                <div class="flex-1 flex space-x-3">
                  <button class="py-1 px-4 bg-primary-400 rounded-lg text-white disabled:opacity-50" :disabled="saving"
                    @click="save">
                    Submit
                  </button>
                  <!-- <button v-if="form.id" class="py-1 px-4 bg-red-400 rounded-lg text-white disabled:opacity-50"
                    :disabled="saving" @click="del_item">
                    Delete
                  </button> -->
                </div>
                <div class="text-gray-400 text-sm">
                  {{ form.id }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import _has from "lodash/has";
import _get from "lodash/get";
import _each from "lodash/each";
import _map from "lodash/map";
import { API } from "@/api";
import { UTILS } from "@/utils";
import TgJsonPageEditor from "@/components/Common/TgJsonPageEditor.vue";

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

import draggable from "vuedraggable";

import TgOpenAi from "@/components/Common/TgOpenAi";
import TgBtn from "@/components/Common/TgBtn";
import TgToolbarItem from "@/components/Common/TgToolbarItem";

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    draggable,
    TgBtn,
    TgOpenAi,
    TgToolbarItem,
    TgJsonPageEditor,
  },
  props: {
    slug: String,
  },
  data() {
    return {
      isTpAdmin: this.$auth.isTpAdmin(),
      tours: [],
      current_tour: {},
      steps: [],
      page: null,
      position: {},
      groups: null,
      step: 0,
      edit_mode: false,
      started: false,
      show_tours: false,
      current_el: null,
      teleport_target: null,
      saving: false,
      show: false,
      emitter: null,
      border_width: 4,
      help_box_width: 336,
      help_slug: "",
      json_slug: null,
      form: {},
      tour_index: -1,
      clipboard: null,
      override_otp: '36d1184c-eaa5-4df2-a675-a8b6221ed224',
      global_slug: 'global_1820A42EF95D47B9AABB6FC90843A9A8'
    };
  },

  async created() {
    this.emitter = API.get_emitter();
    this.help_slug = `help_${this.slug}`;
    this.get_page();
    if (this.$route.query.help) this.show_tours = true;
  },
  methods: {
    copy_step(step) {
      this.clipboard = UTILS._clone(step);
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Copied!",
      });
    },
    async paste_step() {
      this.current_tour.steps.push(this.clipboard);
      await this.save_to_db();
      // await API.put_page(UTILS._clone(this.page), this.override_otp);
      await this.get_page();
    },
    async get_page() {
      let resp = await API.page({ slug: this.help_slug });
      let global_resp = await API.page({ slug: this.global_slug });

      this.page = resp.data.data;

      if (_has(resp.data.data, "items")) {
        this.tours = _map(resp.data.data.items, (i) => {
          i.id = UTILS.str_rand(10, "id_");
          return i;
        });
      } else {
        this.page = { slug: this.help_slug, items: [] }
      }

      if (_has(global_resp.data.data, "items")) {
        _each(global_resp.data.data.items, (i) => {
          i.id = UTILS.str_rand(10, "id_");
          this.page.items.push(i);
          this.tours.push(i);
          return i;
        });
      }



      if (this.tour_index >= 0) {
        _each(this.page.items, (i) => {
          if (i.id == this.current_tour.id) this.current_tour = i;
        });
        this.form = {};
      }

      let groups = {};
      if (this.page) {
        _each(this.page.items, (i) => {
          if (!_has(groups, i.group)) {
            groups[i.group] = {
              name: i.group,
              items: [],
            };
          }
          groups[i.group].items.push(i);
        });
      }
      this.groups = groups;
    },

    async del_tour() {
      let index = -1;
      _each(this.page.items, (i, idx) => {
        if (i.id == this.current_tour.id) index = idx;
      });
      if (index >= 0) {
        this.page.items.splice(index, 1);
      }
      this.current_tour = {};
      this.save();
    },

    async create_tour() {
      this.page.items.push({
        name: "New Tour",
        group: "General",
        id: UTILS.str_rand(10, "id_"),
        steps: [],
      });
      await this.save_to_db();
      // await API.put_page(UTILS._clone(this.page), this.override_otp);
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Created!",
      });
      await this.get_page();
    },

    async copy(tour) {
      let new_tour = UTILS._clone(tour);
      new_tour.name = new_tour.name + " - COPY";
      new_tour.id = UTILS.str_rand(10, "id_");
      new_tour.steps = _map(new_tour.steps, (i) => {
        i.id = UTILS.str_rand(10, "id_");
        return i;
      });
      this.page.items.push(new_tour);
      await this.save_to_db();
      // await API.put_page(UTILS._clone(this.page), this.override_otp);
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Created!",
      });
      await this.get_page();
    },

    set_current_tour(i) {
      i.steps = _map(i.steps, (step) => {
        if (!_has(step, "id")) step.id = UTILS.str_rand(10, "id_");
        return step;
      });
      i.id = _get(i, "id", UTILS.str_rand(10, "id_"));
      this.current_tour = i;
    },

    select_tour(i) {
      this.show = false;
      i.steps = _map(i.steps, (step) => {
        if (!_has(step, "id")) step.id = UTILS.str_rand(10, "id_");
        return step;
      });
      this.current_tour = i;

      this.step = 0;
      this.show_tours = false;
      this.started = true;

      if (i.steps.length == 0) {
        this.current_tour.steps.push({
          element_class: "el_35_1e44e13df7a9b6013c78b3469d485b98",
          header: "Empty Placeholder",
          content: "Edit this item and select new element",
          show_prev: true,
          show_next: true,
        });
      }

      this.render();
    },

    restart() {
      this.step = 0;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
        this.get_page();
      });
    },
    redo() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
        this.render();
      });
    },
    manage() {
      this.form = this.current_tour.steps[this.step];
      this.edit_mode = true;
    },
    afterMove(item) {
      if (item.key == "panel") {
        try {
          this.current_tour.steps[this.step].position = {
            top: item.top + "px",
            left: item.left + "px",
          };
          this.save();
        } catch (e) {
          console.log(e);
        }
      }
    },
    del_step(idx) {
      this.current_tour.steps.splice(idx, 1);
      this.save();
    },
    create_step() {
      this.current_tour.steps.push({
        id: UTILS.str_rand(10, "id_"),
        header: 'New Step',
      })
    },
    async save() {
      this.saving = true;
      if (this.current_tour.id) {
        let found = false;
        _each(this.current_tour.steps, (i) => {
          if (i.id == this.form.id) found = true;
        });

        if (!found) {
          if (_has(this.form, "element_class")) this.current_tour.steps.push(this.form);
        }
      }

      await this.save_to_db();
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Step Saved!",
      });
      this.saving = false;
    },
    async save_to_db() {
      let all_steps = UTILS._clone(this.page);
      let global_steps = [];

      // loop over all steps and move step to global if its set
      _each(all_steps.items, (i, index) => {
        if (_has(i, 'global')) {
          global_steps.push(i)
          all_steps.items.splice(index, 1);
        }
      })

      await API.put_page(all_steps, this.override_otp);
      if (global_steps.length > 0)
        await API.put_page({
          slug: this.global_slug,
          items: global_steps
        }, this.override_otp);
    },
    close_step() {
      if (this.started) {
        this.cleanup();
        this.show = false;
      }
    },
    cleanup() {
      if (this.current_el) {
        this.current_el.classList.remove("tour-highlight");
      }
    },
    async render() {
      this.cleanup();
      let item = this.current_tour.steps[this.step];
      this.form = item;
      let el_index = _get(item, "el_index", 0);
      let el = document.getElementsByClassName(item.element_class)[el_index];
      this.show = true;

      if (el) {
        this.el_found(el, item);
      } else {
        const observer = new MutationObserver((mutations, obs) => {
          const found = document.getElementsByClassName(item.element_class)[el_index];
          if (found) {
            obs.disconnect();
            this.el_found();
          }
        });

        observer.observe(document, {
          childList: true,
          subtree: true,
        });
      }
    },
    el_found() {
      let item = this.current_tour.steps[this.step];

      let panel_el = _get(item, "panel_el", false);

      let el_index = _get(item, "el_index", 0);
      let el = document.getElementsByClassName(item.element_class)[el_index];

      if (el.classList.contains("absolute") || el.classList.contains("fixed")) {
      } else {
        el.classList.add("relative");
      }

      el.classList.add("tour-highlight");

      this.position = _get(item, "position", { top: 0, left: 0 });
      this.current_el = el;

      if (panel_el) {
        this.teleport_target = "." + panel_el;
      } else {
        this.teleport_target = el;
      }

      let auto_move = _get(item, "auto_move", false);

      if (auto_move && item.action) {
        this.monitor();
      }
    },
    async monitor() {
      let next_step = this.step + 1;
      if (_has(this.current_tour.steps, next_step)) {
        let next_item = this.current_tour.steps[next_step];
        let el_index = _get(next_item, "el_index", 0);
        let next_el = null;


        if (!next_el) {
          const observer = new MutationObserver((mutations, obs) => {
            const found = document.getElementsByClassName(next_item.element_class)[el_index];
            if (found) {
              obs.disconnect();
              this.forward();
              return;
            }
          });

          observer.observe(document, {
            childList: true,
            subtree: true,
          });
        }
      }
    },
    finish() {
      this.started = false;
      this.cleanup();
      this.step = 0;
      this.show = false;
      const query = { ...this.$route.query };
      delete query.help;
      this.$router.replace({ query });
    },
    async forward() {
      console.log("forward func");
      this.show = true;
      this.flash = "";
      let next_step = this.step + 1;
      if (_has(this.current_tour.steps, next_step)) {
        this.step = this.step + 1;
        this.$nextTick(() => {
          this.render();
        });
      }
    },
    back() {
      this.teleport_target = `#${this.current_tour.steps[this.step].element_class}`;
      if (this.step == 0) return;
      this.step--;
      this.$nextTick(() => {
        this.render();
      });
    },
  },
};
</script>

<style></style>
