<template>
  <div
    class="el_1_9365ff11323407f9f4d866120dc60392 flex items-center p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100"
    :class="data.formtype == 'grouper' ? 'bg-gray-400 text-white hover:text-gray-700' : ''">
    <div class="el_2_9365ff11323407f9f4d866120dc60392 w-6/12 pl-2">
      <div class="el_3_9365ff11323407f9f4d866120dc60392 inline-flex items-center">
        <MenuIcon class="w-4 h-4 mr-3 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        {{ data.name }}
      </div>
    </div>
    <div class="el_4_9365ff11323407f9f4d866120dc60392 w-6/12 text-right">
      <div class="el_5_9365ff11323407f9f4d866120dc60392 inline-flex items-center space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-5 h-5" tooltip="Encrypt" :class="data.encrypt ? 'text-red-500 opacity-100' : 'opacity-50'"
          @click="toggle('encrypt')">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
        </svg>

        <KeyIcon v-if="data.formtype != 'grouper'" class="w-5 h-4 cursor-pointer hover:text-red-500"
          :class="data.primary_key ? 'text-red-500 opacity-100' : 'opacity-50'" tooltip="Primary Key"
          @click="toggle('primary_key')" />
        <tg-badge v-if="data.formtype != 'grouper'" :color="data.unique ? 'success' : 'gray'" tooltip="Unique"
          @click="toggle('unique')">
          U
        </tg-badge>
        <tg-badge color="danger" @click="remove()">
          X
        </tg-badge>
      </div>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { MenuIcon, KeyIcon } from "@heroicons/vue/solid";

export default {
  name: "FormItem",
  components: {
    TgBadge,
    MenuIcon,
    KeyIcon,
  },
  props: {
    item: Object,
  },
  emits: ["remove-item", "toggle-item-feature"],
  data() {
    return {
      data: this.item,
    };
  },
  methods: {
    toggle(fld) {
      this.data[fld] = !this.data[fld];
      // this.$emit("toggle-item-feature", { fld: fld, item: this.data });
    },
    remove() {
      this.$emit("remove-item", { fld: "form", item: this.data });
    },
  },
};
</script>

<style></style>
