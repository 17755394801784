<template>
  <div v-if="data" class="el_1_ccc297a6ca4aa56db5ae1a1e071ec5f4 flex items-center p-1 mb-1 border border-gray-200 rounded-lg hover:bg-primary-100" :class="data.hide ? 'opacity-50' : ''">
    <div class="el_2_ccc297a6ca4aa56db5ae1a1e071ec5f4 w-6/12 pl-2">
      <div class="el_3_ccc297a6ca4aa56db5ae1a1e071ec5f4 inline-flex items-center">
        <MenuIcon class="w-4 h-4 mr-1 text-gray-300 cursor-move hover:text-primary-300 handle" aria-hidden="true" />
        <EyeIcon :class="!data.hide ? 'text-green-400' : 'text-gray-300'" class="w-4 h-4 mr-2 cursor-pointer hover:text-primary-300" aria-hidden="true" @click="toggle('hide')" />
        {{ data.name }}
      </div>
    </div>
    <div class="el_4_ccc297a6ca4aa56db5ae1a1e071ec5f4 w-6/12 text-right cursor-pointer">
      <CloudDownloadIcon class="inline w-5 h-5 cursor-pointer" :class="data.export == 1 ? 'text-green-400' : 'text-gray-500 opacity-50'" @click="toggle('export')" />
      <tg-badge :color="data.groupby ? 'success' : 'gray'" @click="toggle('groupby')">
        G
      </tg-badge>
      <tg-badge :color="data.orderby ? 'success' : 'gray'" @click="toggle('orderby')">
        S
      </tg-badge>
      <tg-badge :color="data.filters ? 'success' : 'gray'" @click="toggle('filters')">
        F
      </tg-badge>
      <tg-badge :color="data.values ? 'success' : 'gray'" @click="toggle('values')">
        V
      </tg-badge>
      <tg-badge :color="data.pivot_arr ? 'success' : 'gray'" @click="toggle('pivot_arr')">
        P
      </tg-badge>
      <tg-badge color="danger" @click="remove()">
        X
      </tg-badge>
    </div>
  </div>
</template>

<script>
import TgBadge from "@/components/Common/TgBadge.vue";
import { MenuIcon, EyeIcon, CloudDownloadIcon } from "@heroicons/vue/solid";
import _map from "lodash/map";
import _get from "lodash/get";

export default {
  name: "DataItem",
  components: {
    TgBadge,
    MenuIcon,
    EyeIcon,
    CloudDownloadIcon,
  },
  props: {
    item: Object,
  },
  emits: { "toggle-item-feature": null, "remove-item": null },
  data() {
    return {
      data: {},
    };
  },
  created() {
    let i = this.item;
    i.export = _get(i, "export", 1);
    this.data = i;
  },
  methods: {
    toggle(fld) {
      this.data[fld] = !this.data[fld];
      this.$emit("toggle-item-feature", { fld: fld, item: this.data });
    },
    remove() {
      this.$emit("remove-item", { fld: "select", item: this.data });
    },
  },
};
</script>

<style>
</style>