<template>
  <button type="button" :disabled="disabled" class="px-4 py-2 ml-2 font-medium text-white border rounded-md shadow-sm bg-primary-600 border-primary-400 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-gray-300">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
};
</script>

<style>
</style>