<template>
  <TgPanel class="flex-1 text-left">
    <template #header>
      Reports
    </template>
    <template #header-buttons>
      <tg-btn color="gray" class="mr-1" :disabled="loading" @click="refresh">
        Refresh
      </tg-btn>
    </template>
    <template #content>
      <TgLoader v-if="loading" class="mx-auto my-20" />
      <div v-else class="el_1_53a8b359038cc9c26e664314727ef154 mt-5">
        <div v-if="data.length == 0" class="el_2_53a8b359038cc9c26e664314727ef154 py-20 text-lg text-center">
          No reports found.
        </div>
        <div v-if="$auth.client.value.key == 'ib' || $auth.client.value.key == 'demo'">
          <div v-for="report_item in data" :key="report_item.id" class="el_3_53a8b359038cc9c26e664314727ef154 mb-6">
            <flight-crew-report-item-ib :item="report_item" :config="config" :refreshlist="refresh" :tab="tab"
              @on:refresh="refresh" />
          </div>
        </div>
        <div v-else>
          <div v-for="report_item in data" :key="report_item.id" class="el_3_53a8b359038cc9c26e664314727ef154 mb-6">
            <flight-crew-report-item :item="report_item" :config="config" :refreshlist="refresh" :tab="tab" />
          </div>
        </div>
      </div>
    </template>
  </TgPanel>
</template>

<script>
import { API } from "@/api";
import _findIndex from "lodash/findIndex";
import _map from "lodash/map";
import _has from "lodash/has";
import _get from "lodash/get";
import { STORE } from "@/store";
import TgBtn from "@/components/Common/TgBtn";
import TgLoader from "@/components/Common/TgLoader";
import TgPanel from "@/components/Common/TgPanel";
import FlightCrewReportItem from "@/components/Touchpoint/Schedules/Flight/FlightCrewReportItem.vue";
import FlightCrewReportItemIb from "@/components/Touchpoint/Schedules/Flight/FlightCrewReportItemIb.vue";
export default {
  components: {
    TgLoader,
    TgBtn,
    TgPanel,
    FlightCrewReportItem,
    FlightCrewReportItemIb,
  },
  props: {
    flrkey: String,
    tab: Object,
    config: Object,
    item: Object,
  },
  data() {
    return {
      loading: true,
      data: [],
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;

      let slug = "5D50D7E3BE80D3E2AD391A5A5C639860";
      let payload = {
        loadflight: this.flrkey,
      };

      if (this.$auth.client.value.key == "ib") {
        slug = "84D444B71CE4441CADED9890820B3805";
        payload = {
          flrkey: this.flrkey,
        };

        let query_type = _get(this.config, "query", null);

        if (query_type == "flightreport") {
          payload = {
            id: this.item.row.id,
          };
        }
      }

      let resp = await API.get(slug, payload);

      if (resp) {
        this.data = _map(resp.data.data, (i) => {
          if (_has(this.config, "supplier")) {
            i.supplier_css = "dark";
            if (i.status == "Port Accept") i.supplier_css = "danger";
            if (i.status == "Port Dispute") i.supplier_css = "success";

            i.airline_css = "dark";
            if (i.status_airline == "Caterer Accepted") i.airline_css = "danger";
            if (i.status_airline == "Enforced") i.airline_css = "danger";
            if (i.status_airline == "Enforced-Expired") i.airline_css = "danger";
            if (i.status_airline == "HO Excluded") i.airline_css = "danger";
            if (i.status_airline == "Overturned") i.airline_css = "success";
            if (i.status_airline == "Pending") i.airline_css = "primary";
          } else {
            i.supplier_css = "dark";
            if (i.status == "Port Accept") i.supplier_css = "success";
            if (i.status == "Port Dispute") i.supplier_css = "danger";

            i.airline_css = "dark";
            if (i.status_airline == "Caterer Accepted") i.airline_css = "success";
            if (i.status_airline == "Enforced") i.airline_css = "success";
            if (i.status_airline == "Enforced-Expired") i.airline_css = "success";
            if (i.status_airline == "HO Excluded") i.airline_css = "success";
            if (i.status_airline == "Overturned") i.airline_css = "danger";
            if (i.status_airline == "Pending") i.airline_css = "primary";
          }

          i.stage_css = "dark";
          if (i.stage == "Closed IFS") i.stage_css = "success";

          return i;
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
