<template>
  <div class="el_1_09e7c3e6c7d27910d3f69bcc5eb5a258 relative">
    <button class="py-2 w-20 text-white rounded-md" :class="config.css" @click="openCalc">
      {{ item[col.field] }}
    </button>
  </div>

  <tg-modal :open="showCalc" size="w-10/12 max-w-2xl " class="z-50" @modal-close="closeCalc">
    <template #header class="justify-center items-center">
      {{ item.masterdescription || item.productname }}
    </template>

    <template #content>
      <div class="el_2_09e7c3e6c7d27910d3f69bcc5eb5a258 p-3 mb-20">
        <div class="el_3_09e7c3e6c7d27910d3f69bcc5eb5a258 p-4 mb-2 text-5xl text-right rounded-md border text-primary-600 bg-primary-100 border-primary-300">
          {{ qty }}
        </div>

        <div class="el_4_09e7c3e6c7d27910d3f69bcc5eb5a258 grid grid-cols-3 space-x-2 text-center">
          <div v-for="i in [1,2,3]" :key="i" class="el_5_09e7c3e6c7d27910d3f69bcc5eb5a258 col-span-1 p-7 text-2xl font-bold bg-gray-200 rounded-md w_full" @click="tap(i)">
            {{ i }}
          </div>
        </div>
        <div class="el_6_09e7c3e6c7d27910d3f69bcc5eb5a258 grid grid-cols-3 mt-2 space-x-2 text-center">
          <div v-for="i in [4,5,6]" :key="i" class="el_7_09e7c3e6c7d27910d3f69bcc5eb5a258 col-span-1 p-7 text-2xl font-bold bg-gray-200 rounded-md w_full" @click="tap(i)">
            {{ i }}
          </div>
        </div>
        <div class="el_8_09e7c3e6c7d27910d3f69bcc5eb5a258 grid grid-cols-3 mt-2 space-x-2 text-center">
          <div v-for="i in [7,8,9]" :key="i" class="el_9_09e7c3e6c7d27910d3f69bcc5eb5a258 col-span-1 p-7 text-2xl font-bold bg-gray-200 rounded-md w_full" @click="tap(i)">
            {{ i }}
          </div>
        </div>
        <div class="el_10_09e7c3e6c7d27910d3f69bcc5eb5a258 grid grid-cols-3 mt-2 space-x-2 text-center">
          <div class="el_11_09e7c3e6c7d27910d3f69bcc5eb5a258 col-span-1 p-7 text-2xl font-bold text-white uppercase bg-gray-400 rounded-md w_full" @click="tap('CLEAR')">
            clear
          </div>
          <div class="el_12_09e7c3e6c7d27910d3f69bcc5eb5a258 col-span-1 p-7 text-2xl font-bold bg-gray-200 rounded-md w_full" @click="tap(0)">
            0
          </div>
          <div class="el_13_09e7c3e6c7d27910d3f69bcc5eb5a258 col-span-1 p-7 text-2xl font-bold text-white uppercase rounded-md bg-primary-600 w_full" @click="tap('DONE')">
            done
          </div>
        </div>
      </div>
    </template>
  </tg-modal>
</template>

<script>
import TgModal from "@/components/Common/TgModal.vue";
import _get from "lodash/get";
import _has from "lodash/has";

export default {
  components: {
    TgModal,
  },
  props: {
    slug: String,
    item: Object,
    col: Object,
    tab: Object,
    refresh: Function,
    params: String,
    itemIndex: Number,
    groupIndex: Number,
    access: Boolean,
    cachekey: String,
  },
  emits: ["update-item", "on:update", "formsuccess"],
  data() {
    return {
      row: this.item,
      config: {},
      qty: 0,
      showCalc: false,
    };
  },
  created() {
    if (!this.params) {
      this.config = {};
    } else {
      this.config = JSON.parse(this.params);
    }
    this.config.css = _get(this.config, "css", "bg-primary-300");
  },
  methods: {
    tap(val) {
      val = val.toString();
      if (val == "CLEAR") {
        this.qty = 0;
      } else if (val == "DONE") {
        this.onUpdate(this.qty);
      } else {
        let q = this.qty.toString();
        if (q == "0") q = "";
        this.qty = q + val;
      }
    },
    onUpdate(qty) {
      console.log(this.config);
      if (_has(this.config, "no_emit")) {
        this.$emit("update-item", { qty: qty });
        this.closeCalc();
      } else {
        this.row.v_row_css = "bg-green-100";
        this.row[this.col.field] = qty;
        this.$emit("update-item", {
          itemIndex: this.itemIndex,
          groupIndex: this.groupIndex,
          row: this.row,
        });
        this.closeCalc();
      }
    },
    openCalc() {
      this.showCalc = true;
    },
    closeCalc() {
      this.showCalc = false;
    },
  },
};
</script>
