<template>
  <TgPanel class="flex-1">
    <template #header>
      {{ label }}
    </template>
    <template #content>
      <div class="el_1_85abbc93d58806ef6a0c669fcf915e21 text-3xl font-bold text-center">
        {{ value }} <small v-if="suffix" class="text-sm text-gray-400 uppercase">{{ suffix }}</small>
      </div>
    </template>
  </TgPanel>
</template>

<script>
import TgPanel from "@/components/Common/TgPanel";
export default {
  components: {
    TgPanel,
  },
  props: {
    label: String,
    suffix: String,
    value: [String, Number],
  },
};
</script>

<style>
</style>