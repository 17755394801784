<template>
  <label :for="item.field" class="relative block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_512a49d9bc2dc86de1a609593e69c1e8 inline mr-1 text-sm text-red-400">*</span>
    {{ item.name }}
    <div class="el_2_512a49d9bc2dc86de1a609593e69c1e8 absolute top-0 right-0">
      <InformationCircleIcon v-if="item.popover" class="inline-block w-4 h-4 mb-1 ml-1 cursor-pointer"
        @click="show_help = !show_help" />
    </div>
  </label>
  <div class="el_3_512a49d9bc2dc86de1a609593e69c1e8 flex space-x-1 mt-1 rounded-md shadow-sm">
    <div v-for="c in colors" :key="c"
      class="flex-1 p-2 cursor-pointer opacity-80 hover:opacity-100 border border-gray-300 rounded-md" :class="c"
      @click="$emit('update:modelValue', c)">
      <svg v-if="modelValue == c" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
        stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
      </svg>
      <span v-else>&nbsp;</span>
    </div>
  </div>
  <div v-if="show_help && item.popover"
    class="el_4_512a49d9bc2dc86de1a609593e69c1e8 absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
    @click="show_help = false">
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
export default {
  components: {
    InformationCircleIcon,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      show_help: false,
      colors: [
        "color_default",
        "color_blue",
        "color_green",
        "color_red",
        "color_gray",
        "color_orange",
        "color_purple",
        "color_cyan",
        "color_yellow"
      ]

    };
  },
};
</script>
