<template>
  <label :for="item.field" class="relative block text-sm font-medium uppercase text-primary-800">
    <span
      v-if="item.required"
      class="el_1_ee66fa49597769e856b5be681db6a83b inline mr-1 text-sm text-red-400"
    >*</span>
    {{ item.name }}
  </label>
  <div
    class="el_2_ee66fa49597769e856b5be681db6a83b items-center p-2 mt-1 bg-white rounded-md shadow-sm"
  >
    <div class="flex space-x-2">
      <div v-for="(attachment, file_idx) in attachments" :key="file_idx" class="flex-shrink-0 px-3">
        <div v-if="attachment.resource_type == 'image'" class="text-center group flex flex-col">
          <tg-image :path="attachment.public_id" :thumb="true" class="shadow-md" />

          <div class="text-center text-sm cursor-pointer">
            {{ attachment.original_filename }}
          </div>
          <span
            class="text-red-400 text-xs hover:underline cursor-pointer group-hover:visible"
            @click="remove_attachement(file_idx)"
          >Remove</span>
        </div>
        <div v-else class="text-center group flex flex-col">
          <div class="h-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-16 h-16 mx-auto"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
          </div>
          <a
            class="text-center text-sm cursor-pointer"
            :href="attachment.secure_url"
            target="_blank"
          >{{ attachment.filename }}</a>
          <span
            class="text-red-400 text-xs invisible hover:underline cursor-pointer group-hover:visible"
            @click="remove_attachement(file_idx)"
          >Remove</span>
        </div>
      </div>
    </div>
  </div>
  <div class="el_4_ee66fa49597769e856b5be681db6a83b mt-5">
    <TgCloudinaryUploader :folder="`${slug}/${item.id}/${guid}`" @upload:success="upload_success" />
  </div>
  <div
    v-if="show_help && item.popover"
    class="el_5_ee66fa49597769e856b5be681db6a83b absolute left-0 z-40 p-2 mt-1 border rounded-lg shadow-lg top-16 text-primary-700 bg-primary-100 border-primary-300"
    @click="show_help = false"
  >
    <InformationCircleIcon class="inline-block w-6 h-6 mr-1" /> {{ item.popover }}
  </div>
</template>

<script>
import TgCloudinaryUploader from "@/components/Common/TgCloudinaryUploader.vue";
import { UTILS } from "@/utils";
import TgImage from "@/components/Common/TgImage";
import _each from "lodash/each";

export default {
  components: {
    TgCloudinaryUploader,
    TgImage,
  },
  props: {
    item: Object,
    slug: String,
    error: String,
    modelValue: String,
  },
  emits: { "update:modelValue": null, "update:error": null },
  data() {
    return {
      guid: UTILS.guid(),
      show_help: false,
      file_obj: {},
      attachments: {},
    };
  },
  created() {
    try {
      this.attachments = JSON.parse(this.modelValue);
    } catch (e) {}
  },
  methods: {
    async remove_attachement(idx) {
      this.attachments.splice(idx, 1);
      this.$emit("update:modelValue", JSON.stringify(this.attachments));
    },
    upload_success(files) {
      _each(files, (f) => {
        this.attachments.push(f);
      });
      this.$emit("update:modelValue", JSON.stringify(this.attachments));
    },
  },
};
</script>
