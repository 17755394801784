<template>
  <tg-modal :open="open" size="w-11/12  pb-40 z-50" class="z-50" @modal-close="done">
    <template #header>
      Fields Configuration
    </template>

    <template #content>
      <div class="el_1_a74985d94607241784540ccdd8f7854d overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <td class="absolute mb-1 bg-gray-300" style="min-width: 110px">
                Fields
              </td>
              <td style="min-width: 115px">
                &nbsp;
              </td>
              <th v-for="(item, idx) in mutatedselect" :key="idx" class="bg-gray-300 border-b border-gray-400">
                <input v-model="item.field" type="text"
                  class="block py-1 w-full text-sm text-white bg-gray-400 rounded-md border-gray-200 focus:outline-none">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="col in config" :key="col.field" class="hover:bg-primary-400 group">
              <td
                class="absolute h-full bg-gray-200 border-r border-gray-400 group-hover:bg-primary-400 group-hover:text-white"
                style="min-width: 110px">
                {{ col.name }}
              </td>
              <td>&nbsp;</td>
              <td v-for="item in mutatedselect" :key="item.field" :style="{ minWidth: calcWidth(item) }">
                <select v-if="col.field == 'formtype'" v-model="item.formtype"
                  :class="item.formtype == 'grouper' ? 'bg-gray-200' : ''"
                  class="block w-full py-1 pl-3 pr-10 text-sm border-gray-200 rounded-md focus:outline-none">
                  <option v-for="i in [
                    'input',
                    'textarea',
                    'number',
                    'mask',
                    'combo',
                    'combo_array',
                    'multiselect',
                    'combo_mobile',
                    'cascade_combo',
                    'checkbox',
                    'checkbox_inline',
                    'tags',
                    'date',
                    'datetime',
                    'password',
                    'guid',
                    'time',
                    'image',
                    'product_finder',
                    'timestamp',
                    'file',
                    'colorpicker',
                    'filecloudinary',
                    'grouper',
                    'camera',
                    'displayonly',
                    'flightpicker',
                  ]" :key="i" :value="i">
                    {{ i }}
                  </option>
                </select>
                <input v-else-if="col.type == 'checkbox'" v-model="item[col.field]"
                  :class="item.formtype == 'grouper' ? 'bg-gray-200' : ''" type="checkbox"
                  class="w-4 h-4 border-gray-300 rounded focus:ring-primary-500 text-primary-600">
                <textarea v-else-if="col.field == 'popover'" v-model="item.popover"
                  :class="item.formtype == 'grouper' ? 'bg-gray-200' : ''" type="text"
                  class="block w-full py-1 text-sm border-gray-200 rounded-md h-28 focus:outline-none" />
                <textarea v-else-if="col.field == 'validators'" v-model="item.validators"
                  :class="item.formtype == 'grouper' ? 'bg-gray-200' : ''" type="text"
                  class="block w-full py-1 text-sm border-gray-200 rounded-md h-28 focus:outline-none" />
                <input v-else v-model="item[col.field]" type="text"
                  :class="item.formtype == 'grouper' ? 'bg-gray-200' : ''"
                  class="block w-full py-1 text-sm border-gray-200 rounded-md focus:outline-none">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template #footer>
      <tg-btn :click="autoconfigure" color="primary" class="ml-3">
        Auto Configure
      </tg-btn>
      <tg-btn :click="done" color="success" class="ml-3">
        Done
      </tg-btn>
    </template>
  </tg-modal>
</template>

<script>
import TgModal from "@/components/Common/TgModal.vue";
import TgBtn from "@/components/Common/TgBtn.vue";

import _map from "lodash/map";
import _has from "lodash/has";

import md5 from "blueimp-md5";

import { API } from "@/api";
import _get from "lodash/get";
import _isString from "lodash/isString";

export default {
  name: "FormFieldsConfigModal",
  components: {
    TgModal,
    TgBtn,
  },
  props: {
    open: Boolean,
    table: String,
    select: Array,
    slug: String,
  },
  emits: ["update:select", "modal-close"],
  data() {
    return {
      showHelpPanel: false,
      data: null,
      help_id: md5("form-fields-help"),
      mutatedselect: _map({ ...this.select }, (i) => {
        if (!_isString(i.validators)) i.validators = JSON.stringify(i.validators);
        if (!_has(i, "v_col_width")) i.v_col_width = "col-span-12";
        return i;
      }),
      config: [
        { field: "name", name: "Name" },
        { field: "formtype", name: "Type" },
        { field: "v_col_width", name: "Width" },
        { field: "default_value", name: "Default" },
        { field: "sql", name: "Combo SQL" },
        { field: "sql_edit", name: "Combo SQL (Edit)" },
        { field: "cascade_field", name: "Cascade Fld" },
        { field: "combo_array", name: "Combo Array" },
        { field: "dt_min", name: "Date Min" },
        { field: "dt_max", name: "Date Max" },
        { field: "prefix", name: "Prefix" },
        { field: "suffix", name: "Suffix" },
        { field: "maska", name: "Maska" },
        { field: "input_css", name: "Input CSS" },

        { field: "hide", name: "Hidden", type: "checkbox" },
        { field: "allow_edit", name: "Allow Edit", type: "checkbox" },
        { field: "hide_edit", name: "Hide on Edit", type: "checkbox" },
        { field: "disable_edit", name: "Disable on Edit", type: "checkbox" },
        { field: "required", name: "Required", type: "checkbox" },
        { field: "disabled", name: "Disabled", type: "checkbox" },
        { field: "popout_help", name: "Slide out Help", type: "checkbox" },
        { field: "unique", name: "Unique", type: "checkbox" },
        { field: "validators", name: "Validators" },
        { field: "popover", name: "Field Help" },
      ],
      loading: false,
    };
  },
  computed: {
    calcWidth() {
      return (item) => {
        let len = item.field.length * 6 + 15;
        if (len < 120) len = 120;
        return len + "px";
      };
    },
  },
  methods: {
    closeHelpPanel() {
      this.showHelpPanel = false;
    },
    openHelpPanel() {
      this.showHelpPanel = true;
    },
    done() {
      this.$emit("update:select", this.mutatedselect);
      this.$emit("modal-close");
    },
    async autoconfigure() {
      let resp = await API.raw(`schema/${this.slug}?tbl=${this.table}`, {}).catch((e) => {
        console.log(e);
      });

      if (resp) {
        let schema_fields = resp.data.data;
        this.mutatedselect = _map(this.mutatedselect, (i) => {
          let schema = _get(schema_fields, i.field, false);

          if (schema) {
            let validators = [];

            if (schema.max_length) {
              validators = [{ type: "length", min: 0, max: schema.max_length }];
            }

            if (
              ["bigint", "int", "tinyint", "numeric", "smallint"].indexOf(schema.data_type) != -1
            ) {
              validators.push({ type: "integer" });
              i.v_css = "text-right";
            }
            if (["float", "decimal"].indexOf(schema.data_type) != -1) {
              validators.push({ type: "float" });
              i.v_css = "text-right";
            }
            if (["date"].indexOf(schema.data_type) != -1) {
              i.formtype = "date";
              i.v_col_width = "col-span-6";
            }
            if (["bit"].indexOf(schema.data_type) != -1) {
              i.formtype = "checkbox";
              i.v_col_width = "col-span-3";
            }

            let default_value = _get(schema, "default_value", "");

            if (default_value) i.default_value = default_value.replace(/[^0-9a-zA-Z_-]/gi, "");

            if (schema.required == 1 && i.field != "id") i.required = true;

            i.validators = JSON.stringify(validators);
          }

          return i;
        });
      }
    },
  },
};
</script>

<style></style>
