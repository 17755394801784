<template>
  <teleport v-if="grouped.length > 0" to="#mobile-bar-top-right">
    <button class="p-2 w-20 text-white rounded-md bg-primary-700" @click="openForm">
      Submit
    </button>
  </teleport>
  <tg-modal :open="showForm" size="w-112" class="z-50" flex="true" @modal-close="closeForm">
    <template #header class="justify-center items-center">
      <span v-if="!submitting" class="el_2_96b23718f39f6b5e7171e8119e953128">Sign Off</span>
      <span v-if="submitting" class="el_3_96b23718f39f6b5e7171e8119e953128">Please Wait...</span>
    </template>
    <template #content>
      <div v-if="submitting" class="el_4_96b23718f39f6b5e7171e8119e953128">
        <TgLoader />
      </div>
      <form-panel v-if="!submitting" ref="FormPanel" :slug="slug" :selected="selected_row" @formerror="formHasError"
        @formsuccess="receiveFormData" />
    </template>
    <template #footer>
      <TgSubmit :disabled="submitting" @click="submitForm">
        Submit
      </TgSubmit>
    </template>
  </tg-modal>
</template>

<script>
import _each from "lodash/each";
import _has from "lodash/has";
import _isString from "lodash/isString";
import TgModal from "@/components/Common/TgModal.vue";
import FormPanel from "@/components/Grids/FormPanel.vue";
import TgSubmit from "@/components/Common/TgSubmit";
import TgLoader from "@/components/Common/TgLoader";
import { MOBILE, API } from "@/api";
import { UTILS } from "@/utils";
import dayjs from "dayjs";

import { get as GetCache, set } from "idb-keyval";
import _get from "lodash/get";
import _head from "lodash/head";

export default {
  components: {
    TgModal,
    FormPanel,
    TgSubmit,
    TgLoader,
  },
  props: {
    slug: String,
    item: Object,
    col: Object,
    refreshParent: Function,
    params: String,
    grouped: Array,
  },
  data() {
    return {
      cachekey: null,
      queue_key: "queue",
      selected_row: null,
      submitting: false,
      showForm: false,
      flightkeyfinal: this.$route.query.flightkeyfinal,
      form: null,
      stage: "",
    };
  },
  async created() {
    if (this.params && _isString(this.params)) {
      let params = JSON.parse(this.params);
      this.stage = params.stage;
    }
    let resp = await API.get(this.slug, {
      stage: this.stage,
      _raw: 1,
      _top: 1,
      orderBy: "created_desc",
      flightkeyfinal: this.$route.query.flightkeyfinal,
    });
    this.selected_row = _head(resp.data.data);
    this.cachekey = API.get_cache_key("data", this.slug, false);
  },
  methods: {
    formHasError() {
      this.submitting = false;
    },
    openForm() {
      this.showForm = true;
    },
    closeForm() {
      this.submitting = false;
      this.showForm = false;
    },
    submitForm() {
      this.submitting = true;
      this.$refs.FormPanel.get_payload();
    },
    receiveFormData(formsuccess) {
      this.form = formsuccess;
      this.submit();
    },
    async submit() {
      // let cache_resp = await GetCache(this.cachekey);

      // let queue = await GetCache(this.queue_key);

      // cache_resp.data = this.grouped;
      // set(this.cachekey, JSON.parse(JSON.stringify(cache_resp)));

      // if (!queue)
      //   queue = {
      //     lastmile: {
      //       type: "lastmile",
      //       slug: this.slug,
      //       items: {},
      //     },
      //   };

      let queue = {
        lastmile: {
          items: {}
        }
      }

      let form = this.form;
      let guid = UTILS.guid();

      queue.lastmile.items[this.flightkeyfinal] = [];

      let ungrouped = [];

      _each(this.grouped, (g) => {
        _each(g.items, (i) => {
          ungrouped.push(i);
        });
      });

      let qty_fld = "quantity_delivered";

      if (this.stage == "production") qty_fld = "quantity_produced";
      if (this.stage == "dispatch") qty_fld = "quantity_dispatched";

      _each(ungrouped, (i) => {
        let row = {
          instruction_id: i.id,
          flightkeyfinal: i.flightkeyfinal,
          flightkeymeal: i.flightkeymeal,
          masterlistkey: i.masterlistkey,
          masterlistkey_sub: i.masterlistkey_sub,
          stage: this.stage,
          qtycontracted: i.qtycontracted,
          service: i.service,
          direction: i.direction,
          qtylive: i.qtylive,
          submit_local: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          qtysubmitted: _get(i, qty_fld, 0),
          paxquantity: i.paxquantity,
        };

        row.user_id = this.$auth.user.value.sub;
        row.app_version = process.env.VUE_APP_VERSION;
        row._insertKey = guid;

        queue.lastmile.items[i.flightkeyfinal].push({ ...form, ...row });
      });

      if (navigator.onLine === true) {
        let resp = await MOBILE.bulk_insert(
          this.slug,
          queue.lastmile.items[this.flightkeyfinal]
        ).catch((e) => {
          console.log(e);
        });

        await API.proc({
          proc: "SSDFp_680_Submissions_NEW_IPAD",
          params: [guid],
        });
      } else {
        console.log("queue_key = ", this.queue_key);
        console.log(queue);
        // set(this.queue_key, JSON.parse(JSON.stringify(queue)));
      }

      // this.$router.go(-1);

      this.closeForm();

      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Submit received!",
      });

      let back = JSON.parse(this.$route.query.back);

      this.$router.push({
        name: "mobilepage",
        params: back.params,
        query: back.query,
      });
    },
  },
};
</script>

<style></style>
