<template>
  <div
    class="el_1_269c7893a2a1b6addc8f06b99a005bb7 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
    @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <div v-if="!sending" class="el_2_269c7893a2a1b6addc8f06b99a005bb7 space-y-1 text-center">
      <svg class="w-12 h-12 mx-auto text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
        aria-hidden="true">
        <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div class="el_3_269c7893a2a1b6addc8f06b99a005bb7 flex text-sm text-gray-600">
        <label for="file-upload"
          class="relative font-medium text-indigo-600 bg-white rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          <input id="assetsFieldHandle" ref="file" type="file" multiple name="fields[assetsFieldHandle][]"
            class="absolute w-px h-px overflow-hidden opacity-0" accept=".pdf,.jpg,.jpeg,.png" @change="onChange">
        </label>
        Drag and drop file here
      </div>
      <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p> -->
    </div>
    <div v-else class="el_4_269c7893a2a1b6addc8f06b99a005bb7 text-center">
      <TgLoader />
      <div class="el_5_269c7893a2a1b6addc8f06b99a005bb7 text-gray-500">
        Uploading {{ complete }} of {{ total }} files..
      </div>
    </div>
  </div>
</template>

<script>
import TgLoader from "@/components/Common/TgLoader";
import { getState } from "@/auth";
import _each from "lodash/each";
export default {
  components: {
    TgLoader,
  },
  props: {
    access: String,
    folder: String,
    filename: String,
  },
  emits: { "upload:success": null },
  data: function () {
    return {
      filelist: [],
      complete: 0,
      total: 0,
      sending: false,
    };
  },
  methods: {
    async onChange() {
      console.log('Before filelist assignment');
      this.filelist = [...this.$refs.file.files];
      console.log('filelist:', this.filelist);

      this.total = this.filelist.length;
      this.sending = true;
      let jobs = [];

      _each(this.filelist, (f, index) => {
        console.log(`Processing file ${index}:`, f);
        jobs.push(this.upload(f));
      });

      console.log('jobs:', jobs);

      let resp = await Promise.all(jobs);

      console.log('resp:', resp);

      if (resp) {
        console.log("upload resp", resp);
        this.sending = false;
        this.$emit("upload:success", resp);
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      console.log('event.dataTransfer.files:', event.dataTransfer.files);
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add("bg-gray-100");
      console.log('After adding bg-gray-100:', event.currentTarget.classList);
      event.currentTarget.classList.remove("bg-green-300");
      console.log('After removing bg-green-300:', event.currentTarget.classList);
    },
    upload(file) {
      console.log("uploading file", file)
      return new Promise((resolve, reject) => {
        const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/tplive/upload";
        const CLOUDINARY_UPLOAD_PRESET = "vue_wvky6qwr";

        let client = getState.client;

        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        formData.append("folder", client.key + "/" + this.folder);
        formData.append("file", file);
        if (this.filename) {
          formData.append("public_id", this.filename);
        } else {
          let parts = file.name.split(".");
          formData.append("public_id", parts[0]);
        }

        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            this.complete++;
            resolve(response);
          }

          // Not succesfull
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            alert("error, status code not 200 " + error);
            reject(error);
          }
        };

        request.onerror = (err) => {
          alert(JSON.stringify(err));
          reject(err);
        };

        request.send(formData);
      });
    },
  },
};
</script>

<style></style>
