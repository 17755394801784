import { API } from "@/api";
import _map from "lodash/map";
import _has from "lodash/has";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import md5 from "blueimp-md5";
import { del } from "idb-keyval";

export const STORE = {
  data: {},
  async set(key, payload, code_2fa) {
    let filters_key = `filters_${payload.slug}`;
    let filters = _get(this.data, filters_key, null);
    if (filters) {
      filters = _groupBy(filters, "field");
    }

    payload.pageconfig.filters = _map(payload.pageconfig.filters, (i) => {
      if (_has(i, "lock") && i.lock === true) {
        let filter = _get(filters, i.field, null);
        if (filter) {
          i.not = filter[0].not;
          i.value = filter[0].value;
          i.selected = filter[0].selected;
        }
      } else {
        (i.value = null), (i.selected = []);
      }
      return i;
    });
    payload.pageconfig.form = _map(payload.pageconfig.form, (i) => {
      i._show_help = false;
      i.lock_edits = false;
      return i;
    });

    if (_has(payload, "schema")) delete payload.schema;

    await del(payload.slug);

    this.data[key] = payload;

    return API.put_page(payload, code_2fa);
  },
  setOnly(key, payload) {
    this.data[key] = payload;
  },
  get(key) {
    return _get(this.data, key, null);
    // return reactive(this.data[key]);
  },
  del(key) {
    if (_has(this.data, key)) {
      delete this.data[key];
    }
  },
  slug(slug, params) {
    return new Promise((resolve, reject) => {
      let key = md5(slug + JSON.stringify(params));

      if (_has(this.data, key)) {
        return resolve(this.data[key]);
      }
      API.get(slug, params)
        .then((resp) => {
          this.data[key] = resp.data.data;
          return resolve(resp.data.data);
        })
        .catch((e) => {
          return reject(e);
        });
    });
  },
  filter(slug, fld, params, use_cache) {
    return new Promise((resolve, reject) => {
      let key = md5(slug + fld + JSON.stringify(params));

      if (_has(this.data, key) && use_cache) {
        return resolve(this.data[key]);
      } else {
        API.filter(slug, fld, params)
          .then((resp) => {
            this.data[key] = resp.data.data;
            return resolve(resp.data.data);
          })
          .catch((e) => {
            return reject(e);
          });
      }
    });
  },
};
