<template>
  <label :for="item.field" @click="$emit('sticky')" class="relative block text-sm font-medium uppercase text-primary-800">
    <span v-if="item.required" class="el_1_ec1d66a1133b7fd5995513da53cb99d2 inline mr-1 text-sm text-red-400">*</span>
    {{ item.name }}
  </label>
  <div class="el_3_ec1d66a1133b7fd5995513da53cb99d2 relative flex mt-1 flex-col space-y-1">
    <div class="flex space-x-3">
      <template v-if="row.id">
        <select v-model="val" class="w-full border border-gray-200 rounded-md" @change="select">
          <option v-for="i in options" :key="i.value">
            {{ i.label }}
          </option>
        </select>
      </template>
      <template v-else>
        <div class="flex-1">
          <input v-model="search_query" :readonly="item.sticky"  placeholder="Search..." class="w-full p-2 rounded-md border border-gray-200">
          <select v-model="values" readonly="item.sticky" :multiple="row.id ? false : true" class="w-full border border-gray-200 rounded-md read-only:bg-yellow-50"
            @change="add">
            <option v-for="i in result_query" :key="i.value">
              {{ i.label }}
            </option>
          </select>
        </div>
        <div class="flex-1">
          <div class="block text-sm font-medium uppercase text-primary-800 py-1">
            Selected
          </div>
          <div class="bg-white p-2 divide-y">
            <div v-for="i in selected" :key="i" class="hover:cursor-pointer hover:text-red-400" @click="remove(i)">
              {{ i }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _map from "lodash/map";
import _has from "lodash/has";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _uniqBy from "lodash/uniqBy";
import _findIndex from "lodash/findIndex";
import { API } from "@/api";

export default {
  components: {},
  props: {
    item: Object,
    slug: String,
    error: String,
    row: Object,
    modelValue: [String, Number, Boolean],
  },
  emits: { "update:modelValue": null },
  data() {
    return {
      search_query: "",
      options: [],
      selected: [],
      values: null,
      val: this.modelValue,
      loading: true,
      open: false,
      form_row: null,
      show_add_combo_item: false,
      show_help: false,
      is_sql: !_isEmpty(this.item.sql),
    };
  },
  computed: {
    result_query() {
      if (this.search_query) {
        let filtered = this.options.filter((item) => {
          return this.search_query
            .toLowerCase()
            .split(" ")
            .every((v) => {
              return item.label.toLowerCase().includes(v);
            });
        });
        return filtered;
      } else {
        return this.options;
      }
    },
  },
  created() {
    this.refresh();
  },
  methods: {
    remove(i) {
      let index = this.selected.indexOf(i);
      this.selected.splice(index, 1);
      this.$emit("update:modelValue", this.selected);
    },
    add() {
      this.selected = _uniqBy([...this.selected, ...this.values]);
      this.values = [];
      this.$emit("update:modelValue", this.selected);
    },
    select() {
      this.$emit("update:modelValue", this.val);
    },
    async refresh(use_cache) {
      this.loading = true;

      let params = {};

      if (!_isEmpty(this.item.sql)) params.combo = 1;

      let resp = await API.filter(this.slug, this.item.field, params, use_cache).catch((e) => {
        console.log(e.message);
      });

      this.loading = false;

      if (resp) {
        this.options = _map(resp.data.data, (i) => {
          let obj = i;
          if (!_has(i, "value")) obj = { value: i[this.item.field], label: i[this.item.field] };

          if (obj.value == this.modelValue) this.selected = obj;

          return obj;
        });
      }
    },
  },
};
</script>

<style scoped>
.combo {
  min-height: 32px;
}
</style>
